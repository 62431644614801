import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import Loader from "../Loader/Loader";
// Custom Hooks
import { useGetTopBarConfiguration } from "../../hooks/useGetTopBarConfiguration";
// Images/Icons
import icon_incomplete from "../../assets/icon_task_1.png";
import icon_complete from "../../assets/icon_task_complete.png";
import imgOrgDetails from "../../assets/image_org_details.png";
import imgExtra from "../../assets/image_extra.png";
import imgLeagueFinder from "../../assets/image_league_finder.png";
// Utils
import { useAuth } from "../../utils/authenticate";
import { getMessagesForStepper } from "../../utils/messaging";
// Constants
import * as DASH_CONST from "../../constants/dashboardPage";
import * as STATUS_CONST from "../../constants/status";
// Styles
import "./Dashboard.css";

const getIconForBox = (indexBox) => {
  if (indexBox === 0) return imgOrgDetails;
  if (indexBox === 1) return imgLeagueFinder;
  if (indexBox === 2) return imgExtra;
  return imgOrgDetails;
};

const getProgressBarInfo = (stepper) => {
  // Excluding Summary (last stepper) from tasks
  let completeItems = 0;
  for (const [index, step] of stepper?.entries()) {
    if (index !== stepper?.length - 1) {
      step?.status === STATUS_CONST.STATUS_APPROVED
        ? (completeItems += 1)
        : (completeItems += 0);
    }
  }
  const percentComplete = Math.floor(
    (completeItems / (stepper.length - 1)) * 100
  );
  if (isNaN(percentComplete) || percentComplete === undefined) {
    return 0;
  }
  return percentComplete;
};

const handleClickOnOpenNewPage = (link) => {
  if (link?.trim()?.length > 0) {
    window.open(link, "_blank");
  }
};

const Dashboard = ({ userData }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isError, setIsError] = useState({ active: false, error: "" });
  const [tasks, setTasks] = useState([]);
  const [unreadMsgsByStep, setUnreadMsgsByStep] = useState([]);

  const configuration = useGetTopBarConfiguration(
    userData?.onboardingFlow?.leadType
  );

  useEffect(() => {
    const retrieveMsgsForStepper = async () => {
      try {
        const promises = [];
        for (
          let i = 0;
          i < userData?.onboardingFlow?.stepper?.length - 1;
          i++
        ) {
          promises.push(
            getMessagesForStepper(user?.token, userData?._id, i).then(
              (msgList) => {
                const hasUnreadMessages =
                  msgList?.task?.stepSections?.length > 0 &&
                  msgList?.task?.stepSections.some((step) =>
                    step?.messages?.some(
                      (message) => !message.readBy.includes(user?.email)
                    )
                  );
                return hasUnreadMessages;
              }
            )
          );
        }
        const results = await Promise.all(promises);
        setUnreadMsgsByStep(results);
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to getMessagesForStepper: ${err?.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    };
    if (userData?.onboardingFlow?.stepper?.length > 0 && tasks?.length === 0) {
      if (
        unreadMsgsByStep?.length !==
        userData?.onboardingFlow?.stepper?.length - 1
      ) {
        retrieveMsgsForStepper();
        setTasks(userData?.onboardingFlow?.stepper);
      }
    }
  }, [userData]);

  const listTasks = useCallback(() => {
    return tasks?.map((step, key) => {
      return key < tasks?.length - 1 ? (
        <div key={key}>
          <div className="card_task" key={key}>
            <img
              style={{ width: 40, height: 40 }}
              src={
                step?.status === STATUS_CONST.STATUS_APPROVED
                  ? icon_complete
                  : icon_incomplete
              }
              alt="Icon"
            />
            <div className="card_task_text">
              <div className="card_task_text_main">
                <span className="card_task_label">{step?.stepName}</span>
                <span className="card_task_description">
                  {userData?.status === STATUS_CONST.STATUS_NEW_OPPORTUNITY
                    ? DASH_CONST.MSG_SECTION_NEW_OPPORTUNITY
                    : step?.status === STATUS_CONST.STATUS_APPROVED
                    ? DASH_CONST.MSG_SECTION_APPROVED
                    : step?.status === STATUS_CONST.STATUS_NEEDS_ATTENTION ||
                      step?.stepSections?.some(
                        (section) =>
                          section?.status ===
                          STATUS_CONST.STATUS_NEEDS_ATTENTION
                      )
                    ? DASH_CONST.MSG_SECTION_NEEDS_ATTENTION
                    : DASH_CONST.MSG_SECTION_PENDING}
                </span>
                <div className="dashboard__cartButton-wrapper">
                  <div
                    className="dashboard__cardButton"
                    onClick={() =>
                      navigate(`/flow/${userData?._id}`, {
                        state: { step: key },
                      })
                    }
                  >
                    {step?.status === STATUS_CONST.STATUS_NEEDS_ATTENTION ||
                    step?.stepSections?.some(
                      (section) =>
                        section?.status === STATUS_CONST.STATUS_NEEDS_ATTENTION
                    )
                      ? DASH_CONST.SECTION_RESOLVE
                      : DASH_CONST.SECTION_REVIEW}
                  </div>
                  {unreadMsgsByStep[key] === true && (
                    <div key={`msgList-${key}`} className="dashboard__pill">
                      <div className="dashboard__pill-red"></div>
                      <span className="dashboard__pill-unread">
                        {DASH_CONST.NOTIFICATION_UNREAD_MSGS}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {key > 1 && <div className="card_separator"></div>}
        </div>
      ) : null;
    });
  }, [navigate, tasks, unreadMsgsByStep, userData?._id, userData?.status]);

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  return (
    <div className="mainSection">
      {tasks?.length > 0 ? (
        <>
          {configuration && (
            <div className="left_section">
              <div className="left__section-upper">
                <div className="left__section-wrapper">
                  <div className="dashboard__leftSection-banner">
                    {configuration?.conf?.dashboard_images_urls?.main_image
                      ?.length > 0 ? (
                      <>
                        <div className="dashboard__leftSection-image">
                          <img
                            src={
                              configuration?.conf?.dashboard_images_urls
                                ?.main_image
                            }
                            alt="Banner"
                          />
                        </div>
                        <div className="dashboard__leftSection-content">
                          <div className="dashboard__leftSection-text">
                            <div className="dashboard__leftSection-title">
                              {
                                configuration?.conf?.dashboard?.main_image
                                  ?.title
                              }
                            </div>
                            <div className="dashboard__leftSection-subtitle">
                              {
                                configuration?.conf?.dashboard?.main_image
                                  ?.description
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <div className="dashboard__leftSection-cards">
                    {configuration?.conf?.dashboard?.box_links?.map(
                      (box, indexBox) => (
                        <div
                          key={box?._id}
                          className={`dashboard__cardMain ${
                            box?.url?.trim().length > 0 &&
                            "radio__option-pointer"
                          }`}
                          onClick={() => handleClickOnOpenNewPage(box?.url)}
                        >
                          <div className="dashboard__cardMain-icon">
                            <img
                              src={getIconForBox(indexBox)}
                              alt={box?.title}
                            />
                          </div>
                          <div className="dashboard__cardMain-text">
                            {box?.title}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="left__section-resources">
                <div className="left__resources-wrapperT">
                  {DASH_CONST.TITLE_FOR_LINK_PAGES}
                </div>
                <div className="left__resources-cards">
                  {configuration?.conf?.dashboard?.page_links?.map(
                    (page, indexPage) => (
                      <div
                        key={page?._id}
                        className={`left__resources-card ${
                          page?.url?.trim().length > 0 && "radioOption-pointer"
                        }`}
                        onClick={() => handleClickOnOpenNewPage(page?.url)}
                      >
                        <div className="left__resources-image">
                          <img
                            src={
                              configuration?.conf?.dashboard_images_urls
                                ?.page_links[indexPage]
                            }
                            alt={page?.title}
                          />
                        </div>
                        <div>
                          <div className="left__resources-title">
                            {page?.title}
                          </div>
                          <div className="left__resources-desc">
                            {page?.description}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="task_panel">
            <div className="upper">
              <div className="overview">
                <div className="header">
                  <div className="header_top">
                    <span className="header_title">
                      {DASH_CONST.TITLE_MY_TASKS}
                    </span>
                  </div>
                  <span className="header_bottom">{`${getProgressBarInfo(
                    userData?.onboardingFlow?.stepper
                  )}% resolved`}</span>
                </div>
                <div className="progressbar">
                  <div className="progressbar_box">
                    <div
                      className="progressbar_fill"
                      style={{
                        width: `${getProgressBarInfo(
                          userData?.onboardingFlow?.stepper
                        )}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="task_list">
              {tasks?.length > 0 && unreadMsgsByStep?.length > 0 ? (
                listTasks()
              ) : (
                <div className="loader__alignSelf">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="admin__dashboard-loader">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
