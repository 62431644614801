import React from "react";
// Component
import ErrorTemplate from "../ErrorTemplate/ErrorTemplate";
import FlowTypeVersions from "../FlowTypeVersions/FlowTypeVersions";
import Loader from "../../../Loader/Loader";
// Utils
import { useGetFlowTypeListByProgram } from "../../../../hooks/useGetFlowTypeListByProgram";
// Styles
import "./FlowTypesList.css";

const FlowTypesList = ({ program }) => {
  const { flowTypeList, error, isLoading } = useGetFlowTypeListByProgram({
    program,
  });

  if (error) return <ErrorTemplate error={error} />;

  if (isLoading)
    return (
      <div className="workFlowList__loader">
        <Loader />
      </div>
    );

  if (flowTypeList?.length === 0) return <div>There is no data to show </div>;

  return (
    <div className="flowTypesList__wrapper">
      {flowTypeList?.map((flowType) => {
        return <FlowTypeVersions key={flowType?.name} flowType={flowType} />;
      })}
    </div>
  );
};

export default FlowTypesList;
