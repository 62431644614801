import React, { useReducer } from "react";
import AdminDashboardContext from "./AdminDashboardContext";
import {
  adminDashboardReducer,
  initialState,
} from "../../reducers/AdminDashboard/reducer";

const AdminDashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(adminDashboardReducer, initialState);

  return (
    <AdminDashboardContext.Provider
      value={{ adminDashState: state, dispatchAdminDashboardAction: dispatch }}
    >
      {children}
    </AdminDashboardContext.Provider>
  );
};

export default AdminDashboardProvider;
