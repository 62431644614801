import { getBaseURL } from "./getBaseURL";

export async function getMessagesForStepper(
  user_token,
  onboardingId,
  stepper_index
) {
  const SERVER_URL = getBaseURL();

  try {
    const res = await fetch(
      `${SERVER_URL}/task/${onboardingId}/${stepper_index}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "auth-token": user_token,
        },
      }
    );
    if (!res.ok) {
      const errorMsg = await res.json();
      throw Object.assign(
        new Error(
          `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
        ),
        {
          status: res.status,
          statusText: res.statusText,
          cause: errorMsg.error,
        }
      );
    }
    const data = await res.json();
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function addMessage(
  user_token,
  onboardingId,
  stepperIndex,
  sectionIndex,
  message
) {
  const SERVER_URL = getBaseURL();

  try {
    const res = await fetch(
      `${SERVER_URL}/task/message/${onboardingId}/${stepperIndex}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "auth-token": user_token,
        },
        body: JSON.stringify({
          sectionIndex,
          message,
        }),
      }
    );
    if (!res.ok) {
      const errorMsg = await res.json();
      throw Object.assign(
        new Error(
          `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
        ),
        {
          status: res.status,
          statusText: res.statusText,
          cause: errorMsg.error,
        }
      );
    }
    const result = await res.json();
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function markAsRead(
  user_token,
  onboardingId,
  stepperIndex,
  sectionIndex
) {
  const SERVER_URL = getBaseURL();

  try {
    const res = await fetch(
      `${SERVER_URL}/task/message/${onboardingId}/${stepperIndex}/read`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "auth-token": user_token,
        },
        body: JSON.stringify({
          sectionIndex,
        }),
      }
    );
    if (!res.ok) {
      const errorMsg = await res.json();
      throw Object.assign(
        new Error(
          `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
        ),
        {
          status: res.status,
          statusText: res.statusText,
          cause: errorMsg.error,
        }
      );
    }
    const result = await res.json();
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function markAsUnread(
  user_token,
  onboardingId,
  stepperIndex,
  sectionIndex
) {
  const SERVER_URL = getBaseURL();

  try {
    const res = await fetch(
      `${SERVER_URL}/task/message/${onboardingId}/${stepperIndex}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "auth-token": user_token,
        },
        body: JSON.stringify({
          sectionIndex,
        }),
      }
    );
    if (!res.ok) {
      const errorMsg = await res.json();
      throw Object.assign(
        new Error(
          `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
        ),
        {
          status: res.status,
          statusText: res.statusText,
          cause: errorMsg.error,
        }
      );
    }
    const result = await res.json();
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
}
