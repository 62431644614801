import React from "react";
import PropTypes from "prop-types";
// Styles
import "./Radio.css";
import classNames from "classnames";

const Radio = ({
  className = "",
  children,
  disabled = false,
  label,
  onChange,
  options,
  required = false,
  style,
  value,
  wrapperClassName,
  ...additionalProps
}) => {
  const labelClasses = classNames("radio__label", {
    "admin__disabled-color": disabled,
  });

  const pointerClass = !disabled ? "radio__option-pointer" : "";

  const inputDateClasses = classNames(
    className || "radio__option-item",
    pointerClass,
    {
      "admin__disabled-color user__disabled-background": disabled,
    }
  );

  const handleOnClick = (event, newValue) => {
    if (disabled) return;
    event?.stopPropagation();
    const currentValue = event?.target?.value;
    if (newValue === value) {
      event.target.value = null;
      event.target.checked = false;
      return onChange(null);
    } else {
      event.target.checked = true;
      return onChange(currentValue);
    }
  };

  return (
    <div className={wrapperClassName}>
      {label && (
        <label className={labelClasses}>
          {label}
          {required ? "*" : null}
        </label>
      )}
      <div className="radio__options">
        {options?.map((dropdownItem, dropdownKey) => {
          const idKey = `${dropdownItem?.title}-${dropdownKey}`;
          return (
            <div className={inputDateClasses} key={idKey}>
              <input
                checked={dropdownItem?.value === value}
                disabled={disabled}
                id={idKey}
                name={label}
                onClick={(event) => handleOnClick(event, dropdownItem?.value)}
                onChange={(event) => handleOnClick(event, dropdownItem?.value)}
                style={style}
                type="radio"
                value={dropdownItem?.value}
                {...additionalProps}
              />
              <div className={"radio__option-text"}>
                {dropdownItem?.title && (
                  <label
                    className={`radio__title ${pointerClass}`}
                    htmlFor={idKey}
                  >
                    {dropdownItem?.title}
                  </label>
                )}
                {dropdownItem?.description && (
                  <label
                    className={`radio__description ${pointerClass}`}
                    htmlFor={idKey}
                  >
                    {dropdownItem?.description}
                  </label>
                )}
                {children}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default Radio;
