import React from "react";
import Avatar from "../../../Avatar/Avatar";

const AdminHeader = ({ title }) => {
  return (
    <div className="admin__topBar">
      <div className="admin__topBar-title">{title}</div>
      <div className="admin__topBar-right">
        <Avatar typeUser={"admin"} />
      </div>
    </div>
  );
};

export default AdminHeader;
