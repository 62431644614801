import { getBaseURL } from "./getBaseURL";

export async function getProgramConfigurationFromAdmin(
  user_token,
  programType
) {
  const SERVER_URL = getBaseURL();

  try {
    const response = await fetch(`${SERVER_URL}/admin/config/${programType}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user_token,
      },
    });
    if (!response.ok) {
      const errorMsg = await response.json();
      throw Object.assign(
        new Error(
          `${response?.statusText} : ${errorMsg?.error}. Status: ${response?.status}`
        ),
        {
          status: response?.status,
          statusText: response?.statusText,
          cause: errorMsg?.error,
        }
      );
    }
    const data = await response.json();
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateProgramConfigurationFromAdmin(
  user_token,
  programType,
  objectData
) {
  const SERVER_URL = getBaseURL();
  try {
    const body = {};
    if (objectData.top_bar) {
      body.top_bar = objectData.top_bar;
    }
    if (objectData.dashboard) {
      body.dashboard = objectData.dashboard;
    }
    const response = await fetch(`${SERVER_URL}/admin/config/${programType}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user_token,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      const errorMsg = await response.json();
      throw Object.assign(
        new Error(
          `${response?.statusText} : ${errorMsg?.error}. Status: ${response?.status}`
        ),
        {
          status: response?.status,
          statusText: response?.statusText,
          cause: errorMsg?.error,
        }
      );
    }
    const data = await response.json();
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}
