import React from "react";
import { ReactComponent as ResourceIcon } from "../assets/icon_resource.svg";
import { ReactComponent as FAQIcon } from "../assets/icon_faq.svg";
import { ReactComponent as SupportIcon } from "../assets/icon_support.svg";
import { ReactComponent as QueueList } from "../assets/queue-list.svg";

const iconMapping = {
    faq: <FAQIcon />,
    support: <SupportIcon />,
    myflows: <QueueList />,
};

const defaultIcon = <ResourceIcon />;

export const getIconForLink = (title) => {
    const lowercaseTitle = title.toLowerCase();
    return iconMapping[lowercaseTitle] || defaultIcon;
};
