import _ from "lodash";
// Constants
import * as STATUS_CONST from "../../constants/status";

// Define the initial state of the reducer
export function initialState(tabSelected, newOnboardingData) {
  return {
    initials:
      newOnboardingData &&
        newOnboardingData?.user?.firstName &&
        newOnboardingData?.user?.lastName
        ? `${newOnboardingData?.user?.firstName[0]?.toUpperCase()}${newOnboardingData?.user?.lastName[0]?.toUpperCase()}`
        : "",
    msgList: [],
    msgTemp: "",
    onboardingIdData: newOnboardingData || [],
    onboardingIdDataBKP: newOnboardingData || [],
    sectionEditSelected: null,
    showModalUpdateField: {
      active: false,
      value: null,
    },
    showModalChat: {
      active: false,
      value: [],
      stepperChat: null,
      sectionChat: null,
    },
    showModalExitWithoutSaving: {
      active: false,
      value: -1,
    },
    tabSelected: { index: tabSelected || 0, stepName: null },
  };
}

// Define the action types
export const actionTypes = {
  CANCEL_EDIT_SECTION: "CANCEL_EDIT_SECTION",
  CANCEL_EXIT_WITHOUT_SAVING: "CANCEL_EXIT_WITHOUT_SAVING",
  CANCEL_UPD_FIELD: "CANCEL_UPD_FIELD",
  CHAT_COMMET: "CHAT_COMMET",
  SET_CURRENT_COMMENT: "SET_CURRENT_COMMENT",
  SET_CURRENT_MSG_LIST: "SET_CURRENT_MSG_LIST",
  SET_ONBOARDING_DATA: "SET_ONBOARDING_DATA",
  SET_SECTION_EDIT_SELECTED: "SET_SECTION_EDIT_SELECTED",
  SET_TAB_SELECTED: "SET_TAB_SELECTED",
  SHOW_MODAL_CHAT: "SHOW_MODAL_CHAT",
  SHOW_MODAL_UPD_FIELD: "SHOW_MODAL_UPD_FIELD",
  UPD_ONBOARDING_FLOW_FIELD_USER: "UPD_ONBOARDING_FLOW_FIELD_USER",
  UPD_ONBOARDING_FLOW_FIELD_VIEW: "UPD_ONBOARDING_FLOW_FIELD_VIEW",
};

// Define the reducer function
export function userPageReducer(state, action) {
  if (action.type === actionTypes.CANCEL_EDIT_SECTION) {
    return {
      ...state,
      sectionEditSelected: null,
      onboardingIdData: _.cloneDeep(state?.onboardingIdDataBKP),
    };
  }
  if (action.type === actionTypes.CANCEL_EXIT_WITHOUT_SAVING) {
    return {
      ...state,
      showModalExitWithoutSaving: { active: false, value: -1 },
    };
  }
  if (action.type === actionTypes.CANCEL_UPD_FIELD) {
    return {
      ...state,
      showModalUpdateField: { active: false, value: null },
      sectionEditSelected: null,
      onboardingIdData: _.cloneDeep(state?.onboardingIdDataBKP),
    };
  }
  if (action.type === actionTypes.CHAT_COMMET) {
    return {
      ...state,
      msgList: action?.payload?.newMsg,
      msgTemp: "",
    };
  }
  if (action.type === actionTypes.SET_CURRENT_COMMENT) {
    return {
      ...state,
      msgTemp: action?.payload?.msgTemp,
    };
  }
  if (action.type === actionTypes.SET_CURRENT_MSG_LIST) {
    return {
      ...state,
      msgList: action?.payload?.msgList,
    };
  }
  if (action.type === actionTypes.SET_ONBOARDING_DATA) {
    return {
      ...state,
      onboardingIdData: action?.payload?.onboardingIdData,
      onboardingIdDataBKP: action?.payload?.onboardingIdData,
      initials:
        action?.payload?.onboardingIdData?.user?.firstName[0]?.toUpperCase() +
        action?.payload?.onboardingIdData?.user?.lastName[0]?.toUpperCase(),
    };
  }
  if (action.type === actionTypes.SET_SECTION_EDIT_SELECTED) {
    return {
      ...state,
      sectionEditSelected: action?.payload?.sectionEditSelected,
    };
  }
  if (action.type === actionTypes.SET_TAB_SELECTED) {
    let showModalExitWithoutSavingActive = false;
    let showModalExitWithoutSavingValue = -1;

    const clonedOnboardingIdData = _.cloneDeep(state?.onboardingIdData);
    const clonedOnboardingIdDataBKP = _.cloneDeep(state?.onboardingIdDataBKP);
    // Exclude "DocuSign" elements from clonedOnboardingIdData
    clonedOnboardingIdData?.onboardingFlow?.stepper?.forEach((step) => {
      step?.stepSections?.forEach((section) => {
        section.sectionItems = section?.sectionItems?.filter(
          (item) => item?.inputType !== "DocuSign"
        );
      });
    });
    // Exclude "DocuSign" elements from clonedOnboardingIdDataBKP
    clonedOnboardingIdDataBKP?.onboardingFlow?.stepper?.forEach((step) => {
      step?.stepSections?.forEach((section) => {
        section.sectionItems = section?.sectionItems?.filter(
          (item) => item?.inputType !== "DocuSign"
        );
      });
    });
    // Compare both updated objects
    const isEqual = _.isEqual(
      clonedOnboardingIdData,
      clonedOnboardingIdDataBKP
    );
    if (action?.payload?.viewMode === false && !isEqual) {
      showModalExitWithoutSavingActive = true;
      showModalExitWithoutSavingValue = action?.payload?.index;
    }

    return {
      ...state,
      tabSelected: {
        index: showModalExitWithoutSavingActive
          ? state?.tabSelected?.index
          : action?.payload?.index,
        stepName: showModalExitWithoutSavingActive
          ? state?.tabSelected?.stepName
          : action?.payload?.stepName,
      },
      showModalExitWithoutSaving: {
        active: showModalExitWithoutSavingActive,
        value: showModalExitWithoutSavingValue,
      },
      showModalChat: {
        active: false,
        value: [],
        stepperChat: null,
        sectionChat: null,
      },
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_CHAT) {
    const valueActive =
      action?.payload?.active === false
        ? false
        : action?.payload?.stepperChat === state?.showModalChat?.stepperChat &&
          action?.payload?.sectionChat === state?.showModalChat?.sectionChat
          ? !state?.showModalChat.active
          : true;

    const msgListAux = _.cloneDeep(state?.msgList);
    if (valueActive === true && action?.payload?.email) {
      msgListAux?.stepSections[action?.payload?.sectionChat]?.messages?.map(
        (msg) => msg?.readBy?.push(action?.payload?.email)
      );
    }

    return {
      ...state,
      showModalChat: {
        active: valueActive,
        stepperChat: action?.payload?.stepperChat,
        sectionChat: action?.payload?.sectionChat,
      },
      msgList: msgListAux,
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_UPD_FIELD) {
    return {
      ...state,
      showModalUpdateField: {
        active: action?.payload?.active,
        indexSection: action?.payload?.indexSection,
        value: action?.payload?.value,
      },
    };
  }
  if (action.type === actionTypes.UPD_ONBOARDING_FLOW_FIELD_USER) {
    const onboardingIdDataAux = _.cloneDeep(state?.onboardingIdData);
    onboardingIdDataAux.onboardingFlow.stepper[
      state?.tabSelected.index
    ].stepSections[state?.sectionEditSelected].lastUpdatedBy =
      state?.onboardingIdData?.user?.email;
    onboardingIdDataAux.onboardingFlow.stepper[
      state?.tabSelected?.index
    ].stepSections[state?.sectionEditSelected].status =
      STATUS_CONST.STATUS_PENDING;
    return {
      ...state,
      onboardingIdData: onboardingIdDataAux,
      onboardingIdDataBKP: onboardingIdDataAux,
    };
  }
  if (action.type === actionTypes.UPD_ONBOARDING_FLOW_FIELD_VIEW) {
    const { keySection, keyItem, new_value } = action?.payload;
    const onboardingIdDataAux = _.cloneDeep(state?.onboardingIdData);
    onboardingIdDataAux.onboardingFlow.stepper[
      state?.tabSelected?.index
    ].stepSections[keySection].sectionItems[keyItem].value = new_value;
    return {
      ...state,
      onboardingIdData: onboardingIdDataAux,
    };
  }
}
