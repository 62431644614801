import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// Components
import Loader from "../../Loader/Loader";
// Images
import ImgError from "../../../assets/docuSign_error.png";
import ImgSent from "../../../assets/docuSign_sent.png";
import ImgSigned from "../../../assets/docuSign_signed.png";
// Utils
import { useAuth } from "../../../utils/authenticate";
import { getDocuSignFile } from "../../../utils/docuSign";
// Constants
import * as FORM_CONST from "../../../constants/formComponents";
import * as STATUS_CONST from "../../../constants/status";
// Styles
import "./DocuSign.css";
import classNames from "classnames";

const getIconBasedOnStatus = (status) => {
  switch (status) {
    case FORM_CONST.DOCUSIGN_STATUS.completed.name:
      return ImgSigned;
    case FORM_CONST.DOCUSIGN_STATUS.delivered.name:
    case FORM_CONST.DOCUSIGN_STATUS.waiting.name:
    case FORM_CONST.DOCUSIGN_STATUS.sent.name:
      return ImgSent;
    default:
      return ImgError;
  }
};

const DocuSign = ({
  className = "",
  disabled = false,
  docuSignId,
  label,
  onChange,
  required = false,
  style,
  value,
  wrapperClassName,
  ...props
}) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(value);

  const labelClasses = classNames("inputText__label", {
    "admin__disabled-color": disabled,
  });

  useEffect(() => {
    const fetchDocuSignStatus = async () => {
      const fileData = await getDocuSignFile({
        token: user?.token,
        flowId: user?.onboardingFlowID,
        docuSignId,
      });
      return fileData;
    };
    if (props[0]?.status === STATUS_CONST.STATUS_NEW_OPPORTUNITY) {
      setIsLoading(false);
      return setStatus(FORM_CONST.DOCUSIGN_STATUS.waiting.name);
    }
    if (value === FORM_CONST.DOCUSIGN_FILE_SIGNED) {
      return setStatus(FORM_CONST.DOCUSIGN_FILE_SIGNED);
    }
    fetchDocuSignStatus()
      .then((data) => {
        setStatus(data?.status);
        if (data?.status !== value) {
          onChange(data?.status);
        }
      })
      .catch((error) => {
        console.log("Error fetchDocuSignStatus API", error);
        setStatus(FORM_CONST.DOCUSIGN_STATUS.error.name);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [docuSignId, user?.onboardingFlowID, user?.token, value]);

  return (
    <div className={wrapperClassName}>
      {label && (
        <label className={labelClasses}>
          {label}
          {required ? "*" : null}
        </label>
      )}
      <div>
        <div className="docuSign-wrapper">
          {isLoading && status !== FORM_CONST.DOCUSIGN_FILE_SIGNED ? (
            <Loader />
          ) : (
            <>
              <img
                className={"docuSign__image"}
                src={getIconBasedOnStatus(status)}
                alt="Informational"
              />
              <div className="docuSign__wrapper-text">
                <h2 className="docuSign__title">
                  {FORM_CONST.DOCUSIGN_STATUS[status]?.title ||
                    `Status : ${status}`}
                </h2>
                <p className="docuSign__textDescription">
                  {FORM_CONST.DOCUSIGN_STATUS[status] ===
                    FORM_CONST.DOCUSIGN_STATUS["sent"] ||
                  FORM_CONST.DOCUSIGN_STATUS[status] ===
                    FORM_CONST.DOCUSIGN_STATUS["delivered"]
                    ? FORM_CONST.DOCUSIGN_STATUS[status]?.text +
                        (props[0]?.user?.email ?? "...") ||
                      FORM_CONST.DEFAULT_DOCUSIGN_TEXT
                    : FORM_CONST.DOCUSIGN_STATUS[status]?.text ||
                      FORM_CONST.DEFAULT_DOCUSIGN_TEXT}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

DocuSign.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  docuSignId: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default DocuSign;
