import React from "react";
import { useParams } from "react-router";
import { Account } from "../components/Account/Account";

export const AccountAuthentication = ({ configuration }) => {
  const { userID = "", email = "", token = "" } = useParams();

  const userAccount = { userID, email, token };

  return <Account userAccount={userAccount} configuration={configuration} />;
};
