import React from "react";
import PropTypes from "prop-types";
// Styles
import "./XMark.css";
import classNames from "classnames";

const XMark = ({ classes, color, disabled, height, onClick, width }) => {
  const wrapperClasses = classNames(
    "iconXMark",
    {
      "iconXMark__cursor-pointer": !disabled,
    },
    classes
  );

  return (
    <div className={wrapperClasses} onClick={!disabled ? onClick : undefined}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

XMark.propTypes = {
  classes: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string,
};

XMark.defaultProps = {
  color: "#0F172A",
  height: "10",
  disabled: false,
  width: "10",
};

export default XMark;
