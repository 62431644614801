import React, { useReducer } from "react";
import AdminBuilderContext from "./AdminBuilderContext";
import {
  adminBuilderReducer,
  initialState,
} from "../../reducers/AdminBuilder/reducer";

const AdminBuilderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(adminBuilderReducer, initialState);

  return (
    <AdminBuilderContext.Provider
      value={{ adminBuildState: state, dispatchAdminBuilderAction: dispatch }}
    >
      {children}
    </AdminBuilderContext.Provider>
  );
};

export default AdminBuilderProvider;
