export const getErrorTitle = (statusCode) => {
    if (statusCode < 500 || !statusCode) {
        return "Looks like you are lost!";
    } else {
        return "Sorry! Gateway timeout error";
    }
};

export const getErrorDescription = (statusCode) => {
    if (statusCode < 500 || !statusCode) {
        return "Sorry. We can't find the page you are looking for...";
    } else {
        return "Please try again. If not, check your connection.";
    }
};
