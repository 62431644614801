// Program Logo
import NFLFlag from "../assets/image_nfl_flag.png";
import LogoStreet from "../assets/image_street.png";
// TopBar Logo
import logo49ersTopbar from "../assets/49ers-ob.png";
import logoNBATopbar from "../assets/logo_topbar.png";
import logoNFLTopbar from "../assets/nflflag-ob.png";
import logoMLSTopbar from "../assets/mlsgo_logo.png";
import logoStreetTopbar from "../assets/nhlstreet-ob.png";
// Card Logo
import logoJrNBA from "../assets/logo_jrNBA.png";
import logoMLS from "../assets/mlsgo_logo.png";
import logoNFL from "../assets/logo_NFL.png";
import logoRCXSports from "../assets/logo_rcxSports.png";
import logoSTREET from "../assets/logo_STREET.png";

const LogoTopBarMap = {
  MLS: logoMLSTopbar,
  NBA: logoNBATopbar,
  NFL: logoNFLTopbar,
  STREET: logoStreetTopbar,
  "49ers": logo49ersTopbar,
  undefined: logoRCXSports,
};

const LogoProgramMap = {
  MLS: logoMLS,
  NBA: logoJrNBA,
  NFL: NFLFlag,
  STREET: LogoStreet,
  "49ers": "49ers",
};

const LogoCardMap = {
  MLS: logoMLS,
  NBA: logoJrNBA,
  NFL: logoNFL,
  STREET: logoSTREET,
};

export const getProgramLogoForLeadType = (leadType) => {
  return LogoProgramMap[leadType] ?? "Unknown";
};

export const getTopBarLogoForLeadType = (leadType) => {
  return LogoTopBarMap[leadType] ?? "Unknown";
};

export const getImageFromLogo = (logo) => {
  return LogoCardMap[logo] ?? logoRCXSports;
};
