import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Components
import { Stepper } from "../Stepper/Stepper";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import Edit from "../Icons/Edit/Edit";
import Loader from "../Loader/Loader";
// Reducers
import {
  initialState,
  userPageReducer,
  actionTypes,
} from "../../reducers/UserPage/reducer";
// Utils
import { getBaseURL } from "../../utils/getBaseURL";
import { useAuth } from "../../utils/authenticate";
import { getClassNameForStatus } from "../../utils/getClassNameForStatus";
import {
  getMessagesForStepper,
  addMessage,
  markAsRead,
} from "../../utils/messaging";
import { formatDateToStringYYYYMMDDHHMMSS } from "../../utils/formatDate";
import { renderComponentBaseOnType } from "../../utils/componentForm";
// Images
import imgOrgDetails from "../../assets/image_org_details.png";
import imgExtra from "../../assets/image_extra.png";
import imgResources from "../../assets/image_resources.png";
import EditPencil from "../../assets/edit_pencil.png";
import ImgQuestion from "../../assets/image_question3.png";
import ChatBubble from "../../assets/icon_chat_bubble.png";
import IconWarning from "../../assets/icon_warning.png";
import AlertIcon from "../../assets/ALERT.svg";
// Constants
import * as BUTTON_CONST from "../../constants/buttons";
import * as STATUS_CONST from "../../constants/status";
import * as USER_CONST from "../../constants/userPage";
// Styles
import "./style.css";

const possibleValidations = {
  Web: /\\*.*/,
  None: /\\*/,
  Number: /^[0-9]+$/,
};

const renderWarningMsg = (classes) => {
  return (
    <div className={classes}>
      <div className="user__notificationCard-content">
        <img
          className="user__notificationCard-icon"
          src={IconWarning}
          alt="Warning"
        />
        <div className="user__notificationCard-text">
          <span className="user__notificationCard-textH">
            {USER_CONST.WARNING_IMPORTANT}
          </span>
          {USER_CONST.WARNING_REQUIRED_FIELDS}
        </div>
      </div>
    </div>
  );
};

const sectionIsDisabled = (
  sectionEditSelected,
  indexSection,
  sectionStatus,
  orgStatus
) => {
  return (
    orgStatus !== STATUS_CONST.STATUS_NEW_OPPORTUNITY &&
    (sectionEditSelected !== indexSection ||
      sectionStatus === STATUS_CONST.STATUS_APPROVED)
  );
};

const getInitialsForAvatar = (initials, initialsUserLogged) => {
  return (
    <div className="chat__avatar-content">
      <span
        className={`chat__avatar-circle ${
          initials === initialsUserLogged
            ? "chat__avatar_eqFrom"
            : "chat__avatar_neqFrom"
        }`}
      >
        {initials}
      </span>
    </div>
  );
};

export const FlowLayout = ({ newOnboardingData, step, viewMode = false }) => {
  const { user, changeOnboardingSectionItems } = useAuth();

  const [userState, dispatchUserAction] = useReducer(
    userPageReducer,
    initialState(step, newOnboardingData)
  );

  const [showDoneModal, setShowDoneModal] = useState(false);
  const [isError, setIsError] = useState({ active: false, error: "" });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const refModalContainer = useRef(null);
  const refBubble = useRef([]);
  const messageListRef = useRef(null);

  const SERVER_URL = getBaseURL();
  const navigate = useNavigate();

  const onClickOnChatModal = async (stepperIndex, indexSection) => {
    dispatchUserAction({
      type: actionTypes.SHOW_MODAL_CHAT,
      payload: {
        stepperChat: userState?.tabSelected?.index,
        sectionChat: indexSection,
        email: userState?.onboardingIdData?.user?.email,
      },
    });
    try {
      if (
        userState?.msgList?.stepSections?.length > 0 &&
        userState?.msgList?.stepSections[indexSection]?.messages?.some(
          (msg) =>
            !msg.readBy?.includes(userState?.onboardingIdData?.user?.email)
        ) &&
        userState?.showModalChat?.active === false
      ) {
        await markAsRead(
          user?.token,
          newOnboardingData?._id,
          stepperIndex,
          indexSection
        );
      }
    } catch (err) {
      setIsError({
        active: true,
        error: `Failed to mark msg as read: ${err?.cause}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
  };

  const changeTabSelected = async (tabSelected) => {
    if (
      (tabSelected < 0 &&
        userState?.tabSelected?.index >=
          userState?.onboardingIdData?.onboardingFlow?.stepper?.length - 1) ||
      (tabSelected >= 0 &&
        tabSelected >=
          userState?.onboardingIdData?.onboardingFlow?.stepper?.length - 1)
    ) {
      return dispatchUserAction({
        type: actionTypes.SET_TAB_SELECTED,
        payload: {
          index: tabSelected
            ? tabSelected
            : userState?.onboardingIdData?.onboardingFlow?.stepper?.length,
          viewMode,
        },
      });
    }
    let newStepper = -1;
    if (tabSelected >= 0) {
      newStepper = tabSelected;
    } else {
      newStepper = userState?.tabSelected?.index + 1;
    }
    return dispatchUserAction({
      type: actionTypes.SET_TAB_SELECTED,
      payload: {
        index: newStepper,
        viewMode,
      },
    });
  };

  const addNewCommentOnChat = async (stepperIndex, sectionIndex, message) => {
    if (message.trim().length === 0) return;
    try {
      setIsButtonDisabled(true);
      const resultAddMsg = await addMessage(
        user?.token,
        newOnboardingData?._id,
        stepperIndex,
        sectionIndex,
        message
      );
      dispatchUserAction({
        type: actionTypes.CHAT_COMMET,
        payload: {
          newMsg: resultAddMsg.task,
        },
      });
      setIsButtonDisabled(false);
      setTimeout(() => {
        const lengthMsgs =
          resultAddMsg?.task?.stepSections[sectionIndex]?.messages?.length - 1;
        document
          .getElementById(`chat-msg-${lengthMsgs}`)
          ?.scrollIntoView({ behavior: "auto", block: "center" });
      }, 100);
    } catch (err) {
      setIsError({
        active: true,
        error: `Failed to add new Message: ${err?.cause}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
  };

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        refModalContainer?.current &&
        refBubble?.current[userState?.showModalChat?.sectionChat] &&
        !refModalContainer.current.contains(event.target) &&
        !refBubble.current[
          userState?.showModalChat?.sectionChat
        ]?.current?.contains(event.target)
      ) {
        dispatchUserAction({
          type: actionTypes.SHOW_MODAL_CHAT,
          payload: {
            active: false,
            email: userState?.onboardingIdData?.user?.email,
          },
        });
      }
    },
    [
      refModalContainer,
      dispatchUserAction,
      userState?.onboardingIdData?.user?.email,
      userState?.showModalChat?.sectionChat,
    ]
  );

  const handleDontSaveYourChangesAndContinue = async () => {
    return dispatchUserAction({
      type: actionTypes.SET_TAB_SELECTED,
      payload: {
        index: userState?.showModalExitWithoutSaving?.value,
        viewMode: true,
      },
    });
  };

  const handleSaveYourChangesAndContinue = async () => {
    setIsButtonDisabled(true);
    if (
      userState?.onboardingIdData?.status ===
      STATUS_CONST.STATUS_NEW_OPPORTUNITY
    ) {
      const response = await fetch(
        `${SERVER_URL}/onboarding/${userState?.onboardingIdData?._id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "auth-token": user?.token,
          },
          body: JSON.stringify({ data: userState?.onboardingIdData }),
        }
      );
      if (!response.ok) {
        const errorMsg = await response.json();
        throw Object.assign(
          new Error(
            `${response.statusText} : ${errorMsg.error}. Status: ${response.status}`
          ),
          {
            status: response.status,
            statusText: response.statusText,
            cause: errorMsg.error,
          }
        );
      }
      dispatchUserAction({
        type: actionTypes.SET_ONBOARDING_DATA,
        payload: { onboardingIdData: userState?.onboardingIdData },
      });
    } else {
      await handleUpdateFieldsItems();
    }
    await changeTabSelected(userState?.showModalExitWithoutSaving?.value);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, USER_CONST.TIMER_WAIT_TO_EXECUTE);
  };

  const handleCancelSaveYourChanges = async () => {
    setIsButtonDisabled(true);
    dispatchUserAction({
      type: actionTypes.CANCEL_EXIT_WITHOUT_SAVING,
    });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, USER_CONST.TIMER_WAIT_TO_EXECUTE);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    if (
      userState?.showModalChat?.active &&
      messageListRef &&
      messageListRef?.current
    ) {
      const messageList = messageListRef?.current;
      messageList.scrollTop = messageList?.scrollHeight;
    }
  }, [userState?.showModalChat?.active]);

  useEffect(() => {
    const retrieveMsgsForStepper = async (token, onboardingFlowId) => {
      try {
        const msgList = await getMessagesForStepper(
          token,
          onboardingFlowId,
          userState?.tabSelected?.index
        );
        dispatchUserAction({
          type: actionTypes.SET_CURRENT_MSG_LIST,
          payload: {
            msgList: msgList?.task,
          },
        });
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to getMessagesForStepper: ${err?.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    };
    if (
      userState?.onboardingIdData?.status !==
        STATUS_CONST.STATUS_NEW_OPPORTUNITY &&
      userState?.tabSelected?.index > -1
    ) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "auto",
        });
      }, 0);
      retrieveMsgsForStepper(user?.token, newOnboardingData?._id);
      dispatchUserAction({
        type: actionTypes.CANCEL_EDIT_SECTION,
      });
    }
  }, [
    newOnboardingData?._id,
    user?.token,
    userState?.tabSelected?.index,
    userState?.onboardingIdData?.status,
  ]);

  const updateStringNestedProps = async (
    indexSection,
    keyItem,
    new_value,
    validation,
    updateSectionWithoutValidation = false
  ) => {
    dispatchUserAction({
      type: actionTypes.UPD_ONBOARDING_FLOW_FIELD_VIEW,
      payload: {
        keySection: indexSection,
        keyItem,
        new_value,
        validation,
      },
    });
    if (updateSectionWithoutValidation) {
      try {
        userState.onboardingIdData.onboardingFlow.stepper[
          userState?.tabSelected?.index
        ].stepSections[indexSection].sectionItems[keyItem].value = new_value;
        await changeOnboardingSectionItems(
          newOnboardingData?._id,
          {
            sectionItems:
              userState?.onboardingIdData?.onboardingFlow?.stepper[
                userState?.tabSelected?.index
              ].stepSections[indexSection].sectionItems,
            stepperIndex: userState?.tabSelected?.index,
            sectionIndex: indexSection,
          },
          USER_CONST.REQUEST_CHANGE_USER
        );
      } catch (error) {
        console.log("Error updating DocuSign status: ", error);
      }
    }
  };

  const handleUpdateFieldsItems = async () => {
    try {
      await changeOnboardingSectionItems(
        newOnboardingData?._id,
        {
          sectionItems:
            userState?.onboardingIdData?.onboardingFlow?.stepper[
              userState?.tabSelected?.index
            ].stepSections[userState?.sectionEditSelected].sectionItems,
          stepperIndex: userState?.tabSelected?.index,
          sectionIndex: userState?.sectionEditSelected,
        },
        USER_CONST.REQUEST_CHANGE_USER
      );
      dispatchUserAction({
        type: actionTypes.SHOW_MODAL_UPD_FIELD,
        payload: { active: false, value: null },
      });
      dispatchUserAction({
        type: actionTypes.UPD_ONBOARDING_FLOW_FIELD_USER,
      });
      dispatchUserAction({
        type: actionTypes.SET_SECTION_EDIT_SELECTED,
        payload: { sectionEditSelected: null },
      });
    } catch (err) {
      setIsError({
        active: true,
        error: `Failed to /changeOnboardingSectionItems: ${err.cause ?? err}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
  };

  const submitForm = async (event) => {
    event.stopPropagation();
    if (viewMode === true) {
      if (
        userState?.tabSelected?.index !==
        userState?.onboardingIdData?.onboardingFlow?.stepper?.length - 1
      ) {
        return await changeTabSelected();
      } else return;
    }
    // Is not the LAST step (Summary)
    if (
      userState?.tabSelected?.index !==
      userState?.onboardingIdData?.onboardingFlow?.stepper?.length - 1
    ) {
      try {
        // Save (POST) info in onboardingFlow
        if (
          userState?.onboardingIdData?.status ===
          STATUS_CONST.STATUS_NEW_OPPORTUNITY
        ) {
          const response = await fetch(
            `${SERVER_URL}/onboarding/${userState?.onboardingIdData?._id}`,
            {
              method: "POST",
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "auth-token": user?.token,
              },
              body: JSON.stringify({ data: userState?.onboardingIdData }),
            }
          );
          if (!response.ok) {
            const errorMsg = await response.json();
            throw Object.assign(
              new Error(
                `${response.statusText} : ${errorMsg.error}. Status: ${response.status}`
              ),
              {
                status: response.status,
                statusText: response.statusText,
                cause: errorMsg.error,
              }
            );
          }
          dispatchUserAction({
            type: actionTypes.SET_ONBOARDING_DATA,
            payload: { onboardingIdData: userState?.onboardingIdData },
          });
        }
        return await changeTabSelected();
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to /  : ${err.cause ?? err}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    } else {
      if (
        userState?.onboardingIdData?.status ===
        STATUS_CONST.STATUS_NEW_OPPORTUNITY
      ) {
        setShowDoneModal(true);
        try {
          const response = await fetch(
            `${SERVER_URL}/onboarding/${userState?.onboardingIdData?._id}`,
            {
              method: "POST",
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "auth-token": user?.token,
              },
              body: JSON.stringify({ data: userState?.onboardingIdData }),
            }
          );
          if (!response.ok) {
            const errorMsg = await response.json();
            throw Object.assign(
              new Error(
                `${response.statusText} : ${errorMsg.error}. Status: ${response.status}`
              ),
              {
                status: response.status,
                statusText: response.statusText,
                cause: errorMsg.error,
              }
            );
          }
          const response2 = await fetch(
            `${SERVER_URL}/onboarding/${userState?.onboardingIdData?._id}/submit`,
            {
              method: "POST",
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                "auth-token": user?.token,
              },
            }
          );
          if (!response2.ok) {
            const errorMsg = await response2.json();
            throw Object.assign(
              new Error(
                `${response2.statusText} : ${errorMsg.error}. Status: ${response2.status}`
              ),
              {
                status: response2.status,
                statusText: response2.statusText,
                cause: errorMsg.error,
              }
            );
          }
        } catch (err) {
          setIsError({
            active: true,
            error: `Failed to /submitForm: ${err.cause ?? err}`,
            status: err?.status,
            statusText: err?.statusText,
          });
        }
      } else {
        navigate(`/flows/${user?.userId}`);
      }
    }
  };

  const onClickDoneModal = () => {
    setShowDoneModal(false);
    navigate(`/flows/${user?.userId}`);
  };

  const onClickSkip = async () => {
    changeTabSelected(userState?.tabSelected?.index + 1);
  };

  const processPreviousSteppers = () => {
    const convertListIntoString = (sectionItem) => {
      const selectedOptions = sectionItem.value.selectedOptions;
      const inputAdded = sectionItem.value.inputAdded;
      let selectedOptionsString = "";

      for (let i = 0; i < selectedOptions.length; i++) {
        const option = selectedOptions[i];

        if (option === "Other") {
          selectedOptionsString += `${option} (${inputAdded.join(", ")})`;
        } else {
          selectedOptionsString += option;
        }

        if (i < selectedOptions.length - 1) {
          selectedOptionsString += ", ";
        }
      }

      return selectedOptionsString;
    };

    let stepper = [];
    for (
      let index = 0;
      index < userState?.onboardingIdData?.onboardingFlow?.stepper?.length - 1;
      index++
    ) {
      stepper.push(
        <div key={index}>
          <div
            className="titleText"
            style={{ position: "relative", paddingBottom: "20px" }}
          >
            {
              userState?.onboardingIdData?.onboardingFlow?.stepper[index]
                ?.stepName
            }
            <Edit
              classes={"editIcon"}
              onClick={() => changeTabSelected(index)}
            />
          </div>
          {userState?.onboardingIdData?.onboardingFlow?.stepper[
            index
          ]?.stepSections.map((item, indexSection) => (
            <div key={indexSection}>
              <div className={"stepSection-wrapper-summary"}>
                {item?.sectionItems?.map((sectionItem, keyItem) => {
                  return (
                    <li key={keyItem} className="summary__item">
                      <span className="summary__labelName">
                        {sectionItem?.labelName}
                        {sectionItem?.required ? "*" : ""}:{" "}
                      </span>
                      <span
                        className={`summary__value ${
                          sectionItem?.required &&
                          (!String(sectionItem?.value) ||
                            String(sectionItem?.value) === "null" ||
                            (typeof sectionItem?.value === "object" &&
                              sectionItem?.value?.selectedOptions?.length ===
                                0))
                            ? "summary__value-red"
                            : null
                        }`}
                      >
                        {!String(sectionItem?.value) ||
                        String(sectionItem?.value) === "null" ||
                        (typeof sectionItem?.value === "object" &&
                          sectionItem?.value?.selectedOptions?.length === 0)
                          ? "[empty]"
                          : String(sectionItem?.value) === "File"
                          ? "Uploaded"
                          : String(sectionItem?.inputType) ===
                            "MultipleCheckbox"
                          ? convertListIntoString(sectionItem)
                          : String(sectionItem?.inputType) === "Table"
                          ? sectionItem?.value
                              ?.map(
                                (element) => element[Object.keys(element)[0]]
                              )
                              .join(", ")
                          : String(sectionItem?.value)}
                      </span>
                    </li>
                  );
                })}
              </div>
            </div>
          ))}
          <div className="flow-dividerSection"></div>
        </div>
      );
    }
    return <div>{stepper}</div>;
  };

  const processCurrentStepper = () => {
    return (
      <>
        {userState?.onboardingIdData?.onboardingFlow?.stepper[
          userState?.tabSelected?.index
        ]?.stepSections.map((item, indexSection) => (
          <div key={indexSection}>
            {item?.sectionTitle && (
              <div className="titleText" style={{ paddingBottom: "20px" }}>
                {item.sectionTitle}
              </div>
            )}
            {item?.sectionDescription && (
              <div
                className="descriptionTitle"
                style={{ paddingBottom: "20px" }}
              >
                {item.sectionDescription}
              </div>
            )}
            <div className={"stepSection-wrapper-summary"}>
              {item?.sectionItems?.map((sectionItem, keyItem) =>
                renderComponentBaseOnType(
                  sectionItem,
                  keyItem,
                  indexSection,
                  sectionIsDisabled(
                    userState?.sectionEditSelected,
                    indexSection,
                    item?.status,
                    userState?.onboardingIdData?.status
                  ),
                  updateStringNestedProps
                )
              )}
            </div>
          </div>
        ))}
        {renderWarningMsg("user__lastNotificationCard-wrapper")}
        <div className="flow-dividerSection"></div>
      </>
    );
  };

  const processSummary = () => {
    return (
      <div>
        {userState?.onboardingIdData?.onboardingFlow?.stepper &&
          processPreviousSteppers()}
        {userState?.onboardingIdData?.onboardingFlow?.stepper &&
          processCurrentStepper()}
      </div>
    );
  };

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  if (!userState || !userState?.onboardingIdData?.onboardingFlow) {
    return (
      <div className="loader__wrapper-withHeader">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={
        userState?.onboardingIdData?.status ===
          STATUS_CONST.STATUS_NEW_OPPORTUNITY && showDoneModal
          ? "hideScrollY"
          : null
      }
    >
      {userState?.onboardingIdData?.status ===
        STATUS_CONST.STATUS_NEW_OPPORTUNITY &&
        showDoneModal && (
          <div onClick={onClickDoneModal} className="modalBackDropFilter">
            <Modal>
              <h1 className="modalBackDropFilter__title title__green">
                {USER_CONST.DONE_MODAL_TITLE}
              </h1>
              <span className="modalBackDropFilter__description">
                {USER_CONST.DONE_MODAL_DESCRIPTION}
              </span>
              <div className="modalBackDropFilter-wrapperImages">
                <div className="modalBackDropFilter-card">
                  <img src={imgResources} alt="Resources" />
                  <p className="modalBackDropFilter-cardTitle">
                    {USER_CONST.DONE_MODAL_RESOURCES}
                  </p>
                </div>
                <div className="modalBackDropFilter__card-divider"></div>
                <div className="modalBackDropFilter-card">
                  <img
                    src={imgOrgDetails}
                    alt="Monitor your approval process"
                  />
                  <p className="modalBackDropFilter-cardTitle">
                    {USER_CONST.DONE_MODAL_APPROVAL_PROCESS}
                  </p>
                </div>
                <div className="modalBackDropFilter__card-divider"></div>
                <div className="modalBackDropFilter-card">
                  <img src={imgExtra} alt="Browse our gear catalog" />
                  <p className="modalBackDropFilter-cardTitle">
                    {USER_CONST.DONE_MODAL_GEAR_CATALOG}
                  </p>
                </div>
              </div>
              <Button
                classes="active"
                onClick={onClickDoneModal}
                title={BUTTON_CONST.BUTTON_GO_MY_FLOWS}
              />
            </Modal>
          </div>
        )}
      <div className="completePage">
        <Stepper
          steps={userState?.onboardingIdData?.onboardingFlow?.stepper?.map(
            (item) => item.stepName
          )}
          indexStepper={userState?.tabSelected?.index}
          setIndexStepper={(key) => {
            changeTabSelected(key);
          }}
        />
        {userState?.tabSelected?.index <
          userState?.onboardingIdData?.onboardingFlow?.stepper?.length && (
          <>
            <div className="topText">
              <div className="titleSection">
                <span className="titleText">
                  {
                    userState?.onboardingIdData?.onboardingFlow?.stepper[
                      userState?.tabSelected?.index
                    ].stepTitle
                  }
                </span>
              </div>
              <span className="descriptionText">
                {
                  userState?.onboardingIdData?.onboardingFlow?.stepper[
                    userState?.tabSelected?.index
                  ].stepDescription
                }
              </span>
            </div>
            <form className="formSection">
              {userState?.tabSelected?.index === 0 &&
                renderWarningMsg("user__notificationCard-wrapper")}
              {userState?.tabSelected?.index !==
                userState?.onboardingIdData?.onboardingFlow?.stepper?.length -
                  1 &&
                userState?.onboardingIdData?.onboardingFlow?.stepper[
                  userState?.tabSelected?.index
                ].stepSections.map((item, indexSection) => {
                  refBubble.current[indexSection] =
                    refBubble.current[indexSection] || React.createRef();
                  return (
                    <div
                      key={indexSection}
                      className="admin__stepper-sectionContent user__stepper-sectionContent"
                    >
                      <div className="admin__stepper-sectionHeader">
                        {item?.sectionTitle && (
                          <span className="admin__stepper-sectionHeaderT">
                            {item?.sectionTitle}
                          </span>
                        )}
                        {userState?.onboardingIdData?.status !==
                          STATUS_CONST.STATUS_NEW_OPPORTUNITY && (
                          <div className="admin__stepper-sectionHeaderE">
                            <span className="admin__stepper-lastUpdated">
                              {USER_CONST.LAST_UPDATED_BY}
                            </span>
                            <span className="admin__violetText">
                              {item?.lastUpdatedBy}
                            </span>
                            <span
                              className={getClassNameForStatus(item?.status)}
                            >
                              {item?.status}
                            </span>
                            {item?.status &&
                              indexSection ===
                                userState?.sectionEditSelected && (
                                <>
                                  <Button
                                    classes="active"
                                    className="button__edit-section"
                                    disabled={isButtonDisabled}
                                    onClick={() => {
                                      setIsButtonDisabled(true);
                                      dispatchUserAction({
                                        type: actionTypes.SHOW_MODAL_UPD_FIELD,
                                        payload: {
                                          active: true,
                                          value: null,
                                          indexSection,
                                        },
                                      });
                                      setTimeout(() => {
                                        setIsButtonDisabled(false);
                                      }, USER_CONST.TIMER_WAIT_TO_EXECUTE);
                                    }}
                                    title={BUTTON_CONST.BUTTON_SAVE}
                                  />
                                  <Button
                                    classes="secondary"
                                    className="button__edit-section"
                                    onClick={() => {
                                      dispatchUserAction({
                                        type: actionTypes.CANCEL_EDIT_SECTION,
                                      });
                                    }}
                                    title={BUTTON_CONST.BUTTON_CANCEL}
                                  />
                                </>
                              )}
                            {indexSection !==
                              userState?.sectionEditSelected && (
                              <>
                                {item?.status !==
                                  STATUS_CONST.STATUS_APPROVED && (
                                  <Button
                                    classes="active"
                                    className="button__edit-section"
                                    image={{
                                      src: EditPencil,
                                      alt: "Pencil Edit",
                                    }}
                                    onClick={() => {
                                      dispatchUserAction({
                                        type: actionTypes.SET_SECTION_EDIT_SELECTED,
                                        payload: {
                                          sectionEditSelected: indexSection,
                                        },
                                      });
                                    }}
                                  />
                                )}
                                <div
                                  id={`refBubble-${indexSection}`}
                                  ref={refBubble.current[indexSection]}
                                >
                                  <div
                                    className={`chat__bubble-wrapper ${
                                      userState?.showModalChat?.active &&
                                      userState?.showModalChat?.stepperChat ===
                                        userState?.tabSelected?.index &&
                                      userState?.showModalChat?.sectionChat ===
                                        indexSection
                                        ? "chat__bubble-indexUp"
                                        : "chat__bubble-neutral"
                                    }`}
                                  >
                                    <img
                                      onClick={() => {
                                        onClickOnChatModal(
                                          userState?.tabSelected?.index,
                                          indexSection
                                        );
                                      }}
                                      src={ChatBubble}
                                      alt={`Chat Bubble ${
                                        userState?.showModalChat?.active &&
                                        userState?.showModalChat
                                          ?.stepperChat ===
                                          userState?.tabSelected?.index &&
                                        userState?.showModalChat
                                          ?.sectionChat === indexSection
                                          ? "chat__bubble-indexUp"
                                          : "chat__bubble-neutral"
                                      }`}
                                    />
                                    {userState?.msgList?.stepSections?.length >
                                      0 &&
                                      userState?.msgList?.stepSections[
                                        indexSection
                                      ]?.messages?.some(
                                        (msg) =>
                                          !msg.readBy?.includes(user?.email)
                                      ) &&
                                      (userState?.showModalChat?.active ===
                                        false ||
                                        (userState?.showModalChat?.active &&
                                          userState?.showModalChat
                                            ?.sectionChat !==
                                            indexSection)) && (
                                        <div
                                          className={`chat__bubble_notificationW ${
                                            userState?.showModalChat?.active &&
                                            userState?.showModalChat
                                              ?.stepperChat ===
                                              userState?.tabSelected?.index &&
                                            userState?.showModalChat
                                              ?.sectionChat === indexSection
                                              ? "chat__bubble-indexUp"
                                              : "chat__bubble-neutral"
                                          }`}
                                        >
                                          <div className="chat__bubble_notification"></div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                                {userState?.showModalChat?.active &&
                                  userState?.showModalChat?.stepperChat ===
                                    userState?.tabSelected?.index &&
                                  userState?.showModalChat?.sectionChat ===
                                    indexSection && (
                                    <div className="modal__chat-wrapper">
                                      <div
                                        className="modal__chat"
                                        ref={refModalContainer}
                                      >
                                        <Modal
                                          width={546}
                                          height={333}
                                          classes={"admin__chat-modal"}
                                        >
                                          <div className="modal__header">
                                            <h1 className="modal__header-main">
                                              {USER_CONST.TITLE_CHAT}
                                            </h1>
                                          </div>
                                          <main
                                            className={`admin__chat-main ${
                                              isButtonDisabled
                                                ? "chat__overflowY-hidden"
                                                : ""
                                            }`}
                                            ref={messageListRef}
                                          >
                                            {isButtonDisabled ? (
                                              <Loader />
                                            ) : (
                                              userState?.msgList?.stepSections
                                                ?.length > 0 &&
                                              userState?.msgList?.stepSections[
                                                indexSection
                                              ]?.messages?.map(
                                                (message, indexMsg) => {
                                                  return (
                                                    <div
                                                      key={indexMsg}
                                                      id={`chat-msg-${indexMsg}`}
                                                      className="admin__chat-msgs"
                                                    >
                                                      <div className="modal__chat-avatar">
                                                        {getInitialsForAvatar(
                                                          `${message?.from?.firstName[0]?.toLocaleUpperCase()}${message?.from?.lastName[0]?.toLocaleUpperCase()}`,
                                                          userState?.initials
                                                        )}
                                                      </div>
                                                      <div className="chat__content">
                                                        <span className="chat__content-msg">
                                                          {message?.message}
                                                        </span>
                                                        <span className="chat__content-date">
                                                          {`${formatDateToStringYYYYMMDDHHMMSS(
                                                            message?.datetime
                                                          )} - ${
                                                            userState?.initials ===
                                                            message?.from?.firstName[0]?.toLocaleUpperCase() +
                                                              message?.from?.lastName[0]?.toLocaleUpperCase()
                                                              ? "You"
                                                              : message?.from
                                                                  .firstName
                                                          } sent a message`}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )
                                            )}
                                          </main>
                                          <footer className="modal__chat-footer">
                                            <div className="modal__chat-inputW">
                                              <div className="modal__chat-avatar">
                                                {getInitialsForAvatar(
                                                  userState?.initials,
                                                  userState?.initials
                                                )}
                                              </div>
                                              <textarea
                                                disabled={isButtonDisabled}
                                                type="text"
                                                className={`modal__chat-textarea`}
                                                value={userState?.msgTemp}
                                                onChange={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  dispatchUserAction({
                                                    type: actionTypes.SET_CURRENT_COMMENT,
                                                    payload: {
                                                      msgTemp: e.target.value,
                                                    },
                                                  });
                                                }}
                                              ></textarea>
                                            </div>
                                            <div className="chat__button-commentW">
                                              <Button
                                                classes="secondary"
                                                className={
                                                  "chat__button-comment"
                                                }
                                                disabled={isButtonDisabled}
                                                style={{
                                                  visibility: `${
                                                    isButtonDisabled
                                                      ? "hidden"
                                                      : "visible"
                                                  }`,
                                                }}
                                                title={
                                                  BUTTON_CONST.BUTTON_COMMENT
                                                }
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  addNewCommentOnChat(
                                                    userState?.tabSelected
                                                      ?.index,
                                                    indexSection,
                                                    userState?.msgTemp
                                                  );
                                                }}
                                              />
                                            </div>
                                          </footer>
                                        </Modal>
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          userState?.onboardingIdData?.onboardingFlow.stepper[
                            userState?.tabSelected?.index
                          ].stepName === "Registration Method"
                            ? "stepSection-wrapper-middle"
                            : "stepSection-wrapper"
                        }
                      >
                        {item?.sectionItems?.map((sectionItem, keyItem) =>
                          renderComponentBaseOnType(
                            sectionItem,
                            keyItem,
                            indexSection,
                            sectionIsDisabled(
                              userState?.sectionEditSelected,
                              indexSection,
                              item?.status,
                              userState?.onboardingIdData?.status
                            ),
                            updateStringNestedProps,
                            userState?.onboardingIdData
                          )
                        )}
                      </div>
                      <div className="flow-dividerSection"></div>
                    </div>
                  );
                })}
              {userState?.tabSelected?.index ===
                userState?.onboardingIdData?.onboardingFlow?.stepper?.length -
                  1 && (
                <div key={userState?.tabSelected?.index}>
                  {processSummary()}
                </div>
              )}
              <div className="buttonSection">
                <Button
                  classes="secondary"
                  style={{
                    visibility: `${
                      userState?.tabSelected?.index === 0 ? "hidden" : "visible"
                    }`,
                  }}
                  onClick={() => {
                    changeTabSelected(userState?.tabSelected?.index - 1);
                  }}
                  title={BUTTON_CONST.BUTTON_BACK}
                />
                <div className="rightSection">
                  {userState?.tabSelected?.index !==
                    userState?.onboardingIdData?.onboardingFlow?.stepper
                      ?.length -
                      1 && (
                    <Button
                      classes="transparent"
                      onClick={onClickSkip}
                      title={BUTTON_CONST.BUTTON_SKIP}
                    />
                  )}
                  <Button
                    classes={`${
                      userState?.tabSelected?.index ===
                        userState?.onboardingIdData?.onboardingFlow?.stepper
                          .length -
                          1 &&
                      !userState?.onboardingIdData?.onboardingFlow?.stepper[
                        userState?.tabSelected?.index
                      ].stepSections.some((item) =>
                        item.sectionItems.some(
                          (subitem) =>
                            subitem.required && subitem.value === true
                        )
                      )
                        ? "disabled"
                        : "active"
                    }`}
                    disabled={
                      userState?.tabSelected?.index ===
                        userState?.onboardingIdData?.onboardingFlow?.stepper
                          .length -
                          1 &&
                      !userState?.onboardingIdData?.onboardingFlow?.stepper[
                        userState?.tabSelected?.index
                      ].stepSections.some((item) =>
                        item.sectionItems.some(
                          (subitem) =>
                            subitem.required && subitem.value === true
                        )
                      )
                    }
                    onClick={submitForm}
                    title={
                      userState?.onboardingIdData?.status ===
                        STATUS_CONST.STATUS_NEW_OPPORTUNITY &&
                      userState?.tabSelected?.index ===
                        userState?.onboardingIdData?.onboardingFlow?.stepper
                          ?.length -
                          1
                        ? BUTTON_CONST.BUTTON_SUBMIT
                        : BUTTON_CONST.BUTTON_CONTINUE
                    }
                  />
                </div>
              </div>
            </form>
          </>
        )}
      </div>
      {userState?.showModalUpdateField?.active && (
        <div className="admin__modal-overaly">
          <Modal classes={"modal_wrapper_confirmation"}>
            <div>
              <img src={ImgQuestion} alt="Ask Question" />
            </div>
            <div className="modal__wrapper-title">
              <span className="modal__title">
                {USER_CONST.ORG_ASK_APPROVE_FIELD}
              </span>
            </div>
            <span className="modal__description-text">
              {USER_CONST.ORG_ASK_APPROVE_FIELD_DESC}
            </span>
            <div className="modal__twoButtons">
              <Button
                classes={"secondary"}
                disabled={isButtonDisabled}
                onClick={() => {
                  setIsButtonDisabled(true);
                  dispatchUserAction({
                    type: actionTypes.CANCEL_UPD_FIELD,
                  });
                  setTimeout(() => {
                    setIsButtonDisabled(false);
                  }, USER_CONST.TIMER_WAIT_TO_EXECUTE);
                }}
                title={BUTTON_CONST.BUTTON_CANCEL}
              />
              <Button
                classes={"active"}
                disabled={isButtonDisabled}
                onClick={async () => {
                  setIsButtonDisabled(true);
                  await handleUpdateFieldsItems();
                  setIsButtonDisabled(false);
                }}
                title={BUTTON_CONST.BUTTON_UPDATE}
              />
            </div>
          </Modal>
        </div>
      )}
      {userState?.showModalExitWithoutSaving?.active && (
        <div className="admin__modal-overaly">
          <Modal classes={"modal_wrapper_confirmation"}>
            <div className="modal__xMark">
              <svg
                onClick={handleCancelSaveYourChanges}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z"
                  fill="#0F172A"
                />
              </svg>
            </div>
            <div>
              <img src={AlertIcon} alt="Alert Question" />
            </div>
            <div className="modal__wrapper-title">
              <span className="modal__title">
                {USER_CONST.LEAVE_WITHOUT_SAVING}
              </span>
            </div>
            <span className="modal__description-text">
              {USER_CONST.LEAVE_WITHOUT_SAVING_DESC}
            </span>
            <div className="modal__twoButtons">
              <Button
                classes={"secondary"}
                disabled={isButtonDisabled}
                onClick={handleDontSaveYourChangesAndContinue}
                title={BUTTON_CONST.BUTTON_DONT_SAVE}
              />
              <Button
                classes={"active"}
                disabled={isButtonDisabled}
                onClick={handleSaveYourChangesAndContinue}
                title={BUTTON_CONST.BUTTON_SAVE_CONTINUE}
              />
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};
