import React, { useCallback, useContext, useRef, useState } from "react";
// Component
import ErrorTemplate from "../AdminBuilder/ErrorTemplate/ErrorTemplate";
import Loader from "../../Loader/Loader";
import AdminHeader from "../AdminBuilder/AdminHeader/AdminHeader";
import Dropdown from "../../FormComponents/Dropdown/Dropdown";
import Button from "../../Button/Button";
import InputText from "../../FormComponents/InputText/InputText";
// Context
import AsideContext from "../../../context/AsideMenu/AsideContext";
// Utils
import { useGetListsManagment } from "../../../hooks/useGetListsManagment";
// Styles
import "./AdminLists.css";

const columns = ["ID", "Value"];

const AdminLists = () => {
  const asideContext = useContext(AsideContext);

  const { listsManagment, error, isLoading, setListsManagment } =
    useGetListsManagment();
  const [selectedList, setSelectedList] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [selectedListIndex, setSelectedListIndex] = useState(null);
  const [newValueInput, setNewValueInput] = useState("");
  const inputRef = useRef(null);

  const handleListChange = useCallback(
    (event) => {
      setSelectedList(event.target.value);
      const newIndex = listsManagment.findIndex(
        (list) => list.name === selectedList
      );
      setSelectedListIndex(newIndex);
      setIsEditing(false);
    },
    [listsManagment, selectedList]
  );

  const handleDeleteClickedRow = useCallback(
    (_, indexToDelete) => {
      const updatedLists = [...listsManagment];
      const selectedListIndex = updatedLists.findIndex(
        (list) => list.name === selectedList
      );
      if (selectedListIndex !== -1) {
        updatedLists[selectedListIndex].items.splice(indexToDelete, 1);
        setListsManagment([...updatedLists]);
        // Llama a la API para actualizar el servidor
        // Aquí puedes realizar una llamada a la API para eliminar la fila en el servidor
      }
    },
    [listsManagment, selectedList, setListsManagment]
  );

  const handleEditClickedRow = (index) => {
    const newIndex = listsManagment.findIndex(
      (list) => list.name === selectedList
    );
    setSelectedListIndex(newIndex);
    setIsEditing(index);
    setEditedValue(listsManagment[newIndex].items[index]);
    setNewValueInput("");
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 50);
  };

  const handleSaveClickedRow = (index) => {
    const updatedLists = [...listsManagment];
    updatedLists[selectedListIndex].items[index] = editedValue;
    setListsManagment(updatedLists);
    setIsEditing(false);
  };

  const handleAddNewRow = () => {
    if (newValueInput.trim() === "") {
      return;
    }
    const updatedLists = [...listsManagment];
    const selectedListIndex = updatedLists.findIndex(
      (list) => list.name === selectedList
    );
    updatedLists[selectedListIndex].items.push(newValueInput);
    setListsManagment(updatedLists);
    // Llama a la API para guardar el cambio en el servidor, si es necesario
    setNewValueInput("");
  };
  const handleOnChangeNewInput = (event) => {
    console.log("handleOnChangeNewInput");
    setNewValueInput(event?.target?.value);
  };

  return (
    <div
      className={`${
        asideContext.asideOpen ? "admin__content-expanded" : "admin__content"
      }`}
    >
      {error && <ErrorTemplate error={error} />}
      {isLoading && (
        <div className="workFlowList__loader">
          <Loader />
        </div>
      )}
      {!error && !isLoading && (
        <>
          <AdminHeader title={"Lists Managment"} />
          <div className="adminList__wrapper">
            <Dropdown
              options={listsManagment.map((list) => list.name)}
              onChange={handleListChange}
              placeholder={"Select a list..."}
              value={selectedList}
              wrapperClassName={"stringInput"}
            />
          </div>
          {selectedList && (
            <div className="adminList__content-wrapper">
              <h1 className="adminList__mainTitle">{`List '${selectedList}' detail`}</h1>
              <div>
                <table className="adminList__table">
                  <thead className="adminList__thead">
                    <tr className="adminList-headerC">
                      {columns?.map((column, index) => (
                        <th
                          key={index}
                          scope="col"
                          className={`adminList-columnTitle
                                  }`}
                        >
                          {column}
                        </th>
                      ))}
                      <th
                        scope="col"
                        className={`adminList-columnTitle-action`}
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="adminList__body-wrapper">
                    {listsManagment
                      .find((list) => list.name === selectedList)
                      .items.map((item, index) => (
                        <>
                          <tr key={index} className="adminList__body-tr">
                            <td className={`adminList__rowData`}>
                              <div>{index}</div>
                            </td>
                            {isEditing === index ? (
                              <td className={`adminList__rowData`}>
                                <input
                                  className="adminList__editableRow-input"
                                  onChange={(e) =>
                                    setEditedValue(e.target.value)
                                  }
                                  onKeyDown={(event) => {
                                    return (
                                      event.key === "Enter" &&
                                      handleSaveClickedRow(index)
                                    );
                                  }}
                                  ref={inputRef}
                                  type="text"
                                  value={editedValue}
                                />
                              </td>
                            ) : (
                              <td className={`adminList__rowData`}>
                                <div>{item}</div>
                              </td>
                            )}
                            <td className="adminList__btn-action">
                              <div className="adminList__btn-action-wrapper">
                                {isEditing === index ? (
                                  <>
                                    <Button
                                      classes={"secondary"}
                                      disabled={false}
                                      onClick={() => setIsEditing(false)}
                                      style={{
                                        width: "fit-content",
                                        minHeight: "100%",
                                      }}
                                      title="Cancel"
                                    />
                                    <Button
                                      classes={"secondary"}
                                      disabled={false}
                                      onClick={() =>
                                        handleSaveClickedRow(index)
                                      }
                                      style={{
                                        width: "fit-content",
                                        minHeight: "100%",
                                      }}
                                      title="Save"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      classes={"secondary"}
                                      disabled={false}
                                      onClick={() =>
                                        handleEditClickedRow(index)
                                      }
                                      style={{
                                        width: "fit-content",
                                        minHeight: "100%",
                                      }}
                                      title="Edit"
                                    />
                                    <Button
                                      classes={"secondary"}
                                      disabled={false}
                                      onClick={(event) =>
                                        handleDeleteClickedRow(event, index)
                                      }
                                      style={{
                                        width: "fit-content",
                                        minHeight: "100%",
                                      }}
                                      title="Delete"
                                    />
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
                {typeof isEditing === "boolean" && (
                  <div className="admin__stepSection-wrapper adminList__btn-addNew">
                    <InputText
                      label={"Add new Value"}
                      onChange={(event) => handleOnChangeNewInput(event)}
                      onKeyPress={(event) => {
                        return event.key === "Enter" && handleAddNewRow();
                      }}
                      placeholder="Write here a new value..."
                      value={newValueInput || ""}
                    />
                    <Button
                      classes={
                        newValueInput.length === 0 ? "disabled" : "active"
                      }
                      disabled={newValueInput.length === 0}
                      onClick={handleAddNewRow}
                      style={{
                        width: "fit-content",
                        height: "48px",
                        minHeight: "48px",
                        alignSelf: "flex-end",
                      }}
                      title="Add"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdminLists;
