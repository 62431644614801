import { useEffect, useState } from "react";
import { getBaseURL } from "../utils/getBaseURL";
// Utils
import { useAuth } from "../utils/authenticate";

const SERVER_URL = getBaseURL();

export function useGetListsManagment() {
  const { user } = useAuth();
  const [listsManagment, setListsManagment] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchListsManagment = async () => {
      try {
        const res = await fetch(`${SERVER_URL}/admin/builder/list`, {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "auth-token": user?.token,
          },
        });
        if (!res.ok) {
          const errorMsg = await res.json();
          throw Object.assign(
            new Error(
              `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
            ),
            {
              status: res.status,
              statusText: res.statusText,
              cause: errorMsg.error,
            }
          );
        }
        const data = await res.json();
        setListsManagment(data);
        setIsLoading(false);
        setError(null);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchListsManagment();
  }, [user?.token]);

  return { listsManagment, error, isLoading, setListsManagment };
}
