import React, { useContext, useEffect, useState } from "react";
// Context
import AsideContext from "../../../context/AsideMenu/AsideContext";
import AdminBuilderContext from "../../../context/AdminBuilder/AdminBuilderContext";
// Components
import AdminHeader from "./AdminHeader/AdminHeader";
import AdminBuilderNav from "./AdminBuilderNav/AdminBuilderNav";
import FlowTypesList from "./FlowTypesList/FlowTypesList";
import Loader from "../../Loader/Loader";
import WorkflowList from "./WorkflowList/WorkflowList";
// Reducers
import { actionTypes } from "../../../reducers/AdminBuilder/reducer";
// Constants
import * as BUILDER_CONST from "../../../constants/builderPage";
// Utils
import { useAuth } from "../../../utils/authenticate";
import { getCurrentFlows } from "../../../utils/getCurrentFlows";
// Styles
import "./AdminBuilder.css";
import classNames from "classnames";

const AdminBuilder = () => {
  const { user } = useAuth();

  const asideContext = useContext(AsideContext);
  const { adminBuildState, dispatchAdminBuilderAction } =
    useContext(AdminBuilderContext);

  const adminBuilderClasses = classNames(
    {
      "admin__content-expanded": asideContext?.asideOpen,
    },
    { admin__content: !asideContext?.asideOpen },
    {
      admin__hideScrollY:
        adminBuildState?.showModalSendWorkflow?.active ||
        adminBuildState?.showModalOkInvites?.active ||
        adminBuildState?.showModalErrorInvites?.activeUserAlreadyHas ||
        adminBuildState?.showModalErrorInvites?.activeUserNotExist,
    },
    {
      "admin__hideScrollY-aside":
        (adminBuildState?.showModalSendWorkflow?.active ||
          adminBuildState?.showModalOkInvites?.active ||
          adminBuildState?.showModalErrorInvites?.activeUserAlreadyHas ||
          adminBuildState?.showModalErrorInvites?.activeUserNotExist) &&
        asideContext.asideOpen,
    }
  );

  const [isError, setIsError] = useState({ active: false, error: "" });
  const [typeView, setTypeView] = useState(0);

  const handleProgramChange = async (index, stepName) => {
    dispatchAdminBuilderAction({
      type: actionTypes.SET_TAB_SELECTED,
      payload: {
        index,
        stepName,
      },
    });
  };

  const handleTypeViewChange = async () => {
    if (typeView === 0) {
      setTypeView(1);
    } else {
      setTypeView(0);
    }
  };

  useEffect(() => {
    const retrieveCurrentFlows = async () => {
      try {
        const currentFlowsList = await getCurrentFlows(user?.token);
        dispatchAdminBuilderAction({
          type: actionTypes.SET_PROGRAMS_FLOWS,
          payload: {
            currentFlows: currentFlowsList?.programs?.map(
              (program) => program.name
            ),
          },
        });
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to get current flows: ${err?.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    };
    if (Object.keys(adminBuildState?.programFlows).length === 0) {
      retrieveCurrentFlows();
    }
  }, [
    adminBuildState?.programFlows,
    adminBuildState?.programFlows.length,
    dispatchAdminBuilderAction,
    user?.token,
  ]);

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  return (
    <div className={adminBuilderClasses}>
      <AdminHeader title={BUILDER_CONST.ONBOARDING_FLOWS} />
      {adminBuildState?.isLoading ? (
        <Loader />
      ) : (
        <>
          <AdminBuilderNav
            handleProgramChange={handleProgramChange}
            handleTypeViewChange={handleTypeViewChange}
            programList={adminBuildState?.tabNames}
            selectedProgram={adminBuildState?.tabSelected}
            selectedTypeView={typeView}
          />
          {typeView === 0 && (
            <WorkflowList
              program={
                adminBuildState?.tabSelected?.stepName ||
                adminBuildState?.tabNames[0]
              }
            />
          )}
          {typeView === 1 && (
            <FlowTypesList
              program={
                adminBuildState?.tabSelected?.stepName ||
                adminBuildState?.tabNames[0]
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default AdminBuilder;
