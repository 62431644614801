import React from "react";
import { getTopBarLogoForLeadType } from "../../utils/getLogoImage";

const TopHeaderProgramImage = ({ leadType }) => {
  return (
    <div>
      <img
        className={leadType ? "topbar_image" : "topbar_image-default"}
        src={getTopBarLogoForLeadType(leadType)}
        alt="Logo"
      />
    </div>
  );
};

export default TopHeaderProgramImage;
