export function downloadFile(data) {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", generateFilename());
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function convertToCSV(objArray) {
  const header = Object.keys(objArray[0]).join(",");
  const rows = objArray.map((obj) =>
    Object.values(obj)
      .map((val) => `"${val}"`)
      .join(",")
  );
  return [header, ...rows].join("\n");
}

export function generateFilename() {
  const currentDate = new Date();
  const formattedDate = `${
    currentDate.getMonth() + 1
  }-${currentDate.getDate()}-${currentDate.getFullYear()}`;
  const formattedTime = currentDate.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  const meridiem = formattedTime.slice(-2);
  const formattedTimeWithoutMeridiem = formattedTime.slice(0, -3);
  const formattedFileName = `OrganizationsFlows_${formattedDate}_${formattedTimeWithoutMeridiem.replace(
    /:/g,
    "."
  )}_${meridiem}.csv`;

  return formattedFileName;
}
