import React, { useState } from "react";
import PropTypes from "prop-types";
// Icons
import Checked from "../Icons/Checked/Checked";
import Eye from "../Icons/Eye/Eye";
import Info from "../Icons/Info/Info";
import Add from "../Icons/Add/Add";
// Styles
import "./Input.css";

const Input = (props) => {
  const {
    className,
    checkedPassword,
    disabled,
    error,
    label,
    informativeText,
    onChange,
    onKeyPress,
    onClickIconRight,
    onFocus,
    placeholder,
    required,
    style,
    type,
    value,
    width,
  } = props;

  const [eyeIconOpened, setEyeIconOpened] = useState(false);
  const [inputData, setInputData] = useState("");

  const onClickEyeIcon = () => {
    setEyeIconOpened(!eyeIconOpened);
  };

  const onChangeData = (e) => {
    setInputData(e.target.value);
  };

  const onClickAddButton = () => {
    if (inputData.length > 0) {
      onClickIconRight(inputData);
      setInputData("");
      document.getElementsByClassName("inputComponent")[0].value = "";
    }
  };

  return (
    <div className="wrapper-inputString" style={{ ...style }}>
      {label?.name && (
        <label className="input-labelName" htmlFor={label.name}>
          {`${label.name}${label.required ? " *" : ""}`}
        </label>
      )}
      <div
        style={{
          position:
            type === "password" || type === "add" ? "relative" : "inherit",
        }}
      >
        <input
          className={`${
            className
              ? className
              : `inputComponent ${
                  error?.value
                    ? "inputError "
                    : disabled
                    ? "inputComponent-disabled"
                    : ""
                }
              `
          }`}
          disabled={disabled}
          onChange={type === "add" ? onChangeData : onChange}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          required={required}
          type={
            type === "password" ? (eyeIconOpened ? "text" : "password") : type
          }
          value={value}
          style={{ width: `${width}px` }}
        />
        {type === "password" && (
          <Eye
            classes={"eyePassword"}
            color={error?.value ? "#DD082F" : "#8F93A3"}
            opened={eyeIconOpened}
            onClick={onClickEyeIcon}
          />
        )}
        {type === "add" && (
          <Add
            classes={"iconRight"}
            color={"#2468DB"}
            disabled={disabled}
            onClick={onClickAddButton}
          />
        )}
        {checkedPassword && (
          <Checked classes={"checkedIcon"} color={"#00C266"} />
        )}
      </div>
      {error?.value ? (
        <div className="input-wrapperError">
          <Info color="#DD082F" height={16} width={16} />
          <span className="input-accountErrorText">{error?.message}</span>
        </div>
      ) : (
        <span
          className="input-accountInformativeText"
          style={{
            visibility: informativeText?.visible ? "visible" : "hidden",
          }}
        >
          {informativeText?.message}
        </span>
      )}
    </div>
  );
};

Input.propTypes = {
  checkedPassword: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    value: PropTypes.bool,
    message: PropTypes.string,
  }),
  label: PropTypes.shape({
    name: PropTypes.string,
    required: PropTypes.bool,
  }),
  informativeText: PropTypes.shape({
    visible: PropTypes.bool,
    message: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onClickIconRight: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.oneOf(["email", "password", "text", "input", "add"])
    .isRequired,
  value: PropTypes.string,
  with: PropTypes.number,
};

Input.defaultProps = {
  checkedPassword: false,
  disabled: false,
  placeholder: "Write here",
  required: false,
  style: {},
};

export default Input;
