// Utils
import { getImageFromLogo } from "../../../utils/getLogoImage";
// Style
import "./style.css";

const LargeCard = ({ item, selectedValue, groupName, onChange, disabled }) => {
  return (
    <div
      className={`actionableCard ${
        item?.value === selectedValue ? "activeCard" : "normalCard"
      } ${disabled ? "largeCard__disabledCard" : ""}`}
    >
      <div className="headerCard">
        <div className="headerImgCard">
          <img src={getImageFromLogo(item?.logo)} alt="Logo" />
        </div>
        <div className="headerRadioCard">
          <input
            type="radio"
            disabled={disabled}
            name={groupName}
            value={item?.value}
            checked={item?.value === selectedValue}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="textCard">
        <span className="titleCard">{item?.titleCard}</span>
        <span className="descriptionCard">{item?.descriptionCard}</span>
      </div>
    </div>
  );
};

export default LargeCard;
