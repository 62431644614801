import React from "react";
import PropTypes from "prop-types";
import "./AdminNavTab.css";

const AdminNavTab = (props) => {
  const { color, height, type, width } = props;

  return (
    <>
      {type ? (
        <div>
          {type === "pencil" && (
            <svg
              width={width}
              height={height}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 5H4V21H20V5Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 15.6863L10 19L7 15.6863V4.74983C7 3.78195 7.89375 3 9 3H11C12.1063 3 13 3.78195 13 4.74983V15.6863Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 8H13"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 10H13"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 12H13"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 15L7 15"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {type === "findUser" && (
            <svg
              width={width}
              height={height}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9885 17.5113C16.9676 17.5113 17.7613 16.7176 17.7613 15.7385C17.7613 14.7595 16.9676 13.9658 15.9885 13.9658C15.0095 13.9658 14.2158 14.7595 14.2158 15.7385C14.2158 16.7176 15.0095 17.5113 15.9885 17.5113Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.2402 16.9902L18.9132 18.6632"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.4324 21.5006C19.3695 21.5006 21.7506 19.1195 21.7506 16.1824C21.7506 13.2453 19.3695 10.8643 16.4324 10.8643C13.4953 10.8643 11.1143 13.2453 11.1143 16.1824C11.1143 19.1195 13.4953 21.5006 16.4324 21.5006Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.78452 9.97723C11.9874 9.97723 13.7731 8.19147 13.7731 5.98862C13.7731 3.78576 11.9874 2 9.78452 2C7.58166 2 5.7959 3.78576 5.7959 5.98862C5.7959 8.19147 7.58166 9.97723 9.78452 9.97723Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.7423 12.3538C11.8338 11.966 10.8366 11.75 9.78405 11.75C5.62371 11.75 2.25 15.1237 2.25 19.2841H12.1107"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {type === "keyCircle" && (
            <svg
              width={width}
              height={height}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 21.9998C17.5228 21.9998 21.9999 17.5227 21.9999 11.9999C21.9999 6.47707 17.5228 1.99994 12 1.99994C6.47713 1.99994 2 6.47707 2 11.9999C2 17.5227 6.47713 21.9998 12 21.9998Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.49999 13.4998C11.1568 13.4998 12.5 12.1567 12.5 10.4999C12.5 8.84302 11.1568 7.49988 9.49999 7.49988C7.84314 7.49988 6.5 8.84302 6.5 10.4999C6.5 12.1567 7.84314 13.4998 9.49999 13.4998Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.25 15.2502L15.8 13.7002"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.6191 12.6182L16.0004 16.9994L18.3004 14.6994"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {type === "prescription" && (
            <svg
              width={width}
              height={height}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 13.9998H17.9999"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.5 10.9996H15.5"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.4999 21.9997H3.5V2.49976H14.9999L20.4999 7.99973V21.9997Z"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.5 7.99973H15V2.49976"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.2002 17.699L16.0002 19.499L18.3002 17.199"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {type === "school" && (
            <svg
              width={width}
              height={height}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.0682 10.3467V21.1559H3.05273V10.3467"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.1138 5.79785V21.156H8.00684V5.79785"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.8645 7.00843L12.0602 3L6.25586 7.00843"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.25098 10.3467H8.00676"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.1133 10.3467H22.8691"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.8619 21.1566V15.752H10.2588V21.1566"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.80859 12.1484H14.3124"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.80859 9.89648H14.3124"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.80859 7.64453H14.3124"
                stroke={color}
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      ) : null}
    </>
  );
};

AdminNavTab.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.oneOf([
    "pencil",
    "findUser",
    "keyCircle",
    "prescription",
    "school",
  ]),
  width: PropTypes.string,
};

AdminNavTab.defaultProps = {
  color: "#17181C",
  height: "24",
  width: "24",
};

export default AdminNavTab;
