import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// Constants
import * as STATUS_CONST from "../../../constants/status";
import * as USER_CONST from "../../../constants/userPage";
// Utils
import {
  getClassNameForDetailRow,
  getClassNameForStatus,
} from "../../../utils/getClassNameForStatus";
import { getProgramLogoForLeadType } from "../../../utils/getLogoImage";
// Styles
import "./CollapsibleRow.css";
import classNames from "classnames";

const MainRow = ({ collapsed, flow, toggleCollapse }) => {
  const { steps: stepList = [] } = flow;

  const hasMessageWithLengthGtZero = useMemo(() => {
    for (const step of stepList) {
      if (
        step?.hasOwnProperty("message") &&
        typeof step?.message === "string" &&
        step?.message?.length > 0
      ) {
        return true;
      }
    }
    return false;
  }, [stepList]);

  const calculateApprovalPercentage = useMemo(() => {
    const approvedSteps = stepList?.filter(
      (step) => step?.status === STATUS_CONST.STATUS_APPROVED
    );
    const percentage =
      stepList?.length > 0
        ? (approvedSteps?.length / stepList.length) * 100
        : 0;
    return Math.floor(percentage);
  }, [stepList]);

  const calculatePercentajeProgressBar = useMemo(() => {
    return (calculateApprovalPercentage * 170) / 100;
  }, [calculateApprovalPercentage]);

  const mainRowClasses = classNames("flowSelection__table-row", {
    step__denied: flow?.status === STATUS_CONST.STATUS_DENIED,
  });

  return (
    <div className={mainRowClasses} onClick={toggleCollapse}>
      <div className="flowSelection__left-wrapper">
        <div className="flowSelection__table-rowData">
          <span>{`${flow?.name} | `}</span>
          <span>{` ${flow?.workflow?.name}`}</span>
        </div>
        <div className="flowSelection__table-rowData flowSelection__icon">
          <img
            className="admin__table-rowDataImage"
            src={getProgramLogoForLeadType(flow?.programType)}
            alt={`${flow?.programType ?? ""}`}
          />
        </div>
        {hasMessageWithLengthGtZero && (
          <div className="flowSelection__table-rowData flowSelection__msg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M8.49784 4.6665V7.33317"
                stroke="#FF8A00"
                strokeLinecap="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.49784 9.99984C8.86603 9.99984 9.16451 9.70136 9.16451 9.33317C9.16451 8.96498 8.86603 8.6665 8.49784 8.6665C8.12965 8.6665 7.83118 8.96498 7.83118 9.33317C7.83118 9.70136 8.12965 9.99984 8.49784 9.99984Z"
                fill="#FF8A00"
              />
              <path
                d="M14.4978 2.6665V11.3332H9.16447L5.16447 13.9998V11.3332H2.4978V2.6665H14.4978Z"
                stroke="#FF8A00"
                strokeLinejoin="round"
              />
            </svg>
            <span id="flowSelection__newMsg">{USER_CONST.NEW_MESSAGES}</span>
          </div>
        )}
      </div>
      {flow?.status === STATUS_CONST.STATUS_DENIED ? (
        <div className="flowSelection__right-wrapper">
          <div className={getClassNameForStatus(flow?.status)}>
            {flow?.status}
          </div>
          <div>
            {collapsed ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6 10L12 16L18 10"
                  stroke="#17181C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 14L12 8L6 14"
                  stroke="#17181C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            )}
          </div>
        </div>
      ) : (
        <div className="flowSelection__right-wrapper">
          <div>
            <span>{calculateApprovalPercentage}%</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="170"
              height="8"
              viewBox="0 0 170 8"
              fill="none"
            >
              <rect width="170" height="8" rx="4" fill="#F1F2F4" />
              <rect
                width={calculatePercentajeProgressBar}
                height="8"
                rx="4"
                fill={
                  calculatePercentajeProgressBar < 100 ? "#2468DB" : "#00BD63"
                }
              />
            </svg>
          </div>
          <div>
            {collapsed ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6 10L12 16L18 10"
                  stroke="#17181C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 14L12 8L6 14"
                  stroke="#17181C"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const StepRow = React.memo(({ onClick, step }) => {
  const updatedStep = step?.status?.replace(
    STATUS_CONST.STATUS_PENDING,
    STATUS_CONST.STATUS_SENT_TO_RCX
  );
  return (
    <div
      key={step?.name}
      className={getClassNameForDetailRow(updatedStep)}
      onClick={onClick}
    >
      {updatedStep.includes(
        STATUS_CONST.STATUS_NEEDS_ATTENTION,
        STATUS_CONST.STATUS_PENDING,
        STATUS_CONST.STATUS_TODO
      ) && <div className="step__warning"></div>}
      <div className="flowSelection__leftDetail-wrapper">
        <div className="flowSelection__table-rowData">
          <span>{step?.name}</span>
        </div>
        {step?.message?.trim().length > 0 && (
          <div className="flowSelection__table-rowData flowSelection__msg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M8.49784 4.6665V7.33317"
                stroke="#FF8A00"
                strokeLinecap="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.49784 9.99984C8.86603 9.99984 9.16451 9.70136 9.16451 9.33317C9.16451 8.96498 8.86603 8.6665 8.49784 8.6665C8.12965 8.6665 7.83118 8.96498 7.83118 9.33317C7.83118 9.70136 8.12965 9.99984 8.49784 9.99984Z"
                fill="#FF8A00"
              />
              <path
                d="M14.4978 2.6665V11.3332H9.16447L5.16447 13.9998V11.3332H2.4978V2.6665H14.4978Z"
                stroke="#FF8A00"
                strokeLinejoin="round"
              />
            </svg>
            <span id="flowSelection__newMsg">{step?.message ?? ""}</span>
          </div>
        )}
      </div>
      <div className="flowSelection__right-wrapper">
        <div className={getClassNameForStatus(updatedStep)}>{updatedStep}</div>
      </div>
    </div>
  );
});

const ListSteps = ({ list, flowID }) => {
  const navigate = useNavigate();
  const listWithIndexes = list.map((item, index) => ({
    ...item,
    index: index,
  }));
  const orderedList = listWithIndexes.sort(
    (a, b) =>
      STATUS_CONST.statusRisks[a.status] - STATUS_CONST.statusRisks[b.status]
  );
  return orderedList?.map((item) => (
    <StepRow
      key={item?.index}
      step={item}
      onClick={() =>
        navigate(`/flow/${flowID}`, {
          state: { step: item?.index },
        })
      }
    />
  ));
};

const CollapsibleRow = ({ flow }) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleToggleCollapse = useCallback(
    (e) => {
      e.stopPropagation();
      setCollapsed(!collapsed);
    },
    [collapsed]
  );

  return (
    <>
      <MainRow
        collapsed={collapsed}
        flow={flow}
        toggleCollapse={handleToggleCollapse}
      />
      {!collapsed && <ListSteps list={flow?.steps} flowID={flow?.id} />}
    </>
  );
};

export default CollapsibleRow;
