import React from "react";
import PropTypes from "prop-types";
// Constants
import * as FORM_CONST from "../../../constants/formComponents";
// Styles
import "./Dropdown.css";
import classNames from "classnames";

const Dropdown = ({
  className = "",
  disabled = false,
  label,
  onChange,
  options,
  placeholder,
  required = false,
  style,
  value,
  wrapperClassName,
  ...additionalProps
}) => {
  const labelClasses = classNames("inputText__label", {
    "admin__disabled-color": disabled,
  });

  const dropdownClasses = classNames(
    "dropdown__box",
    {
      "admin__disabled-color admin__disabled-background user__disabled-background":
        disabled,
    },
    {
      "dropdown__box-default": !className,
    }
  );

  return (
    <div className={wrapperClassName}>
      {label && (
        <label className={labelClasses}>
          {label}
          {required ? "*" : null}
        </label>
      )}
      <select
        className={dropdownClasses}
        disabled={disabled}
        onChange={onChange}
        required={required}
        style={style}
        value={value ?? ""}
        {...additionalProps}
      >
        <option className="dropdown__defaultOption" value="" disabled>
          {placeholder ?? FORM_CONST.DROPDOWN_DEFAULT_VALUE}
        </option>
        {options?.map((dropdownItem, dropdownKey) => (
          <option disabled={disabled} key={dropdownKey} value={dropdownItem}>
            {dropdownItem}
          </option>
        ))}
      </select>
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default Dropdown;
