export const getBaseURL = () => {
  if (process.env.REACT_APP_RCX_API) {
    return process.env.REACT_APP_RCX_API;
  }
  if (process.env.REACT_APP_DEPLOYMENT === "development") {
    return "https://onboardingserver-dev.rcxmatrix.com";
  }
  if (process.env.REACT_APP_DEPLOYMENT === "staging") {
    return "https://onboardingserver-stage.rcxmatrix.com";
  }
  if (process.env.NODE_ENV === "production") {
    return "https://onboardingserver.rcxmatrix.com";
  }
};
