import React from "react";
import { useNavigate } from "react-router-dom";
// Images
import CheckCircle from "../../../../assets/image_check_circle.png";
// Constants
import * as STATUS_CONST from "../../../../constants/status";
// Utils
import { formatDateToStringMMMDDYYYY } from "../../../../utils/formatDate";
// Styles
import "./FlowVersionCard.css";

const FlowVersionCard = ({ card }) => {
  const navigate = useNavigate();

  return (
    <div
      className="admin__builder-cardWrapper"
      onClick={() => navigate(`/admin/builder/${card?._id}`)}
    >
      <div className="admin__builder-card">
        <div className="admin__builder-cardTop">
          <div className="admin__builder-cardName">
            <img
              className="admin__builder-cardNameImg"
              src={card?.logo_url ?? ""}
              alt={""}
            />
            <span className="admin__builder-cardNameTitle">
              {card?.title ?? ""}
            </span>
          </div>
          {card?.status === STATUS_CONST.STATUS_PUBLISHED && (
            <div className="admin__builder-cardStatusIcon">
              <img src={CheckCircle} alt="Check Circle Green" />
            </div>
          )}
        </div>
        <div className="admin__builder-cardBottom">
          <div className="admin__builder-cardItem">
            <span className="admin__builder-cardItemTitle">{`Added: `}</span>
            <span className={"admin__builder-cardItemValue"}>
              {formatDateToStringMMMDDYYYY(card?.createdAt)}
            </span>
            <div className="admin__builder-cardItem">
              <span className="admin__builder-cardItemTitle">
                {`Last edited: `}
              </span>
              <span className={"admin__builder-cardItemValue"}>
                {formatDateToStringMMMDDYYYY(card?.lastUpdate)}
              </span>
            </div>
            <div className="admin__builder-cardItem">
              <span className="admin__builder-cardItemTitle">
                {`Organizations: `}
              </span>
              <span className={"admin__builder-cardItemValue"}>
                {card?.organizations ?? ""}
              </span>
            </div>
            <div className="admin__builder-cardItem">
              <span className="admin__builder-cardItemTitle">
                {`Version: `}
              </span>
              <span className={"admin__builder-cardItemValue"}>
                {card?.version ?? ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowVersionCard;
