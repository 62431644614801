import React, { useState } from "react";
import "./FlowTypeVersions.css";
import FlowVersionCard from "../FlowVersionCard/FlowVersionCard";

const FlowTypeVersions = ({ flowType }) => {
  const [showAllFlows, setShowAllFlows] = useState(false);

  return (
    <div className="flowTypeVersions__container">
      <div className="flowTypeVersions__title">{flowType?.name}</div>
      <section className="flowTypeVersions__section">
        {flowType?.flowVersions
          ?.slice(0, showAllFlows ? undefined : 3)
          .map((flow) => (
            <FlowVersionCard key={flow?._id} card={flow} />
          ))}
      </section>
      {flowType?.flowVersions?.length > 3 && (
        <div className="flowTypeVersions__seeAll">
          <div
            className="flowTypeVersions__seeAll-btn"
            onClick={() => setShowAllFlows(!showAllFlows)}
          >
            {showAllFlows ? "Collapse All" : "See All"}
          </div>
        </div>
      )}
      <div className="flowTypeVersions__divider-container">
        <div className="flowTypeVersions__divider"></div>
      </div>
    </div>
  );
};

export default FlowTypeVersions;
