import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// Components
import Avatar from "../../Avatar/Avatar";
import Loader from "../../Loader/Loader";
// Reducers
import { actionTypes } from "../../../reducers/AdminBuilder/reducer";
// Context
import AsideContext from "../../../context/AsideMenu/AsideContext";
import AdminBuilderContext from "../../../context/AdminBuilder/AdminBuilderContext";
// Images
import Divider from "../../../assets/arrow_right_divider.png";
import Stats from "../../../assets/image_stats.png";
import TrianglePlay from "../../../assets/image_triangle.png";
// Constants
import * as BUILDER_CONST from "../../../constants/builderPage";
// Utils
import { useAuth } from "../../../utils/authenticate";
import { getFlowDefinition } from "../../../utils/getCurrentFlows";
// Styles
import "./AdminBuilderDetails.css";

const AdminBuilderDetail = ({ flowId }) => {
  const { user } = useAuth();

  const asideContext = useContext(AsideContext);
  const { adminBuildState, dispatchAdminBuilderAction } =
    useContext(AdminBuilderContext);

  const [isError, setIsError] = useState({ active: false, error: "" });

  useEffect(() => {
    const retrieveFlowDefinition = async (flowId) => {
      try {
        const flowDefinition = await getFlowDefinition(user?.token, flowId);
        dispatchAdminBuilderAction({
          type: actionTypes.SET_CURRENT_FLOW,
          payload: {
            flowDefinition: flowDefinition?.flow[0],
          },
        });
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to get flow definition for ${flowId} : ${err?.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    };
    retrieveFlowDefinition(flowId);
  }, [user?.token, flowId, dispatchAdminBuilderAction]);

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  return (
    <div
      className={`${
        asideContext.asideOpen ? "admin__content-expanded" : "admin__content"
      }`}
    >
      <div className="admin__topBar">
        <div className="admin__topBar-title">
          <Link className="admin__topBar-title" to="/admin/builder">
            {BUILDER_CONST.ONBOARDING_FLOWS}
          </Link>
          <>
            <img src={Divider} alt={"divider"} />
            <span className="admin__selected-onboardingFlow">
              {BUILDER_CONST.TITLE_BUILDER}
            </span>
          </>
        </div>
        <div className="admin__topBar-right">
          <Avatar typeUser={"admin"} />
        </div>
      </div>
      <div className="admin__title-row">
        <div>
          <div className="admin__titleText">{adminBuildState?.title}</div>
        </div>
      </div>
      {adminBuildState?.isLoading ? (
        <Loader />
      ) : (
        <main className="admin__onboardingF-wrapper">
          <div className="admin__onboardingF-content">
            <div className="admin__onboardingF-header">
              <span className="admin__onboardingF-headerT">
                {BUILDER_CONST.TITLE_ONBOARDING}
              </span>
              <div className="admin__onboardingF-playB">
                <Link
                  className="admin__onboardingF-headerPlay"
                  to={{
                    pathname: `/admin/builder/${flowId}/live`,
                    state: "TEST",
                  }}
                >
                  {BUILDER_CONST.LIVE_VIEW}
                </Link>
                <img src={TrianglePlay} alt={"play button"} />
              </div>
            </div>
            <div className="admin__onboardingF-Wcards">
              {adminBuildState?.stepperList?.length > 0 &&
                adminBuildState?.stepperList?.map((step) => (
                  <div key={step?._id} className="admin__onboardingF-card">
                    <div className="admin__onboardingF-cardImg">
                      <img src={Stats} alt={"Stats card"} />
                    </div>
                    <div className="admin__onboardingF-cardText">
                      <span className="admin__onboardingF-cardTitle">
                        {step?.stepName}
                      </span>
                      <span className="admin__onboardingF-cardDesc">
                        {step?.stepDescription}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default AdminBuilderDetail;
