import React from "react";
import PropTypes from "prop-types";
// Constants
import * as ADMIN_CONST from "../../../constants/adminPage";
// Utils
import { getClassNameForStatus } from "../../../utils/getClassNameForStatus";
// Styles
import "./StatusDropDown.css";

const StatusDropDown = (props) => {
  const { classes, options, onChange, value } = props;

  return (
    <div className="admin__StatusDropDownBox-wrapper">
      {value ? (
        <select
          name="status"
          className={`admin__StatusDropDownBox-status ${getClassNameForStatus(
            value
          )} ${classes}`}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          {options?.length > 0 &&
            options?.map((opt, index) => (
              <option key={index} value={opt?.value}>
                {opt?.name}
              </option>
            ))}
        </select>
      ) : (
        ADMIN_CONST.UNKNOWN
      )}
    </div>
  );
};

StatusDropDown.propTypes = {
  classes: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

StatusDropDown.defaultProps = {
  classes: "",
};

export default StatusDropDown;
