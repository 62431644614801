import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// Components
import { TopHeader } from "../TopHeader/TopHeader";
import { FlowLayout } from "../FlowLayout/FlowLayout";
import Loader from "../Loader/Loader";
// Constants
import * as STATUS_CONST from "../../constants/status";
// Utils
import { useAuth } from "../../utils/authenticate";
import { getFlowDefinition } from "../../utils/getCurrentFlows";
// Styles
import "./LiveView.css";

const LiveView = () => {
  const { user } = useAuth();
  const location = useLocation();
  const flowId = location?.pathname?.split("/builder/")[1].split("/live")[0];

  const [isError, setIsError] = useState({ active: false, error: "" });
  const [flowData, setFlowData] = useState(null);

  useEffect(() => {
    const retrieveFlowDefinition = async (flowId) => {
      try {
        const flowDefinition = await getFlowDefinition(user?.token, flowId);
        setFlowData({
          onboardingFlow: {
            ...flowDefinition?.flow[0],
          },
          status: STATUS_CONST.STATUS_NEW_OPPORTUNITY,
        });
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to get flow definition for ${flowId} : ${err?.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    };
    if (flowId?.length > 0) {
      retrieveFlowDefinition(flowId);
    }
  }, [user?.token, flowId]);

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  return (
    <>
      {flowData ? (
        <div>
          <article className="live__banner-wrapper">
            <span className="live__banner-text">
              {`You are in Live preview mode. `}
              <Link
                className="live__banner-text"
                to={`/admin/builder/${flowId}`}
              >
                Click here to return to edit
              </Link>
            </span>
          </article>
          <TopHeader
            onboardingUser={{
              name: "New User",
              role: "PENDING",
              leadType: flowData?.onboardingFlow?.leadType,
            }}
            showMyFlows={false}
          />
          <FlowLayout newOnboardingData={flowData} viewMode={true} step={0} />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default LiveView;
