import React from "react";
// Components
import TopHeaderLinks from "./TopHeaderLinks";
import TopHeaderUserProfile from "./TopHeaderUserProfile";
import TopHeaderProgramImage from "./TopHeaderProgramImage";
// Styles
import "./style.css";

export const TopHeader = ({ showMyFlows = true, onboardingUser }) => {
  return (
    <div className="topbar">
      <div className="topbar_contents">
        <TopHeaderProgramImage leadType={onboardingUser?.leadType} />
        <TopHeaderLinks
          leadType={onboardingUser?.leadType}
          showMyFlows={showMyFlows}
        />
        <TopHeaderUserProfile onboardingUser={onboardingUser} />
      </div>
    </div>
  );
};
