import _ from "lodash";
// Constants
import * as STATUS_CONST from "../../constants/status";
import {
  isCurrentDate,
  isBetweenLastWeek,
  isBetweenLastMonth,
  fromDate,
  toDate,
} from "../../utils/formatDate";

// Define the initial state of the reducer
export const initialState = {
  refreshPage: false,
  filters: {
    status: [],
    program: [],
    datePicker: { dateFrom: "", dateTo: "", value: null },
    count: 0,
  },
  inputSearchNav: "",
  listFlowsGroupedByOrganization: [],
  listFlowsGroupedByOrganizationBKP: [],
  msgList: [],
  msgTemp: "",
  onboardingIdDataLoading: true,
  onboardingIdData: [],
  onboardingIdDataBKP: [],
  organizationContact: null,
  organizationFlowsFilteredList: [],
  organizationFlowsSearchedList: [],
  organizationFlowsStats: {},
  rowSelected: {},
  sectionEditSelected: null,
  showModalChat: {
    active: false,
    value: [],
    stepperChat: null,
    sectionChat: null,
  },
  showModalExitWithoutSaving: {
    active: false,
    value: -1,
  },
  showModalFilters: {
    active: false,
    value: [],
  },
  showModalUpdateField: {
    active: false,
    value: null,
  },
  showModalUpdateStatusOrg: {
    active: false,
    value: null,
    indexSection: null,
  },
  showModalUpdateStep: {
    active: false,
    value: null,
  },
  showModalUpdateStatusSection: {
    active: false,
    value: null,
    indexSection: null,
  },
  tabSelected: { index: -1, stepName: null },
};

// Define the action types
export const actionTypes = {
  APPLY_FILTERS: "APPLY_FILTERS",
  CANCEL_EDIT_SECTION: "CANCEL_EDIT_SECTION",
  CANCEL_EXIT_WITHOUT_SAVING: "CANCEL_EXIT_WITHOUT_SAVING",
  CANCEL_UPD_FIELD: "CANCEL_UPD_FIELD",
  CANCEL_FILTERS: "CANCEL_FILTERS",
  CHAT_COMMET: "CHAT_COMMET",
  ERASE_FILTERS: "ERASE_FILTERS",
  FILTER_ROWS_BY_SEARCH_NAV: "FILTER_ROWS_BY_SEARCH_NAV",
  INITIALIZE: "INITIALIZE",
  SET_CURRENT_COMMENT: "SET_CURRENT_COMMENT",
  SET_CURRENT_MSG_LIST: "SET_CURRENT_MSG_LIST",
  SET_FILTERS: "SET_FILTERS",
  SET_REFRESH_PAGE: "SET_REFRESH_PAGE",
  SET_ROW_SELECTED: "SET_ROW_SELECTED",
  SET_INPUT_SEARCH_NAV: "SET_INPUT_SEARCH_NAV",
  SET_ONBOARDING_DATA: "SET_ONBOARDING_DATA",
  SET_ONBOARDING_ID_DATA_LOADING: "SET_ONBOARDING_ID_DATA_LOADING",
  SET_ORG_ONBOARDING_FLOWS_LIST: "SET_ORG_ONBOARDING_FLOWS_LIST",
  SET_SECTION_EDIT_SELECTED: "SET_SECTION_EDIT_SELECTED",
  SET_TAB_SELECTED: "SET_TAB_SELECTED",
  SHOW_MODAL_CHAT: "SHOW_MODAL_CHAT",
  SHOW_MODAL_FILTERS: "SHOW_MODAL_FILTERS",
  SHOW_MODAL_UPD_FIELD: "SHOW_MODAL_UPD_FIELD",
  SHOW_MODAL_UPD_SECTION: "SHOW_MODAL_UPD_SECTION",
  SHOW_MODAL_UPD_STATUS_SECTION: "SHOW_MODAL_UPD_STATUS_SECTION",
  SHOW_MODAL_UPD_STEP: "SHOW_MODAL_UPD_STEP",
  UPD_ONBOARDING_FLOW_FIELD: "UPD_ONBOARDING_FLOW_FIELD",
  UPD_ONBOARDING_FLOW_FIELD_VIEW: "UPD_ONBOARDING_FLOW_FIELD_VIEW",
  UPD_ONBOARDING_SECTION_STATUS: "UPD_ONBOARDING_SECTION_STATUS",
  UPD_ONBOARDING_STEP: "UPD_ONBOARDING_STEP",
  UPD_STATUS_ORG: "UPD_STATUS_ORG",
  VIEW_ORG_ACTIVITY: "VIEW_ORG_ACTIVITY",
};
// Define the reducer function
export function adminDashboardReducer(state, action) {
  const filteredOrganizations = (groupedData) => {
    if (!groupedData) return {};
    const filteredData = {};
    Object.keys(groupedData).forEach((email) => {
      const flows = groupedData[email];
      const filteredFlows = flows.filter((flow) => {
        const { name, email, location } = flow;
        const matchName = name
          .toLowerCase()
          .includes(state?.inputSearchNav?.toLowerCase());
        const matchEmail = email
          .toLowerCase()
          .includes(state?.inputSearchNav?.toLowerCase());
        const matchLocation = location
          .toLowerCase()
          .includes(state?.inputSearchNav?.toLowerCase());
        return matchName || matchEmail || matchLocation;
      });
      if (filteredFlows.length > 0) {
        filteredData[email] = filteredFlows;
      }
    });
    return filteredData;
  };
  const applyFilter = (groupedData) => {
    if (!groupedData) return {};
    const filteredData = {};
    Object.keys(groupedData).forEach((email) => {
      const flows = groupedData[email];
      const filteredFlows = flows.filter((flow) => {
        // Status
        const statusMatches =
          state?.filters?.status?.length === 0 ||
          state?.filters?.status?.includes(flow?.status);
        // Program
        const programMatches =
          state?.filters?.program?.length === 0 ||
          state?.filters?.program?.includes(flow?.leadType);
        // Date From
        const dateFromMatches =
          state?.filters?.datePicker?.dateFrom?.length === 0 ||
          fromDate(flow?.created, state?.filters?.datePicker?.dateFrom);
        // Date To
        const dateToMatches =
          state?.filters?.datePicker?.dateTo?.length === 0 ||
          toDate(flow?.created, state?.filters?.datePicker?.dateTo);
        // Date Today
        const dateToday =
          state?.filters?.datePicker?.value !== "Today" ||
          isCurrentDate(flow?.created);
        // Last 7 days
        const dateLastWeek =
          state?.filters?.datePicker?.value !== "Last 7 days" ||
          isBetweenLastWeek(flow?.created);
        // Last Month
        const dateLastMonth =
          state?.filters?.datePicker?.value !== "Last 30 days" ||
          isBetweenLastMonth(flow?.created);
        return (
          statusMatches &&
          programMatches &&
          dateFromMatches &&
          dateToMatches &&
          dateToday &&
          dateLastWeek &&
          dateLastMonth
        );
      });
      if (filteredFlows?.length > 0) {
        filteredData[email] = filteredFlows;
      }
    });
    return filteredData;
  };

  if (action.type === actionTypes.INITIALIZE) {
    return { ...initialState };
  }
  if (action.type === actionTypes.APPLY_FILTERS) {
    const countStatusFilter = state?.filters?.status?.length;
    const countProgramFilter = state?.filters?.program?.length;
    const countDateToFilter =
      state?.filters?.datePicker?.value?.length > 0 ? 1 : 0;
    const countFilters =
      countStatusFilter + countProgramFilter + countDateToFilter;

    const resultApplyFilter = applyFilter(
      state?.listFlowsGroupedByOrganizationBKP
    );

    return {
      ...state,
      filters: { ...state?.filters, count: countFilters },
      organizationFlowsFilteredList: resultApplyFilter,
      listFlowsGroupedByOrganization: resultApplyFilter,
      rowSelected: {},
      showModalFilters: {
        active: false,
        value: state?.filters,
      },
    };
  }
  if (action.type === actionTypes.CANCEL_EDIT_SECTION) {
    return {
      ...state,
      sectionEditSelected: null,
      onboardingIdData: _.cloneDeep(state?.onboardingIdDataBKP),
    };
  }
  if (action.type === actionTypes.CANCEL_EXIT_WITHOUT_SAVING) {
    return {
      ...state,
      showModalExitWithoutSaving: { active: false, value: -1 },
    };
  }
  if (action.type === actionTypes.CANCEL_UPD_FIELD) {
    return {
      ...state,
      showModalUpdateField: { active: false, value: null },
      sectionEditSelected: null,
      onboardingIdData: _.cloneDeep(state?.onboardingIdDataBKP),
    };
  }
  if (action.type === actionTypes.CANCEL_FILTERS) {
    return {
      ...state,
      filters: state?.showModalFilters.value,
      showModalFilters: {
        active: false,
        value: state?.showModalFilters.value,
      },
    };
  }
  if (action.type === actionTypes.CHAT_COMMET) {
    return {
      ...state,
      msgList: action?.payload?.newMsg,
      msgTemp: "",
    };
  }
  if (action.type === actionTypes.ERASE_FILTERS) {
    return {
      ...state,
      filters: {
        status: [],
        program: [],
        datePicker: { dateFrom: "", dateTo: "", value: null },
        count: 0,
      },
      organizationFlowsFilteredList: [],
      listFlowsGroupedByOrganization: [],
      showModalFilters: {
        active: false,
        value: state?.filters,
      },
    };
  }
  if (action.type === actionTypes.FILTER_ROWS_BY_SEARCH_NAV) {
    return {
      ...state,
      filterSearchNav: {
        active: state?.inputSearchNav?.length > 0,
        lastSearch: state?.inputSearchNav,
      },
      rowSelected: {},
      organizationFlowsSearchedList: filteredOrganizations(
        state?.listFlowsGroupedByOrganization
      ),
    };
  }
  if (action.type === actionTypes.SET_CURRENT_COMMENT) {
    return {
      ...state,
      msgTemp: action?.payload?.msgTemp,
    };
  }
  if (action.type === actionTypes.SET_CURRENT_MSG_LIST) {
    return {
      ...state,
      msgList: action?.payload?.msgList,
    };
  }
  if (action.type === actionTypes.SET_FILTERS) {
    const filtersAux = _.cloneDeep(state?.filters);
    if (
      action?.payload?.field === "status" ||
      action?.payload?.field === "program"
    ) {
      if (action?.payload?.checked) {
        filtersAux[action?.payload?.field] = [
          ...filtersAux[action?.payload?.field],
          action?.payload?.value,
        ];
      } else {
        filtersAux[action?.payload?.field] = filtersAux[
          action?.payload?.field
        ].filter((item) => item !== action?.payload?.value);
      }
    }
    if (action?.payload?.field === "datePicker") {
      filtersAux.datePicker.value = action?.payload?.value;
      filtersAux.datePicker.dateFrom = "";
      filtersAux.datePicker.dateTo = "";
    }
    if (
      action?.payload?.field === "dateTo" ||
      action?.payload?.field === "dateFrom"
    ) {
      filtersAux.datePicker[action?.payload?.field] = action?.payload?.value;
    }
    return {
      ...state,
      filters: filtersAux,
    };
  }
  if (action.type === actionTypes.SET_REFRESH_PAGE) {
    return {
      ...state,
      refreshPage: action?.payload?.refreshPage,
      filters: {
        status: [],
        program: [],
        datePicker: { dateFrom: "", dateTo: "", value: null },
        count: 0,
      },
      organizationFlowsFilteredList: [],
      rowSelected: {},
      showModalFilters: {
        active: false,
      },
    };
  }
  if (action.type === actionTypes.SET_ROW_SELECTED) {
    return { ...state, rowSelected: action?.payload?.row };
  }
  if (action.type === actionTypes.SET_INPUT_SEARCH_NAV) {
    return {
      ...state,
      inputSearchNav: action?.payload?.inputSearchNav,
    };
  }
  if (action.type === actionTypes.SET_ORG_ONBOARDING_FLOWS_LIST) {
    const data = action?.payload?.organizationFlowsList || [];
    const groupedData = data?.reduce((result, current) => {
      const email = current?.email;
      if (!result[email]) {
        result[email] = [];
      }
      result[email]?.push(current);
      return result;
    }, {});
    return {
      ...state,
      onboardingIdDataLoading: true,
      listFlowsGroupedByOrganization: groupedData,
      listFlowsGroupedByOrganizationBKP: groupedData,
      organizationFlowsStats: action?.payload?.organizationFlowsStats,
    };
  }
  if (action.type === actionTypes.SET_ONBOARDING_DATA) {
    return {
      ...state,
      onboardingIdData: action?.payload?.onboardingIdData,
    };
  }
  if (action.type === actionTypes.SET_ONBOARDING_ID_DATA_LOADING) {
    return {
      ...state,
      onboardingIdDataLoading: action?.payload?.onboardingIdDataLoading,
    };
  }
  if (action.type === actionTypes.SET_SECTION_EDIT_SELECTED) {
    return {
      ...state,
      sectionEditSelected: action?.payload?.sectionEditSelected,
    };
  }
  if (action.type === actionTypes.SET_TAB_SELECTED) {
    let showModalExitWithoutSavingActive = false;
    let showModalExitWithoutSavingValue = -1;
    const clonedOnboardingIdData = _.cloneDeep(state?.onboardingIdData);
    const clonedOnboardingIdDataBKP = _.cloneDeep(state?.onboardingIdDataBKP);
    if (
      state?.onboardingIdData?.status !== STATUS_CONST.STATUS_NEW_OPPORTUNITY
    ) {
      // Compare both updated objects
      const isEqual = _.isEqual(
        clonedOnboardingIdData,
        clonedOnboardingIdDataBKP
      );
      if (!action?.payload?.viewMode && !isEqual) {
        showModalExitWithoutSavingActive = true;
        showModalExitWithoutSavingValue = action?.payload?.index;
      }
    }
    return {
      ...state,
      tabSelected: {
        index: showModalExitWithoutSavingActive
          ? state?.tabSelected?.index
          : action?.payload?.index,
        stepName: showModalExitWithoutSavingActive
          ? state?.tabSelected?.stepName
          : action?.payload?.stepName,
      },
      showModalExitWithoutSaving: {
        active: showModalExitWithoutSavingActive,
        value: showModalExitWithoutSavingValue,
      },
      showModalChat: {
        active: false,
        value: [],
        stepperChat: null,
        sectionChat: null,
      },
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_CHAT) {
    const valueActive =
      action?.payload?.active === false
        ? false
        : action?.payload?.stepperChat === state?.showModalChat?.stepperChat &&
          action?.payload?.sectionChat === state?.showModalChat?.sectionChat
          ? !state?.showModalChat.active
          : true;

    const msgListAux = _.cloneDeep(state?.msgList);
    if (valueActive === true && action?.payload?.email) {
      msgListAux?.stepSections[action?.payload?.sectionChat]?.messages?.map(
        (msg) => msg?.readBy?.push(action?.payload?.email)
      );
    }

    return {
      ...state,
      showModalChat: {
        active: valueActive,
        stepperChat: action?.payload?.stepperChat,
        sectionChat: action?.payload?.sectionChat,
      },
      msgList: msgListAux,
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_FILTERS) {
    return {
      ...state,
      showModalFilters: {
        active: action?.payload?.active,
        value: state?.filters,
      },
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_UPD_FIELD) {
    return {
      ...state,
      showModalUpdateField: {
        active: action?.payload?.active,
        indexSection: action?.payload?.indexSection,
        value: action?.payload?.value,
      },
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_UPD_SECTION) {
    return {
      ...state,
      showModalUpdateStatusOrg: {
        active: action?.payload?.active,
        value: action?.payload?.value,
      },
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_UPD_STATUS_SECTION) {
    return {
      ...state,
      showModalUpdateStatusSection: {
        active: action?.payload?.active,
        indexSection: action?.payload?.indexSection,
        value: action?.payload?.value,
      },
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_UPD_STEP) {
    return {
      ...state,
      showModalUpdateStep: {
        active: action?.payload?.active,
        value: action?.payload?.value,
      },
    };
  }
  if (action.type === actionTypes.UPD_ONBOARDING_FLOW_FIELD) {
    const onboardingIdDataAux = _.cloneDeep(state?.onboardingIdData);
    onboardingIdDataAux.onboardingFlow.stepper[
      state?.tabSelected?.index
    ].stepSections[state?.sectionEditSelected].lastUpdatedBy =
      action?.payload?.email;
    const currentDate = new Date();
    const formattedDate = `${currentDate.toISOString().slice(0, -1)}000Z`;
    onboardingIdDataAux.onboardingFlow.lastUpdate = formattedDate;
    // Update Location for FlowsList.
    let listFlowsGroupedByOrganizationBKPAux = _.cloneDeep(
      state?.listFlowsGroupedByOrganizationBKP
    );
    // Email
    const email = state?.rowSelected?.username;
    if (state?.rowSelected?.row > -1) {
      if (
        onboardingIdDataAux?.onboardingFlow?.stepper[0]?.stepSections[2]?.sectionItems[2]?.value?.trim()
          ?.length > 0
      ) {
        // Update location for each flow that belongs to organization (email) selected.
        listFlowsGroupedByOrganizationBKPAux[email] =
          listFlowsGroupedByOrganizationBKPAux[email]?.map((flow) => {
            if (flow?.email === email) {
              return {
                ...flow,
                location:
                  onboardingIdDataAux?.onboardingFlow?.stepper[0]
                    ?.stepSections[2]?.sectionItems[2]?.value,
              };
            } else {
              return flow;
            }
          });
      }
    }

    const resultApplyFilter = applyFilter(listFlowsGroupedByOrganizationBKPAux);

    return {
      ...state,
      onboardingIdData: onboardingIdDataAux,
      onboardingIdDataBKP: onboardingIdDataAux,
      organizationFlowsFilteredList: resultApplyFilter,
      listFlowsGroupedByOrganization: resultApplyFilter,
      listFlowsGroupedByOrganizationBKP: listFlowsGroupedByOrganizationBKPAux,
      organizationFlowsSearchedList: filteredOrganizations(resultApplyFilter),
    };
  }
  if (action.type === actionTypes.UPD_ONBOARDING_FLOW_FIELD_VIEW) {
    const { keySection, keyItem, new_value } = action?.payload;
    const onboardingIdDataAux = _.cloneDeep(state?.onboardingIdData);
    onboardingIdDataAux.onboardingFlow.stepper[
      state?.tabSelected?.index
    ].stepSections[keySection].sectionItems[keyItem].value = new_value;
    return {
      ...state,
      onboardingIdData: onboardingIdDataAux,
    };
  }
  if (action.type === actionTypes.UPD_ONBOARDING_SECTION_STATUS) {
    const onboardingIdDataAux = _.cloneDeep(state?.onboardingIdData);
    // Stepper Status
    onboardingIdDataAux.onboardingFlow.stepper[
      state?.tabSelected?.index
    ].status = action?.payload?.stepperStatus;
    // Section Status
    onboardingIdDataAux.onboardingFlow.stepper[
      state?.tabSelected?.index
    ].stepSections[state?.showModalUpdateStatusSection.indexSection].status =
      state?.showModalUpdateStatusSection.value;
    // Organization Status & FLow List
    const listFlowsGroupedByOrganizationAux = _.cloneDeep(
      state?.listFlowsGroupedByOrganization
    );
    const listFlowsGroupedByOrganizationBKPAux = _.cloneDeep(
      state?.listFlowsGroupedByOrganizationBKP
    );
    // Email
    const email = state?.rowSelected?.username;
    // Get ID for Grouped List
    const idToUpdateFlowsList = listFlowsGroupedByOrganizationBKPAux[
      email
    ].findIndex((org) => org.id === state?.rowSelected?.onboardingFlowId);
    if (
      state?.rowSelected?.row > -1 &&
      listFlowsGroupedByOrganizationAux[email][state?.rowSelected?.row]
        .status === STATUS_CONST.STATUS_APPROVED &&
      state?.showModalUpdateStatusSection.value !== STATUS_CONST.STATUS_APPROVED
    ) {
      onboardingIdDataAux.status = STATUS_CONST.STATUS_PENDING;
      if (idToUpdateFlowsList > -1) {
        // Update Grouped Lists
        listFlowsGroupedByOrganizationBKPAux[email][
          idToUpdateFlowsList
        ].status = STATUS_CONST.STATUS_PENDING;
        listFlowsGroupedByOrganizationAux[email][idToUpdateFlowsList].status =
          STATUS_CONST.STATUS_PENDING;
      }
    }

    const resultApplyFilter = applyFilter(listFlowsGroupedByOrganizationBKPAux);

    return {
      ...state,
      onboardingIdData: onboardingIdDataAux,
      onboardingIdDataBKP: onboardingIdDataAux,
      organizationFlowsFilteredList: resultApplyFilter,
      listFlowsGroupedByOrganization: resultApplyFilter,
      listFlowsGroupedByOrganizationBKP: listFlowsGroupedByOrganizationBKPAux,
      organizationFlowsSearchedList: filteredOrganizations(resultApplyFilter),
      showModalUpdateStatusSection: {
        active: false,
        value: null,
        indexSection: null,
      },
      showModalUpdateStatusOrg:
        action?.payload?.flowStatus === STATUS_CONST.STATUS_TO_APPROVED
          ? { active: true, value: STATUS_CONST.STATUS_APPROVED }
          : { active: false, value: null },
    };
  }
  if (action.type === actionTypes.UPD_ONBOARDING_STEP) {
    const listFlowsGroupedByOrganizationAux = _.cloneDeep(
      state?.listFlowsGroupedByOrganization
    );
    const listFlowsGroupedByOrganizationBKPAux = _.cloneDeep(
      state?.listFlowsGroupedByOrganizationBKP
    );
    const onboardingIdDataAux = _.cloneDeep(state?.onboardingIdData);
    onboardingIdDataAux.onboardingFlow.stepper[
      state?.tabSelected?.index
    ].status = STATUS_CONST.STATUS_APPROVED;
    onboardingIdDataAux.onboardingFlow.stepper[
      state?.tabSelected?.index
    ].stepSections.map(
      (section) => (section.status = STATUS_CONST.STATUS_APPROVED)
    );
    const email = state?.rowSelected?.username;
    if (
      state?.rowSelected?.row > -1 &&
      action?.payload?.flowStatus === STATUS_CONST.STATUS_APPROVED
    ) {
      // Get ID for Grouped List
      const idToUpdateFlowsListBKP = listFlowsGroupedByOrganizationAux[
        email
      ].findIndex((org) => org.id === state?.rowSelected?.onboardingFlowId);
      if (idToUpdateFlowsListBKP > -1) {
        // Update Grouped Lists
        listFlowsGroupedByOrganizationAux[email][
          idToUpdateFlowsListBKP
        ].status = STATUS_CONST.STATUS_APPROVED;
        listFlowsGroupedByOrganizationBKPAux[email][
          idToUpdateFlowsListBKP
        ].status = STATUS_CONST.STATUS_APPROVED;
      }
    }

    const resultApplyFilter = applyFilter(listFlowsGroupedByOrganizationBKPAux);

    return {
      ...state,
      onboardingIdData: onboardingIdDataAux,
      onboardingIdDataBKP: onboardingIdDataAux,
      organizationFlowsFilteredList: resultApplyFilter,
      listFlowsGroupedByOrganization: resultApplyFilter,
      listFlowsGroupedByOrganizationBKP: listFlowsGroupedByOrganizationBKPAux,
      organizationFlowsSearchedList: filteredOrganizations(resultApplyFilter),
      showModalUpdateStep: { active: false, value: null },
      showModalUpdateStatusOrg:
        action?.payload?.flowStatus === STATUS_CONST.STATUS_TO_APPROVED
          ? { active: true, value: STATUS_CONST.STATUS_APPROVED }
          : { active: false, value: null },
    };
  }
  if (action.type === actionTypes.UPD_STATUS_ORG) {
    const onboardingIdDataAux = _.cloneDeep(state?.onboardingIdData);
    const listFlowsGroupedByOrganizationAux = _.cloneDeep(
      state?.listFlowsGroupedByOrganization
    );
    const listFlowsGroupedByOrganizationBKPAux = _.cloneDeep(
      state?.listFlowsGroupedByOrganizationBKP
    );
    // Email
    const email = state?.rowSelected?.username;
    // Organization Status
    if (state?.rowSelected?.row > -1) {
      listFlowsGroupedByOrganizationAux[email][state?.rowSelected?.row].status =
        action?.payload?.status;
      // Get ID for Grouped List
      const idToUpdateFlowsListBKP = listFlowsGroupedByOrganizationBKPAux[
        email
      ].findIndex((org) => org.id === state?.rowSelected?.onboardingFlowId);
      if (idToUpdateFlowsListBKP > -1) {
        // Update Grouped Lists
        listFlowsGroupedByOrganizationBKPAux[email][
          idToUpdateFlowsListBKP
        ].status = action?.payload?.status;
      }
    }
    onboardingIdDataAux.status = action?.payload?.status;
    if (state?.tabSelected?.index > -1) {
      // Stepper Status
      onboardingIdDataAux.onboardingFlow.stepper.map(
        (step) => (step.status = state?.showModalUpdateStatusOrg?.value)
      );
      // Section Status
      onboardingIdDataAux.onboardingFlow.stepper.map((step) =>
        step.stepSections.map(
          (section) =>
          (section.status =
            action?.payload?.status === STATUS_CONST.STATUS_DENIED
              ? STATUS_CONST.STATUS_NEEDS_ATTENTION
              : action?.payload?.status)
        )
      );
    }

    const resultApplyFilter = applyFilter(listFlowsGroupedByOrganizationBKPAux);

    return {
      ...state,
      onboardingIdData: onboardingIdDataAux,
      onboardingIdDataBKP: onboardingIdDataAux,
      organizationFlowsFilteredList: resultApplyFilter,
      listFlowsGroupedByOrganization: resultApplyFilter,
      listFlowsGroupedByOrganizationBKP: listFlowsGroupedByOrganizationBKPAux,
      organizationFlowsSearchedList: filteredOrganizations(resultApplyFilter),
      showModalUpdateStatusOrg: { active: false, value: null },
    };
  }
  if (action.type === actionTypes.VIEW_ORG_ACTIVITY) {
    return {
      ...state,
      onboardingIdData: action?.payload?.onboardingData,
      onboardingIdDataBKP: _.cloneDeep(action?.payload?.onboardingData),
      organizationContact: {
        orgName: action?.payload?.orgName,
        orgEmail: action?.payload?.orgEmail,
      },
      sectionEditSelected: null,
      tabSelected: {
        index: 0,
        stepName:
          action?.payload?.onboardingData?.onboardingFlow?.stepper?.length > 0
            ? action?.payload?.onboardingData?.onboardingFlow?.stepper[0]
              .stepName
            : "",
      },
      onboardingIdDataLoading: false,
      showModalChat: {
        active: false,
        value: [],
        sectionChat: null,
      },
    };
  }
}
