import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
// Components
import { TopHeader } from "../../TopHeader/TopHeader";
import CollapsibleRow from "../CollapsibleRow/CollapsibleRow";
import Loader from "../../Loader/Loader";
import Modal from "../../Modal/Modal";
import ToggleSwitch from "../../ToggleSwitch/ToggleSwitch";
// Constants
import * as STATUS_CONST from "../../../constants/status";
import * as USER_CONST from "../../../constants/userPage";
// Images
import ImgFilter from "../../../assets/image_filter.png";
// Utils
import { useAuth } from "../../../utils/authenticate";
// Styles
import "./UserFlowsSelection.css";
import { getClassNameForStatus } from "../../../utils/getClassNameForStatus";

const UserFlowsSelection = () => {
  const { user, getFlowListForAnUser } = useAuth();
  const { userID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState({ active: false, error: "" });
  const [showModalStatusInformation, setShowModalStatusInformation] =
    useState(false);
  const [showApprovedFlowsState, setShowApprovedFlowsState] = useState(true);

  const handleToggleChange = () => {
    setShowApprovedFlowsState((prevState) => !prevState);
  };

  useEffect(() => {
    if (user && user?.token) {
      if (!user?.admin && userID === user?.userId) {
        getFlowListForAnUser();
        setIsLoading(false);
      } else {
        setIsError({
          active: true,
          status: 404,
          statusText: "Current user don't have permissions",
        });
      }
    } else {
      setIsError({
        active: true,
        error: "Page Not Found. First Login to App.",
        status: 404,
        statusText: "Page Not Found",
      });
    }
  }, [user?.userId, userID]);

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const userHasFlows = user?.flows?.length > 1;

  return (
    <>
      {userHasFlows && (
        <div>
          <TopHeader
            showMyFlows={false}
            onboardingUser={{
              name: `${user?.firstName} ${user?.lastName}`,
            }}
          />
          <main className="flowsSelection__wrapper">
            <div className="flowsSelection__header">
              <div className="flowsSelection__header-left">
                <span className="flowsSelection__title">
                  {USER_CONST.FLOWS_SELECTION_TITLE}
                </span>
                {/* FGL */}
                {/* <Button
                  classes="secondary"
                  className="button__dashboard-filters"
                  iconPlace="left"
                  image={{
                    className: "button__filter-flex",
                    src: ImgFilter,
                    alt: "Filter Icon",
                  }}
                  onClick={() => {}}
                  title={`${BUTTON_CONST.BUTTON_FILTERS}`}
                /> */}
                <div className="flowsSelection__header-toggle">
                  <span>{USER_CONST.TOGGLE_APPROVED_FLOWS}</span>
                  <ToggleSwitch
                    isChecked={showApprovedFlowsState}
                    onToggleChange={handleToggleChange}
                  />
                </div>
              </div>
              <div className="flowsSelection__header-right">
                <span>{USER_CONST.MY_FLOWS_STATUS_INFO}</span>
                <div>
                  <svg
                    onClick={() =>
                      setShowModalStatusInformation(!showModalStatusInformation)
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9.87891 7.51884C11.0505 6.49372 12.95 6.49372 14.1215 7.51884C15.2931 8.54397 15.2931 10.206 14.1215 11.2312C13.9176 11.4096 13.6917 11.5569 13.4513 11.6733C12.7056 12.0341 12.0002 12.6716 12.0002 13.5V14.25M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 17.25H12.0075V17.2575H12V17.25Z"
                      stroke="#73788C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {user?.allFlows?.Onboarding?.filter((flow) => {
              return (
                showApprovedFlowsState ||
                flow?.status !== STATUS_CONST.STATUS_APPROVED
              );
            }).map((flow) => {
              return <CollapsibleRow key={flow?.id} flow={flow} />;
            })}
          </main>
        </div>
      )}
      {showModalStatusInformation && (
        <div className="modal__backdrop">
          <Modal classes={"modal__statusInfo-wrapper"} width={502}>
            <div className="modal__xMark">
              <svg
                onClick={() =>
                  setShowModalStatusInformation(!showModalStatusInformation)
                }
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z"
                  fill="#0F172A"
                />
              </svg>
            </div>
            <div className="modal__header-main">
              {USER_CONST.MY_FLOWS_STATUS_INFO_TITLE}
            </div>
            <div className="modal__statusInfo-text">
              {STATUS_CONST.statusInformationText.map(
                ({ index, status, description }) => {
                  return (
                    <div className="modal__statusInfo-textItem" key={index}>
                      <span className={getClassNameForStatus(status)}>
                        {status}
                      </span>
                      <div>
                        <span>{description}</span>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default UserFlowsSelection;
