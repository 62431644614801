import React from "react";
// Components
import Loader from "../../Loader/Loader";
// Utils
import { getProgramLogoForLeadType } from "../../../utils/getLogoImage";
import { getClassNameForStatus } from "../../../utils/getClassNameForStatus";
import { formatDateToStringMMMDDYYYYHHMM } from "../../../utils/formatDate";
// Constants
import * as ADMIN_CONST from "../../../constants/adminPage";
// Styles
import "./TableContent.css";

const TableContent = ({ isLoading, objectList, onClick }) => {
  const handleOnClick = (index, email) => {
    onClick(index, email);
  };

  return (
    <div className="admin__wrapper-table">
      <table className="admin__table">
        <thead className="admin__table-header">
          <tr className="admin__table-headerC">
            {ADMIN_CONST.HEADER_COLUMNS.map((column) => (
              <th
                key={column?.id}
                scope="col"
                style={{ width: column?.width }}
                className={`admin__table-columnTitle`}
              >
                {column?.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          className={`admin__table-body ${isLoading ? "min-height-50vh" : ""}`}
        >
          {isLoading ? (
            <tr style={{ alignSelf: "center" }}>
              <td>
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {Object.keys(objectList).map((email) => {
                const rows = objectList[email].map((user, index) => (
                  <td
                    key={user?.id}
                    className="admin__table-row"
                    onClick={() => handleOnClick(index, email)}
                  >
                    <div
                      className="admin__table-rowData"
                      style={{ width: ADMIN_CONST.HEADER_COLUMNS[0]?.width }}
                    >
                      <div className="admin__table-firstColumnData">
                        <span>{user?.workflow}</span>
                      </div>
                    </div>
                    <div
                      className="admin__table-rowData"
                      style={{ width: ADMIN_CONST.HEADER_COLUMNS[1]?.width }}
                    >
                      <img
                        className="admin__table-rowDataImage"
                        src={getProgramLogoForLeadType(user?.leadType)}
                        alt={`${user?.leadType}`}
                      />
                    </div>
                    <div
                      className="admin__table-rowData"
                      style={{ width: ADMIN_CONST.HEADER_COLUMNS[2]?.width }}
                    >
                      <span className={getClassNameForStatus(user?.status)}>
                        {user?.status?.toLocaleUpperCase()}
                      </span>
                    </div>
                    <div
                      className="admin__table-rowData"
                      style={{ width: ADMIN_CONST.HEADER_COLUMNS[3]?.width }}
                    >
                      {user?.location}
                    </div>
                    <div
                      className="admin__table-rowData "
                      style={{ width: ADMIN_CONST.HEADER_COLUMNS[4]?.width }}
                    >
                      <span>{user?.version}</span>
                    </div>
                    <div
                      className="admin__table-rowData textAlign__center"
                      style={{ width: ADMIN_CONST.HEADER_COLUMNS[5]?.width }}
                    >
                      <span>{user?.flowType}</span>
                    </div>
                    <div
                      className="admin__table-rowData text__align-center"
                      style={{ width: ADMIN_CONST.HEADER_COLUMNS[6]?.width }}
                    >
                      {formatDateToStringMMMDDYYYYHHMM(user?.created)}
                    </div>
                  </td>
                ));
                return (
                  <tr key={email} className="tableContent__row-wrapper">
                    <td className="tableContent__table-mainRow">
                      <div className="admin__table-emailRow">
                        <div className="admin__table-firstColumnData">
                          <div>
                            {objectList[email][0]?.name ||
                              ADMIN_CONST.UNNAMED_ORGANIZATION}
                          </div>
                          <div className="admin__violetText ellipsis-text">
                            {email}
                          </div>
                        </div>
                      </div>
                    </td>
                    {rows}
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableContent;
