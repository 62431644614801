export const BUTTON_ADD_NEW_ROW = "Add Row";
export const BUTTON_APPLY = "Apply";
export const BUTTON_BACK = "Back";
export const BUTTON_CANCEL = "Cancel";
export const BUTTON_CHANGE_PASSWORD = "Change Password";
export const BUTTON_COMMENT = "Comment";
export const BUTTON_CONTINUE = "Continue";
export const BUTTON_CONTINUE_TO_LOG_IN = "Continue To Log In";
export const BUTTON_DONT_SAVE = "Don't Save";
export const BUTTON_ERASE_FILTER = "Erase Filters";
export const BUTTON_FILTERS = "Filters";
export const BUTTON_GO_HOMEPAGE = "Go To Homepage";
export const BUTTON_GO_MY_FLOWS = "Go To My Flows";
export const BUTTON_GOT_IT = "Got It";
export const BUTTON_LOG_IN = "Log In";
export const BUTTON_NEXT = "Next";
export const BUTTON_OK = "Ok";
export const BUTTON_RESET_PASSWORD = "Reset Password";
export const BUTTON_SAVE = "Save";
export const BUTTON_SAVE_CONTINUE = "Save and Continue";
export const BUTTON_SEARCH = "Go!";
export const BUTTON_SEND = "Send";
export const BUTTON_SEND_WORKFLOW = "Send Workflow";
export const BUTTON_SKIP = "Skip For Now";
export const BUTTON_SUBMIT = "Submit";
export const BUTTON_UPDATE = " Yes, Update";
