export const LINK_SETTINGS = "Link settings";
export const SETTINGS = "Settings";
export const ONBOARDING_FLOWS = "Flows";
export const TITLE_BUILDER = "builder";
export const TITLE_ONBOARDING = "Onboarding";
export const LIVE_VIEW = "Live view";
export const TYPE_VIEW_LIST = [
    { id: 1, name: "Workflows" },
    { id: 2, name: "Flowtypes" },
];
