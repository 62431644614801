import React from "react";
import PropTypes from "prop-types";
// Styles
import "./Add.css";
import classNames from "classnames";

const Add = ({ classes, color, disabled, height, onClick, width }) => {
  const wrapperClasses = classNames(classes, {
    "add__cursor-pointer": !disabled,
  });

  return (
    <div className={wrapperClasses} onClick={!disabled ? onClick : undefined}>
      <svg
        width={width}
        height={height}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 1V15M15 8L1 8"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

Add.propTypes = {
  classes: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string,
};

Add.defaultProps = {
  color: "#8F93A3",
  disabled: false,
  height: "16",
  width: "16",
};

export default Add;
