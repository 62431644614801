export const emailValidationPattern =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)*$/; // eslint-disable-line max-len
export const passwordValidationPattern =
  /^(?=.*[!@#$%^&*()_+\-[\]{};':"\\|,.<>/?])[a-zA-Z0-9!@#$%^&*()_+\-[\]{};':"\\|,.<>/?]{9,16}$/; // eslint-disable-line max-len
export const specialCharacter = /[!@#$%^&*()_+=\-[\]{};':"\\|,.<>/?]/; // eslint-disable-line max-len

export const isTextValid = (value) => {
  // trim value is type is string
  if (value && typeof value === "string" && value.trim().length) return true;
  return false;
};

export const isEmailValid = (email) => {
  return email && RegExp(emailValidationPattern).test(email.trim())
    ? true
    : false;
};

export const isPasswordValid = (password) => {
  const re = RegExp(passwordValidationPattern);
  if (password && password.length && re.test(password)) return true;
  return false;
};

export const containSpecialCharacter = (password) => {
  return password && RegExp(specialCharacter).test(password.trim())
    ? true
    : false;
};
