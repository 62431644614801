import { useEffect, useState, useCallback } from "react";
// Utils
import { useAuth } from "../utils/authenticate";

export const useGetDataFromFlowID = (flowID) => {
  const { user, getFlowIdFromUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [isError, setIsError] = useState({ active: false, error: "" });
  const [isLoading, setIsLoading] = useState(true);

  const getOnboardingData = useCallback(async () => {
    try {
      const data = await getFlowIdFromUser(flowID);
      setUserData(data?.onboardingUser);
    } catch (err) {
      setIsError({
        active: true,
        error: `Failed to fetch onboarding: ${err.cause ?? err}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    } finally {
      setIsLoading(false);
    }
  }, [flowID]);

  useEffect(() => {
    if (user?.token) {
      if (!user?.admin) {
        getOnboardingData();
      } else {
        setIsError({
          active: true,
          status: 404,
          statusText: "Current user don't have permissions",
        });
      }
    } else {
      setIsError({
        active: true,
        error: "Page Not Found. First Login to App.",
        status: 404,
        statusText: "Page Not Found",
      });
    }
  }, [flowID, user?.admin, user?.token]);

  return { userData, isError, isLoading };
};
