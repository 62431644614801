import React from "react";
import PropTypes from "prop-types";
import "./Edit.css";

const Edit = (props) => {
  const { classes, color, height, onClick, width } = props;

  return (
    <div className={classes} onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5858 4.41421C16.3668 3.63317 17.6332 3.63317 18.4142 4.41421L19.5858 5.58579C20.3668 6.36684 20.3668 7.63316 19.5858 8.41421L8.58579 19.4142C8.21071 19.7893 7.70201 20 7.17157 20L4 20L4 16.8284C4 16.298 4.21071 15.7893 4.58579 15.4142L15.5858 4.41421Z"
          stroke={color}
          strokeWidth="1.5"
        />
        <path d="M14 6L18 10" stroke={color} strokeWidth="1.5" />
      </svg>
    </div>
  );
};

Edit.propTypes = {
  classes: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  onClick: PropTypes.func,
  width: PropTypes.number,
};

Edit.defaultProps = {
  color: "#2468DB",
  height: 24,
  width: 24,
};

export default Edit;
