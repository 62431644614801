import { useState } from "react";

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window !== "undefined" && window.localStorage) {
      try {
        const value = window.localStorage.getItem(keyName);
        if (value) {
          return JSON.parse(value);
        } else {
          window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
          return defaultValue;
        }
      } catch (err) {
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    if (typeof window !== "undefined" && window.localStorage) {
      try {
        window.localStorage.setItem(keyName, JSON.stringify(newValue));
      } catch (err) {
        console.error("Error storing value in LocalStorage:", err);
      }
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
