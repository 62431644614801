import React, { useContext, useEffect, useReducer, useState } from "react";
// Components
import Avatar from "../../../Avatar/Avatar";
import AdminNavTab from "../../../Icons/AdminNavTab/AdminNavTab";
import Button from "../../../Button/Button";
import Loader from "../../../Loader/Loader";
import Modal from "../../../Modal/Modal";
import UploadFile from "../../../FormComponents/UploadFile/UploadFile";
// Context
import AsideContext from "../../../../context/AsideMenu/AsideContext";
// Reducers
import {
  initialState,
  adminLinksReducer,
  actionTypes,
} from "../../../../reducers/SettingsProgramsPage/reducer";
// Images
import Divider from "../../../../assets/arrow_right_divider.png";
import EditPencil from "../../../../assets/edit_pencil.png";
import ImgQuestion from "../../../../assets/image_question3.png";
// Utils
import { useAuth } from "../../../../utils/authenticate";
import { getCurrentFlows } from "../../../../utils/getCurrentFlows";
import {
  getProgramConfigurationFromAdmin,
  updateProgramConfigurationFromAdmin,
} from "../../../../utils/settingsLinks";
import { formatDateDifference } from "../../../../utils/formatDate";
// Constants
import * as ADMIN_CONST from "../../../../constants/adminPage";
import * as BUILDER_CONST from "../../../../constants/builderPage";
import * as BUTTON_CONST from "../../../../constants/buttons";
// Styles
import "./SettingsPrograms.css";

const SettingsPrograms = () => {
  const { user } = useAuth();

  const asideContext = useContext(AsideContext);
  const [isError, setIsError] = useState({ active: false, error: "" });

  const [adminSetState, dispatchLinksAction] = useReducer(
    adminLinksReducer,
    initialState
  );

  const changeTabSelected = async (index, stepName) => {
    dispatchLinksAction({
      type: actionTypes.SET_TAB_SELECTED,
      payload: {
        index,
        stepName,
      },
    });
    const programConfig = await getProgramConfigurationFromAdmin(
      user?.token,
      adminSetState?.tabNames[index]
    );
    dispatchLinksAction({
      type: actionTypes.SET_CURRENT_CONFIGURATION_FLOW,
      payload: {
        programConfig,
      },
    });
  };

  const handleUpdateFieldsItems = async () => {
    await updateProgramConfigurationFromAdmin(
      user?.token,
      adminSetState?.currentFlow?.leadType,
      adminSetState?.sectionEditSelected === 0
        ? {
            top_bar: adminSetState?.currentFlow?.top_bar,
          }
        : {
            dashboard: adminSetState?.currentFlow?.dashboard,
          }
    );
    dispatchLinksAction({
      type: actionTypes.SHOW_MODAL_UPD_FIELD,
      payload: { active: false, value: null },
    });
    const programConfig = await getProgramConfigurationFromAdmin(
      user?.token,
      adminSetState?.currentFlow?.leadType
    );
    dispatchLinksAction({
      type: actionTypes.SET_CURRENT_CONFIGURATION_FLOW,
      payload: {
        programConfig: programConfig,
      },
    });
    dispatchLinksAction({
      type: actionTypes.SET_SECTION_EDIT_SELECTED,
      payload: { sectionEditSelected: null },
    });
  };

  useEffect(() => {
    const retrieveCurrentFlows = async () => {
      try {
        const currentFlowsList = await getCurrentFlows(user?.token);
        dispatchLinksAction({
          type: actionTypes.SET_PROGRAMS_FLOWS,
          payload: {
            currentFlows: currentFlowsList?.programs?.map(
              (program) => program.name
            ),
          },
        });
        const programConfig = await getProgramConfigurationFromAdmin(
          user?.token,
          currentFlowsList?.programs?.map((program) => program.name)[
            adminSetState?.tabSelected?.index ?? 0
          ]
        );
        dispatchLinksAction({
          type: actionTypes.SET_CURRENT_CONFIGURATION_FLOW,
          payload: {
            programConfig,
          },
        });
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to get Messages for Stepper: ${err?.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    };
    retrieveCurrentFlows();
  }, [user?.token]);

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  return (
    <div
      className={`${
        asideContext.asideOpen ? "admin__content-expanded" : "admin__content"
      }`}
    >
      <div className="admin__topBar">
        <div className="admin__topBar-title">
          {BUILDER_CONST.SETTINGS}
          <>
            <img src={Divider} alt={"divider"} />
            <span className="admin__selected-onboardingFlow">
              {BUILDER_CONST.LINK_SETTINGS}
            </span>
          </>
        </div>
        <div className="admin__topBar-right">
          <Avatar typeUser={"admin"} />
        </div>
      </div>
      {adminSetState?.isLoading ? (
        <Loader />
      ) : (
        <>
          <nav className="admin__navTab-wrapper">
            {adminSetState?.tabNames?.length > 0 &&
              adminSetState?.tabNames?.map((tabName, index) => {
                return (
                  <div
                    key={`${index}-${tabName}`}
                    onClick={() => changeTabSelected(index, tabName)}
                    className={
                      adminSetState?.tabSelected?.index === index
                        ? "admin__navTab-sectionActive"
                        : "admin__navTab-section"
                    }
                  >
                    <AdminNavTab
                      color={
                        adminSetState?.tabSelected?.index === index
                          ? "#2468DB"
                          : "#17181C"
                      }
                    />
                    <div
                      className={
                        adminSetState?.tabSelected?.index === index
                          ? "admin__navTab-sectionNameActive"
                          : "admin__navTab-sectionName"
                      }
                    >
                      {tabName}
                    </div>
                  </div>
                );
              })}
          </nav>
          {/* TopBar */}
          <div className="admin__settingsLinks-wrapper">
            {adminSetState?.currentFlow?.top_bar?.items?.length > 0 && (
              <div
                key={`section-settings-link-topBar`}
                className="admin__stepper-section"
              >
                <div className="admin__stepper-sectionContent">
                  <div className="admin__stepper-sectionHeader">
                    <span className="admin__stepper-sectionHeaderT">
                      {"Top bar"}
                    </span>
                    <div className="admin__stepper-sectionHeaderE">
                      <span className="admin__stepper-lastUpdated">
                        {ADMIN_CONST.LAST_UPDATED_BY}
                      </span>
                      <span className="admin__violetText">
                        {adminSetState?.currentFlow?.top_bar?.lastUpdatedBy}
                      </span>
                      <span className="admin__stepper-lastUpdated">
                        {formatDateDifference(
                          adminSetState?.currentFlow?.top_bar?.lastUpdated
                        )}
                      </span>
                      {adminSetState?.sectionEditSelected === 0 ? (
                        <>
                          <Button
                            classes="active"
                            className="button__edit-section"
                            onClick={() =>
                              dispatchLinksAction({
                                type: actionTypes.SHOW_MODAL_UPD_FIELD,
                                payload: {
                                  active: true,
                                  value: null,
                                  indexSection: 0,
                                },
                              })
                            }
                            title={BUTTON_CONST.BUTTON_SAVE}
                          />
                          <Button
                            classes="secondary"
                            className="button__edit-section"
                            onClick={() =>
                              dispatchLinksAction({
                                type: actionTypes.CANCEL_EDIT_SECTION,
                              })
                            }
                            title={BUTTON_CONST.BUTTON_CANCEL}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            classes="active"
                            className="button__edit-section"
                            image={{
                              src: EditPencil,
                              alt: "Pencil Edit",
                            }}
                            onClick={() =>
                              dispatchLinksAction({
                                type: actionTypes.SET_SECTION_EDIT_SELECTED,
                                payload: {
                                  sectionEditSelected: 0,
                                },
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className={"admin__stepSection-wrapper"}>
                    {adminSetState?.currentFlow?.top_bar?.items?.map(
                      (link, indexPageLink) => {
                        return (
                          <React.Fragment key={`${indexPageLink}-${link?.id}`}>
                            <div key={`${link?._id}-title`}>
                              <div className="stringInput">
                                <span
                                  className={`inputText__label ${
                                    adminSetState?.sectionEditSelected !== 0
                                      ? "admin__disabled-color"
                                      : ""
                                  }`}
                                >
                                  {`Title *`}
                                </span>
                                <input
                                  disabled={
                                    adminSetState?.sectionEditSelected !== 0
                                  }
                                  type="text"
                                  className={`inputText__box ${
                                    adminSetState?.sectionEditSelected !== 0
                                      ? "admin__disabled-color admin__disabled-background"
                                      : ""
                                  }`}
                                  value={link?.title}
                                  onChange={(event) =>
                                    dispatchLinksAction({
                                      type: actionTypes.ON_CHANGE_CONFIG,
                                      payload: {
                                        id: indexPageLink,
                                        type: "top_bar",
                                        subtype: "items",
                                        field: "title",
                                        value: event.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div key={`${link?._id}-url`}>
                              <div className="stringInput">
                                <span
                                  className={`inputText__label ${
                                    adminSetState?.sectionEditSelected !== 0
                                      ? "admin__disabled-color"
                                      : ""
                                  }`}
                                >
                                  {`Link *`}
                                </span>
                                <input
                                  disabled={
                                    adminSetState?.sectionEditSelected !== 0
                                  }
                                  type="text"
                                  className={`inputText__box ${
                                    adminSetState?.sectionEditSelected !== 0
                                      ? "admin__disabled-color admin__disabled-background"
                                      : ""
                                  }`}
                                  value={link?.url}
                                  onChange={(event) =>
                                    dispatchLinksAction({
                                      type: actionTypes.ON_CHANGE_CONFIG,
                                      payload: {
                                        id: indexPageLink,
                                        type: "top_bar",
                                        subtype: "items",
                                        field: "url",
                                        value: event.target.value,
                                      },
                                    })
                                  }
                                  placeholder="https://www.rcx.com"
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Dashboard */}
          <div className="admin__settingsLinks-wrapper">
            {adminSetState?.currentFlow?.dashboard && (
              <>
                <div
                  key={`section-settings-link-dashboard`}
                  className="admin__stepper-section"
                >
                  <div className="admin__stepper-sectionContent">
                    <div className="admin__stepper-sectionHeader">
                      <span className="admin__stepper-sectionHeaderT">
                        {"Dashboard"}
                      </span>
                      <div className="admin__stepper-sectionHeaderE">
                        <span className="admin__stepper-lastUpdated">
                          {ADMIN_CONST.LAST_UPDATED_BY}
                        </span>
                        <span className="admin__violetText">
                          {adminSetState?.currentFlow?.dashboard?.lastUpdatedBy}
                        </span>
                        <span className="admin__stepper-lastUpdated">
                          {formatDateDifference(
                            adminSetState?.currentFlow?.dashboard?.lastUpdated
                          )}
                        </span>
                        {adminSetState?.sectionEditSelected === 1 ? (
                          <>
                            <Button
                              classes="active"
                              className="button__edit-section"
                              onClick={() =>
                                dispatchLinksAction({
                                  type: actionTypes.SHOW_MODAL_UPD_FIELD,
                                  payload: {
                                    active: true,
                                    value: null,
                                    indexSection: 0,
                                  },
                                })
                              }
                              title={BUTTON_CONST.BUTTON_SAVE}
                            />
                            <Button
                              classes="secondary"
                              className="button__edit-section"
                              onClick={() =>
                                dispatchLinksAction({
                                  type: actionTypes.CANCEL_EDIT_SECTION,
                                })
                              }
                              title={BUTTON_CONST.BUTTON_CANCEL}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              classes="active"
                              className="button__edit-section"
                              image={{
                                src: EditPencil,
                                alt: "Pencil Edit",
                              }}
                              onClick={() =>
                                dispatchLinksAction({
                                  type: actionTypes.SET_SECTION_EDIT_SELECTED,
                                  payload: {
                                    sectionEditSelected: 1,
                                  },
                                })
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="admin__stepper-sectionHeader">
                      <span className="admin__stepper-sectionHeaderSubt">
                        {"Main Image"}
                      </span>
                    </div>
                    {/* Main_Image */}
                    <div
                      className={"admin__stepSection-wrapper"}
                      key={"main_image"}
                    >
                      <>
                        <div key={`dashboard__main_image-title`}>
                          <div className="stringInput">
                            <span
                              className={`inputText__label ${
                                adminSetState?.sectionEditSelected !== 1
                                  ? "admin__disabled-color"
                                  : ""
                              }`}
                            >
                              {`Title *`}
                            </span>
                            <input
                              disabled={
                                adminSetState?.sectionEditSelected !== 1
                              }
                              type="text"
                              className={`inputText__box ${
                                adminSetState?.sectionEditSelected !== 1
                                  ? "admin__disabled-color admin__disabled-background"
                                  : ""
                              }`}
                              value={
                                adminSetState?.currentFlow?.dashboard
                                  ?.main_image?.title
                              }
                              onChange={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                dispatchLinksAction({
                                  type: actionTypes.ON_CHANGE_CONFIG,
                                  payload: {
                                    id: null,
                                    type: "dashboard",
                                    subtype: "main_image",
                                    field: "title",
                                    value: event.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div key={`dashboard__main_image-desc`}>
                          <div className="stringInput">
                            <span
                              className={`inputText__label ${
                                adminSetState?.sectionEditSelected !== 1
                                  ? "admin__disabled-color"
                                  : ""
                              }`}
                            >
                              {`Description *`}
                            </span>
                            <input
                              disabled={
                                adminSetState?.sectionEditSelected !== 1
                              }
                              type="text"
                              className={`inputText__box ${
                                adminSetState?.sectionEditSelected !== 1
                                  ? "admin__disabled-color admin__disabled-background"
                                  : ""
                              }`}
                              value={
                                adminSetState?.currentFlow?.dashboard
                                  ?.main_image?.description
                              }
                              onChange={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                dispatchLinksAction({
                                  type: actionTypes.ON_CHANGE_CONFIG,
                                  payload: {
                                    id: null,
                                    type: "dashboard",
                                    subtype: "main_image",
                                    field: "description",
                                    value: event.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div key={`dashboard__main_image-url`}>
                          <span
                            className={`inputText__label ${
                              adminSetState?.sectionEditSelected !== 1
                                ? "admin__disabled-color"
                                : ""
                            }`}
                          >
                            {"Image *"}
                          </span>
                          <UploadFile
                            id={`dashboard__main_image-url`}
                            disabled={adminSetState?.sectionEditSelected !== 1}
                            itemFile={{
                              type: "uploadFile",
                              titleCard: "Upload an Image File",
                              descriptionCard: "Size 760x272px - JPG, PNG, SVG",
                              acceptFiles: [".jpg", ".png", ".svg"],
                              value: "File",
                            }}
                            isFileUploaded={
                              adminSetState?.currentFlow?.dashboard_images_urls
                                ?.main_image?.length > 0
                            }
                            paramConfig={{
                              active:
                                adminSetState?.currentFlow
                                  ?.dashboard_images_urls?.main_image,
                              leadType: adminSetState?.currentFlow?.leadType,
                              image: "main_image",
                              originalname:
                                adminSetState?.currentFlow?.dashboard_images
                                  ?.main_image?.originalname,
                              size: adminSetState?.currentFlow?.dashboard_images
                                ?.main_image?.size,
                              url: adminSetState?.currentFlow
                                ?.dashboard_images_urls?.main_image,
                            }}
                          />
                        </div>
                      </>
                    </div>
                    <div className="fulldividerSection"></div>
                    {/* Box Links */}
                    <div className="admin__stepper-sectionHeader">
                      <span className="admin__stepper-sectionHeaderSubt">
                        {"Box Links"}
                      </span>
                    </div>
                    <div
                      className={"admin__stepSection-wrapper"}
                      key={"box_links"}
                    >
                      {adminSetState?.currentFlow?.dashboard?.box_links?.map(
                        (link, indexPageLink) => {
                          return (
                            <React.Fragment
                              key={`${indexPageLink}-${link?.id}`}
                            >
                              <div key={`${link?._id}-link`}>
                                <div className="stringInput">
                                  <span
                                    className={`inputText__label ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color"
                                        : ""
                                    }`}
                                  >
                                    {`Title *`}
                                  </span>
                                  <input
                                    disabled={
                                      adminSetState?.sectionEditSelected !== 1
                                    }
                                    type="text"
                                    className={`inputText__box ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color admin__disabled-background"
                                        : ""
                                    }`}
                                    value={link?.title}
                                    onChange={(event) =>
                                      dispatchLinksAction({
                                        type: actionTypes.ON_CHANGE_CONFIG,
                                        payload: {
                                          id: indexPageLink,
                                          type: "dashboard",
                                          subtype: "box_links",
                                          field: "title",
                                          value: event.target.value,
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div key={`${link?._id}-url`}>
                                <div className="stringInput">
                                  <span
                                    className={`inputText__label ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color"
                                        : ""
                                    }`}
                                  >
                                    {`Link *`}
                                  </span>
                                  <input
                                    disabled={
                                      adminSetState?.sectionEditSelected !== 1
                                    }
                                    type="text"
                                    className={`inputText__box ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color admin__disabled-background"
                                        : ""
                                    }`}
                                    value={link?.url}
                                    onChange={(event) =>
                                      dispatchLinksAction({
                                        type: actionTypes.ON_CHANGE_CONFIG,
                                        payload: {
                                          id: indexPageLink,
                                          type: "dashboard",
                                          subtype: "box_links",
                                          field: "url",
                                          value: event.target.value,
                                        },
                                      })
                                    }
                                    placeholder="https://www.rcx.com"
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                    <div className="fulldividerSection"></div>
                    {/* Page Links */}
                    <div className="admin__stepper-sectionHeader">
                      <span className="admin__stepper-sectionHeaderSubt">
                        {"Page Links"}
                      </span>
                    </div>
                    <div
                      className={"admin__stepSection-wrapper"}
                      key={"page_links"}
                    >
                      {adminSetState?.currentFlow?.dashboard?.page_links?.map(
                        (link, indexPageLink) => {
                          return (
                            <React.Fragment
                              key={`${indexPageLink}-${link?.id}`}
                            >
                              <div key={`${link?._id}-title`}>
                                <div className="stringInput">
                                  <span
                                    className={`inputText__label ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color"
                                        : ""
                                    }`}
                                  >
                                    {`Title *`}
                                  </span>
                                  <input
                                    disabled={
                                      adminSetState?.sectionEditSelected !== 1
                                    }
                                    type="text"
                                    className={`inputText__box ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color admin__disabled-background"
                                        : ""
                                    }`}
                                    value={link?.title}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      dispatchLinksAction({
                                        type: actionTypes.ON_CHANGE_CONFIG,
                                        payload: {
                                          id: indexPageLink,
                                          type: "dashboard",
                                          subtype: "page_links",
                                          field: "title",
                                          value: event.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div key={`${link?._id}-description`}>
                                <div className="stringInput">
                                  <span
                                    className={`inputText__label ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color"
                                        : ""
                                    }`}
                                  >
                                    {`Description *`}
                                  </span>
                                  <input
                                    disabled={
                                      adminSetState?.sectionEditSelected !== 1
                                    }
                                    type="text"
                                    className={`inputText__box ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color admin__disabled-background"
                                        : ""
                                    }`}
                                    value={link?.description ?? ""}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      dispatchLinksAction({
                                        type: actionTypes.ON_CHANGE_CONFIG,
                                        payload: {
                                          id: indexPageLink,
                                          type: "dashboard",
                                          subtype: "page_links",
                                          field: "description",
                                          value: event.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div key={`${link?._id}-logo`}>
                                <span
                                  className={`inputText__label ${
                                    adminSetState?.sectionEditSelected !== 1
                                      ? "admin__disabled-color"
                                      : ""
                                  }`}
                                >
                                  {"Image *"}
                                </span>
                                <UploadFile
                                  id={`${indexPageLink}`}
                                  disabled={
                                    adminSetState?.sectionEditSelected !== 1
                                  }
                                  itemFile={{
                                    type: "uploadFile",
                                    titleCard: "Upload an Image File",
                                    descriptionCard:
                                      "Supported Formats: JPG, PNG, SVG",
                                    acceptFiles: [".jpg", ".png", ".svg"],
                                    value: "File",
                                  }}
                                  isFileUploaded={
                                    adminSetState?.currentFlow
                                      ?.dashboard_images_urls?.page_links[
                                      indexPageLink
                                    ]?.length > 0
                                  }
                                  paramConfig={{
                                    active:
                                      adminSetState?.currentFlow
                                        ?.dashboard_images_urls?.page_links,
                                    leadType:
                                      adminSetState?.currentFlow?.leadType,
                                    image: "page_links",
                                    index: indexPageLink,
                                    originalname:
                                      adminSetState?.currentFlow
                                        ?.dashboard_images?.page_links[
                                        indexPageLink
                                      ]?.originalname,
                                    size: adminSetState?.currentFlow
                                      ?.dashboard_images?.page_links[
                                      indexPageLink
                                    ]?.size,
                                    url: adminSetState?.currentFlow
                                      ?.dashboard_images_urls?.page_links[
                                      indexPageLink
                                    ],
                                  }}
                                />
                              </div>
                              <div key={`${link?._id}-url`}>
                                <div className="stringInput">
                                  <span
                                    className={`inputText__label ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color"
                                        : ""
                                    }`}
                                  >
                                    {`Url *`}
                                  </span>
                                  <input
                                    disabled={
                                      adminSetState?.sectionEditSelected !== 1
                                    }
                                    type="text"
                                    className={`inputText__box ${
                                      adminSetState?.sectionEditSelected !== 1
                                        ? "admin__disabled-color admin__disabled-background"
                                        : ""
                                    }`}
                                    value={link?.url ?? ""}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      dispatchLinksAction({
                                        type: actionTypes.ON_CHANGE_CONFIG,
                                        payload: {
                                          id: indexPageLink,
                                          type: "dashboard",
                                          subtype: "page_links",
                                          field: "url",
                                          value: event.target.value,
                                        },
                                      });
                                    }}
                                    placeholder="https://www.rcx.com"
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {adminSetState?.showModalUpdateField?.active && (
            <div className="admin__modal-overaly">
              <Modal classes={"modal_wrapper_confirmation"}>
                <div>
                  <img src={ImgQuestion} alt="Ask question" />
                </div>
                <div className="modal__wrapper-title">
                  <span className="modal__title">
                    {ADMIN_CONST.ORG_ASK_APPROVE_FIELD}
                  </span>
                </div>
                <span className="modal__description-text">
                  {ADMIN_CONST.ORG_ASK_APPROVE_FIELD_DESC}
                </span>
                <div className="modal__twoButtons">
                  <Button
                    classes={"secondary"}
                    onClick={() => {
                      dispatchLinksAction({
                        type: actionTypes.CANCEL_UPD_FIELD,
                      });
                    }}
                    title={BUTTON_CONST.BUTTON_CANCEL}
                  />
                  <Button
                    classes={"active"}
                    onClick={handleUpdateFieldsItems}
                    title={BUTTON_CONST.BUTTON_UPDATE}
                  />
                </div>
              </Modal>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SettingsPrograms;
