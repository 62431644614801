import * as STATUS_CONST from "../constants/status";

export const getClassNameForStatus = (status) => {
  const statusMappings = {
    PENDING: "statusPending",
    TODO: "statusPending",
    APPROVED: "statusApprove",
    "NEEDS ATTENTION": "statusPending",
    DENIED: "statusDenied",
    "NEW OPPORTUNITY": "statusOther",
    "SENT TO RCX": "statusSent",
  };

  const defaultStatus = "statusPending";
  const normalizedStatus = status?.toLocaleUpperCase();
  const statusClass = statusMappings[normalizedStatus] || defaultStatus;

  return `statusOnboarding ${statusClass}`;
};

export const getClassNameForDetailRow = (status) => {
  const statusClassMappings = {
    [STATUS_CONST.STATUS_NEEDS_ATTENTION]: "step__needsAttention",
    [STATUS_CONST.STATUS_PENDING]: "step__needsAttention",
    [STATUS_CONST.STATUS_TODO]: "step__needsAttention",
    [STATUS_CONST.STATUS_DENIED]: "step__denied",
  };

  const defaultStatusClass = "step__default";
  const statusClass = statusClassMappings[status] || defaultStatusClass;

  return `flowSelection__table-row ${statusClass}`;
};
