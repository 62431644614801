import React from "react";
// Router
import { useParams, useLocation } from "react-router";
// Constants
import * as ADMIN_CONST from "../constants/adminPage";
// Context
import AsideProvider from "../context/AsideMenu/AsideContextProvider";
import AdminDashboardProvider from "../context/AdminDashboard/AdminDashboardContextProvider";
// Components
import AsideMenu from "../components/AsideMenu/AsideMenu";
import AdminDashboard from "../components/Admin/AdminDashboard/AdminDashboard";
import AdminDashboardDetail from "../components/Admin/AdminDashboardDetail/AdminDashboardDetail";
import AdminBuilder from "../components/Admin/AdminBuilder/AdminBuilder";
import AdminBuilderDetail from "../components/Admin/AdminBuilderDetail/AdminBuilderDetail";
import AdminLists from "../components/Admin/AdminLists/AdminLists";
import SettingsPrograms from "../components/Admin/AdminSettings/SettingsPrograms/SettingsPrograms";
import ErrorFallback from "../components/ErrorFallback/ErrorFallback";
import LiveView from "../components/LiveView/LiveView";
import AdminBuilderProvider from "../context/AdminBuilder/AdminBuilderContextProvider";

export const AdminPage = () => {
  const { componentName } = useParams();

  const [, , , thridSegment, fourthSegment, fifthSegment] =
    useLocation().pathname.split("/");

  let selectedIcon = -1;
  let componentToRender;

  switch (componentName) {
    case ADMIN_CONST.ADMIN_PAGES.dashboard:
      if (!thridSegment) {
        selectedIcon = 0;
        componentToRender = <AdminDashboard />;
      } else {
        if (!fourthSegment) {
          selectedIcon = 0;
          componentToRender = <AdminDashboardDetail flowId={thridSegment} />;
        } else {
          componentToRender = <ErrorFallback />;
        }
      }
      break;
    case ADMIN_CONST.ADMIN_PAGES.builder:
      if (!thridSegment) {
        selectedIcon = 1;
        componentToRender = <AdminBuilder />;
      } else {
        if (!fourthSegment) {
          selectedIcon = 1;
          componentToRender = <AdminBuilderDetail flowId={thridSegment} />;
        } else {
          if (fourthSegment === "live" && !fifthSegment) {
            selectedIcon = -1;
            componentToRender = <LiveView />;
          } else componentToRender = <ErrorFallback />;
        }
      }
      break;
    case ADMIN_CONST.ADMIN_PAGES.lists:
      if (!thridSegment) {
        selectedIcon = 2;
        componentToRender = <AdminLists />;
      } else {
        componentToRender = <ErrorFallback />;
      }
      break;
    case ADMIN_CONST.ADMIN_PAGES.settings:
      if (thridSegment === ADMIN_CONST.ADMIN_PAGES.programs) {
        if (!fourthSegment) {
          selectedIcon = 3;
          componentToRender = <SettingsPrograms />;
        } else {
          componentToRender = <ErrorFallback />;
        }
      } else {
        componentToRender = <ErrorFallback />;
      }
      break;
    default:
      componentToRender = <ErrorFallback />;
  }

  return (
    <AsideProvider>
      <AdminDashboardProvider>
        <AdminBuilderProvider>
          <div className="admin__page">
            {selectedIcon >= 0 && <AsideMenu selectedIcon={selectedIcon} />}
            {componentToRender}
          </div>
        </AdminBuilderProvider>
      </AdminDashboardProvider>
    </AsideProvider>
  );
};
