import React from "react";
import PropTypes from "prop-types";
// Components
import Button from "../../Button/Button";
import Checkbox from "../Checkbox/Checkbox";
// Icons
import Input from "../../../components/Input/Input";
import XMark from "../../Icons/XMark/XMark";
// Styles
import "./MultiCheckbox.css";
import classNames from "classnames";

const MultiCheckbox = ({
  className = "",
  disabled = false,
  inputAdded,
  label,
  onChange,
  onChangeInput,
  onRemovePill,
  options,
  required = false,
  style,
  value,
  wrapperClassName,
}) => {
  const labelClasses = classNames("inputText__label", {
    "admin__disabled-color": disabled,
  });

  return (
    <div className={wrapperClassName}>
      {label && (
        <label className={labelClasses}>
          {label}
          {required ? "*" : null}
        </label>
      )}
      <div className="multipleCheckbox__wrapper-input">
        {options?.length > 0 &&
          options?.map((dropdownItem, dropdownKey) => {
            const idKey = `${dropdownItem.title}-${dropdownKey}`;
            return (
              <div key={idKey}>
                <Checkbox
                  className={className}
                  checked={value?.includes(dropdownItem?.value)}
                  disabled={disabled}
                  key={`${dropdownItem.title}-${dropdownKey}`}
                  label={dropdownItem?.title}
                  onChange={onChange}
                  value={dropdownItem?.value}
                  wrapperClassName={"multipleCheckbox__option"}
                />
              </div>
            );
          })}
      </div>
      {value?.includes("Other") && (
        <div className="multipleCheckbox__other-input">
          <div>
            <Input
              disabled={disabled}
              onClickIconRight={(inputAdd) =>
                !disabled && onChangeInput(inputAdd)
              }
              placeholder={"Add a new one"}
              style={{
                marginBottom: "40px",
              }}
              type="add"
              width={"398"}
            />
          </div>
          {inputAdded?.length > 0 && (
            <div className="addElementList">
              {inputAdded?.map((element, key) => (
                <>
                  <Button
                    disabled
                    classes="pill"
                    className={`pillLarge pillLarge__text ${
                      disabled
                        ? "admin__disabled-background admin__disabled-color"
                        : ""
                    }`}
                    key={key}
                    title={element}
                    onClick={() => {}}
                  >
                    <XMark
                      disabled={disabled}
                      onClick={() => onRemovePill(key)}
                    />
                  </Button>
                </>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MultiCheckbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputAdded: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onRemovePill: PropTypes.func.isRequired,
  options: PropTypes.array,
  required: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.array,
  wrapperClassName: PropTypes.string,
};

export default MultiCheckbox;
