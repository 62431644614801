import React from "react";
import PropTypes from "prop-types";
// Styles
import "./Modal.css";

const Modal = (props) => {
  const { children, classes, height, width } = props;
  const classesModal = `modalWrapper ${classes ?? ""}`;

  const modalStyle = {
    minHeight: height > 0 ? `${height}px` : undefined,
    width: width > 0 ? `${width}px` : undefined,
  };

  return (
    <div
      className={classesModal}
      style={modalStyle}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Modal;
