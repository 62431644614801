export const AVATAR_LOGOUT = "Log out";
export const DONE_MODAL_TITLE = "It's done!";
export const DONE_MODAL_DESCRIPTION = "Meanwhile you can check a few things";
export const DONE_MODAL_RESOURCES = "Check League Resources";
export const DONE_MODAL_APPROVAL_PROCESS = "Monitor your approval process";
export const DONE_MODAL_GEAR_CATALOG = "Browse our gear catalog";
export const FLOWS_SELECTION_TITLE = "My flows";
export const LAST_UPDATED_BY = "Last updated by:";
export const LEAVE_WITHOUT_SAVING = "You are about to lose your progress.";
export const LEAVE_WITHOUT_SAVING_DESC =
  "You will be able to complete it later.";
export const MY_FLOWS_STATUS_INFO = "status information";
export const MY_FLOWS_STATUS_INFO_TITLE = "Step status";
export const NEW_MESSAGES = "New Messages";
export const ORG_ASK_APPROVE_SECTION =
  "Sure you want to changes status for this section?";
export const ORG_ASK_APPROVE_SECTION_DESC =
  "This will replace current section status";
export const ORG_ASK_APPROVE_ALL = "Sure you want to mark all as approved?";
export const ORG_ASK_APPROVE_ALL_DESC =
  "There are still some items pending a revision on this section, approving them all would override those.";
export const ORG_ASK_CHANGE_STATUS =
  "Sure you want to change this organization's status?";
export const ORG_ASK_CHANGE_STATUS_DESC =
  "Changing the status of this organization will override the existing values and change its status.";
export const ORG_ASK_APPROVE_ORG =
  "Sure you want to approve this organization?";
export const ORG_ASK_APPROVE_ORG_DESC =
  "There are still some items pending a revision, marking this org as approved will override those and approve them all.";
export const ORG_ASK_APPROVE_FIELD = "Sure you want to update this field?";
export const ORG_ASK_APPROVE_FIELD_DESC =
  "This will replace previous content another user has created on this form.";
export const REQUEST_CHANGE_USER = "user";
export const TIMER_WAIT_TO_EXECUTE = 50;
export const TITLE_CHAT = "Chat";
export const UNKNOWN = "unknown";
export const TOGGLE_APPROVED_FLOWS = "Show approved flows";
export const WARNING_FILLIN_REQUIRED_FIELDS =
  "Please fill in all required fields (*) in this step";
export const WARNING_IMPORTANT = "Important!";
export const WARNING_REQUIRED_FIELDS =
  " Fields marked with a (*) will be required for approval, but can be skipped for now if you don't yet have the required information.";
