import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { getBaseURL } from "./getBaseURL";
// Constants
import * as LOGIN_CONST from "../constants/loginPage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const SERVER_URL = getBaseURL();

  async function logout() {
    setUser(null);
  }

  async function login(userAccount) {
    const endpointLogin =
      userAccount?.page === LOGIN_CONST.APP_PAGES.loginAdmin.page
        ? "/admin/login"
        : "/user/login";
    try {
      const res = await fetch(`${SERVER_URL}${endpointLogin}`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userAccount?.email,
          password: userAccount?.password,
        }),
      });
      if (!res?.ok) {
        const errorMsg = await res?.json();
        throw Object.assign(
          new Error(
            `${res?.statusText} : ${errorMsg?.error}. Status: ${res?.status}`
          ),
          {
            status: res?.status,
            statusText: res?.statusText,
            cause: errorMsg?.error,
          }
        );
      }
      const data = await res?.json();
      setUser({
        admin: data?.data?.admin || false,
        email: data?.data?.email,
        firstName: data?.data?.firstName,
        lastName: data?.data?.lastName,
        initials:
          data?.data?.firstName[0]?.toUpperCase() +
          data?.data?.lastName[0]?.toUpperCase(),
        rememberMe: userAccount?.rememberMe,
        token: data?.data?.token,
        userId: data?.data?.userId,
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function setPassword(userAccount) {
    try {
      const res = await fetch(
        `${SERVER_URL}/user/${userAccount?.userID}/set_password`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: userAccount?.password,
            repeat_password: userAccount?.repeat_password,
          }),
        }
      );
      if (!res?.ok) {
        const errorMsg = await res?.json();
        throw Object.assign(
          new Error(
            `${res?.statusText} : ${errorMsg?.error}. Status: ${res?.status}`
          ),
          {
            status: res?.status,
            statusText: res?.statusText,
            cause: errorMsg?.error,
          }
        );
      }
      const data = await res?.json();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function triggerResetPassword(email) {
    try {
      const res = await fetch(`${SERVER_URL}/user/reset_password`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });
      if (!res?.ok) {
        const errorMsg = await res?.json();
        throw Object.assign(
          new Error(
            `${res?.statusText} : ${errorMsg?.error}. Status: ${res?.status}`
          ),
          {
            status: res?.status,
            statusText: res?.statusText,
            cause: errorMsg?.error,
          }
        );
      }
      const data = await res?.json();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function resetPassword({ token, userAccount }) {
    try {
      const res = await fetch(`${SERVER_URL}/user/reset_password/${token}`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: userAccount?.password,
          repeat_password: userAccount?.confirmPass,
        }),
      });
      if (!res?.ok) {
        const errorMsg = await res?.json();
        throw Object.assign(
          new Error(
            `${res?.statusText} : ${errorMsg?.error}. Status: ${res?.status}`
          ),
          {
            status: res?.status,
            statusText: res?.statusText,
            cause: errorMsg?.error,
          }
        );
      }
      const data = await res?.json();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getFlowListForAnUser() {
    const userLocalStorage = window.localStorage.getItem("user");
    if (typeof userLocalStorage !== "undefined" && userLocalStorage) {
      try {
        const res = await fetch(`${SERVER_URL}/onboarding`, {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "auth-token": JSON.parse(userLocalStorage)?.token,
          },
        });
        if (!res?.ok) {
          const errorMsg = await res?.json();
          throw Object.assign(
            new Error(
              `${res?.statusText} : ${errorMsg?.error}. Status: ${res?.status}`
            ),
            {
              status: res?.status,
              statusText: res?.statusText,
              cause: errorMsg?.error,
            }
          );
        }
        const data = await res?.json();
        setUser({
          ...JSON.parse(userLocalStorage),
          flows: data?.flows,
          allFlows: data?.allFlows,
          onboardingFlowID: data?.flows?.[0]?._id,
          status: data?.flows?.[0]?.status,
        });
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }

  async function getOnboardingListFromAdmin(onboardingId) {
    try {
      const res = await fetch(
        `${SERVER_URL}/admin/onboarding/${onboardingId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "auth-token": user?.token,
          },
        }
      );
      if (!res?.ok) {
        const errorMsg = await res?.json();
        throw Object.assign(
          new Error(
            `${res?.statusText} : ${errorMsg?.error}. Status: ${res?.status}`
          ),
          {
            status: res?.status,
            statusText: res?.statusText,
            cause: errorMsg?.error,
          }
        );
      }
      const data = await res?.json();
      const newState = { ...user, onboardingFlowID: data._id };
      setUser(newState);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getFlowIdFromUser(flowId) {
    try {
      const res = await fetch(`${SERVER_URL}/onboarding/${flowId}`, {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "auth-token": user?.token,
        },
      });
      if (!res?.ok) {
        const errorMsg = await res?.json();
        throw Object.assign(
          new Error(
            `${res?.statusText} : ${errorMsg?.error}. Status: ${res?.status}`
          ),
          {
            status: res?.status,
            statusText: res?.statusText,
            cause: errorMsg?.error,
          }
        );
      }
      const data = await res?.json();
      const newState = { ...user, onboardingFlowID: data?.onboardingUser?._id };
      setUser(newState);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function changeOnboardingStatus(flowId, statusUpdate) {
    try {
      const body = {};
      if (statusUpdate?.status) {
        body.status = statusUpdate?.status;
      }
      if (statusUpdate?.stepperIndex > -1) {
        body.stepper_index = statusUpdate?.stepperIndex;
      }
      if (statusUpdate?.sectionIndex > -1) {
        body.section_index = statusUpdate?.sectionIndex;
      }
      const res = await fetch(
        `${SERVER_URL}/admin/onboarding/${flowId}/status`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "auth-token": user?.token,
          },
          body: JSON.stringify(body),
        }
      );
      if (!res?.ok) {
        const errorMsg = await res?.json();
        throw Object.assign(
          new Error(
            `${res?.statusText} : ${errorMsg?.error}. Status: ${res?.status}`
          ),
          {
            status: res?.status,
            statusText: res?.statusText,
            cause: errorMsg?.error,
          }
        );
      }
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function changeOnboardingSectionItems(flowId, statusUpdate, type) {
    const URLChangeSectionItems =
      type === "user"
        ? `${SERVER_URL}/onboarding/${flowId}/edit`
        : `${SERVER_URL}/admin/onboarding/${flowId}`;
    try {
      const res = await fetch(URLChangeSectionItems, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "auth-token": user?.token,
        },
        body: JSON.stringify({
          sectionItems: statusUpdate?.sectionItems,
          stepper_index: statusUpdate?.stepperIndex,
          section_index: statusUpdate?.sectionIndex,
        }),
      });
      if (!res?.ok) {
        const contentType = res?.headers.get("Content-Type");
        if (!contentType || !contentType?.includes("application/json")) {
          throw Object.assign(
            new Error(
              `${res?.statusText}. ContentType: ${contentType}. Status: ${res?.status}`
            ),
            {
              status: res?.status,
              statusText: res?.statusText,
            }
          );
        }
        const errorMsg = await res?.json();
        throw Object.assign(
          new Error(
            `${res?.statusText} : ${errorMsg?.error}. Status: ${res?.status}`
          ),
          {
            status: res?.status,
            statusText: res?.statusText,
            cause: errorMsg?.error,
          }
        );
      }
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  const userValue = useMemo(
    () => ({
      user,
      logout,
      login,
      setPassword,
      triggerResetPassword,
      resetPassword,
      getFlowListForAnUser,
      getFlowIdFromUser,
      getOnboardingListFromAdmin,
      changeOnboardingStatus,
      changeOnboardingSectionItems,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={userValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
