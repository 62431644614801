import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Pages
import { AccountAuthentication } from "./pages/AccountAuthentication";
import { AdminPage } from "./pages/AdminPage";
import { OnboardingFlowIDPage } from "./pages/OnboardingFlowIDPage";
import UserFlowsSelection from "./components/User/UserFlowsSelection/UserFlowsSelection";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";
// Constants
import * as LOGIN_CONST from "./constants/loginPage";
// Styles
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            exact
            path="/flow/:onboardingFlowID"
            element={<OnboardingFlowIDPage />}
          />
          <Route exact path="/flows/:userID" element={<UserFlowsSelection />} />
          <Route
            exact
            path="/set_password/:userID/:email"
            element={
              <AccountAuthentication
                configuration={LOGIN_CONST.APP_PAGES.setPassword}
              />
            }
          />
          <Route
            exact
            path="/reset_password"
            element={
              <AccountAuthentication
                configuration={LOGIN_CONST.APP_PAGES.resetPasswordInitial}
              />
            }
          />
          <Route
            exact
            path="/reset_password/:token/:email"
            element={
              <AccountAuthentication
                configuration={LOGIN_CONST.APP_PAGES.resetPassword}
              />
            }
          />
          <Route
            exact
            path="/login"
            element={
              <AccountAuthentication
                configuration={LOGIN_CONST.APP_PAGES.loginUser}
              />
            }
          />
          <Route
            exact
            path="/admin/login"
            element={
              <AccountAuthentication
                configuration={LOGIN_CONST.APP_PAGES.loginAdmin}
              />
            }
          />
          <Route path="/admin/:componentName/*" element={<AdminPage />} />
          <Route path="*" element={<ErrorFallback />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
