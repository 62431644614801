import React from "react";
import PropTypes from "prop-types";
// Components
import LargeCard from "../LargeCard/LargeCard";
import UploadFile from "../UploadFile/UploadFile";
// Styles
import "./CombineCard.css";
import classNames from "classnames";

const CombineCard = ({
  className = "",
  disabled = false,
  label,
  onChange,
  options,
  required = false,
  style,
  value,
  wrapperClassName,
}) => {
  const labelClasses = classNames("inputText__label", {
    "admin__disabled-color": disabled,
  });

  const inputDateClasses = classNames("combineCard__unit", className, {
    "admin__disabled-color admin__disabled-background user__disabled-background":
      disabled,
  });

  const handleOnClick = (type, value) => {
    return !disabled && onChange(type, value);
  };

  return (
    <div className={wrapperClassName}>
      {label && (
        <label className={labelClasses}>
          {label}
          {required ? "*" : null}
        </label>
      )}
      <div className="combineCard__largeCard">
        {options?.length > 0 &&
          options?.map((dropdownItem, dropdownKey) => {
            const idKey = `${dropdownItem.title}-${dropdownKey}`;
            return (
              <div
                className={inputDateClasses}
                key={idKey}
                onClick={() =>
                  handleOnClick(
                    dropdownItem?.type,
                    value === dropdownItem?.value ? null : dropdownItem?.value
                  )
                }
                style={style}
              >
                {dropdownItem?.type === "uploadFile" ? (
                  <UploadFile
                    disabled={disabled}
                    itemFile={dropdownItem}
                    isFileUploaded={value === "File"}
                    onChange={() => onChange("uploadFile", "File")}
                  />
                ) : (
                  <LargeCard
                    disabled={disabled}
                    item={dropdownItem}
                    selectedValue={value}
                    groupName={label}
                    onChange={() =>
                      !disabled &&
                      onChange(dropdownItem.type, dropdownItem?.value)
                    }
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

CombineCard.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  required: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default CombineCard;
