import React from "react";
import { getIconForLink } from "../../utils/getIconForTopBar";

const NavigationItemLink = ({ item }) => {
  return (
    <>
      <div className="topbar_sections_item">
        <a
          href={item?.url?.includes("@") ? `mailto:${item?.url}` : item?.url}
          target="_blank"
          rel="noopener noreferrer"
          className="topbar_link"
        >
          {getIconForLink(item?.title)}
          <span className="topbar_text">{item?.title}</span>
        </a>
      </div>
    </>
  );
};

export default NavigationItemLink;
