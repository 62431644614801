import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
// Components
import Button from "../Button/Button";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";
import Checkbox from "../FormComponents/Checkbox/Checkbox";
// Helpers
import {
  isEmailValid,
  isPasswordValid,
  containSpecialCharacter,
} from "../../utils/validationsFields";
import debounce from "lodash.debounce";
// Icons
import ModalIconNumberOne from "../../assets/image_done_number_one.png";
import ImageLock from "../../assets/image_lock.png";
import ImageEmailSent from "../../assets/email_sent.png";
// Utils
import { useAuth } from "../../utils/authenticate";
// Constants
import * as LOGIN_CONST from "../../constants/loginPage";
import * as BUTTON_CONST from "../../constants/buttons";
import * as STATUS_CONST from "../../constants/status";
// Styles
import "./style.css";

export const Account = (props) => {
  const {
    getFlowListForAnUser,
    login,
    logout,
    triggerResetPassword,
    resetPassword,
    setPassword,
    user,
  } = useAuth();
  const {
    userAccount: { userID = "", email = "", token = "" } = {},
    configuration: { buttonName, page: initialPage, title },
  } = props;
  const navigate = useNavigate();

  const [isError, setIsError] = useState({ active: false, error: "" });
  const [userAccount, setUserAccount] = useState({
    userId: userID,
    email,
    password: "",
    confirmPass: "",
    rememberMe: false,
  });
  const [visibility, setVisibility] = useState({
    informativeTextPassword: false,
    buttonConfirm: false,
  });
  const [error, setError] = useState({
    email: {
      value: email?.length > 0 && !isEmailValid(userAccount?.email),
      message: !isEmailValid(userAccount?.email)
        ? LOGIN_CONST.ERROR_MSG.invalid_email
        : "",
    },
    password: { value: false, message: "" },
    confirmPass: { value: false, message: "" },
  });

  const [page, setPage] = useState(initialPage);

  const validateMatchPassword = useCallback(
    debounce((newPassword, confirmPassword) => {
      if (newPassword === confirmPassword) {
        setError({
          ...error,
          password: { value: false, message: "" },
          confirmPass: { value: false, message: "" },
        });
      } else {
        if (!error?.confirmPass?.value) {
          setError({
            ...error,
            confirmPass: {
              value: true,
              message: LOGIN_CONST.ERROR_MSG.passwords_dont_match,
            },
          });
        }
      }
    }, 300),
    [error]
  );

  const validateEmail = useCallback(
    debounce((email) => {
      if (!isEmailValid(email)) {
        setError({
          ...error,
          email: {
            value: true,
            message: LOGIN_CONST.ERROR_MSG.invalid_email,
          },
        });
      } else {
        setError({
          ...error,
          email: {
            value: false,
            message: "",
          },
        });
      }
    }, 500),
    []
  );

  const onChangeEmail = (event) => {
    setUserAccount({ ...userAccount, email: event.target.value });
    validateEmail(event.target.value);
    if (error?.email?.value) {
      if (error?.password?.value) {
        setError({
          ...error,
          email: { value: false, message: "" },
          password: { value: false, message: "" },
        });
      } else {
        setError({ ...error, email: { value: false, message: "" } });
      }
    }
  };

  const onChangePassword = (event) => {
    setUserAccount({ ...userAccount, password: event.target.value });
    if (
      page !== LOGIN_CONST.APP_PAGES.loginUser.page &&
      page !== LOGIN_CONST.APP_PAGES.loginAdmin.page
    ) {
      if (isPasswordValid(event.target.value)) {
        setVisibility({
          ...visibility,
          informativeTextPassword: false,
        });
        if (
          userAccount?.confirmPass?.length > 0 &&
          event.target.value !== userAccount?.confirmPass
        ) {
          return setError({
            ...error,
            password: { value: false, message: "" },
            confirmPass: {
              value: true,
              message: LOGIN_CONST.ERROR_MSG.passwords_dont_match,
            },
          });
        } else {
          setError({
            ...error,
            password: { value: false, message: "" },
            confirmPass: { value: false, message: "" },
          });
        }
      } else {
        setVisibility({
          ...visibility,
          informativeTextPassword: true,
        });
      }
    } else {
      if (error?.password?.value) {
        setError({
          ...error,
          password: { value: false, message: "" },
        });
      }
    }
  };

  const onFocusPassword = () => {
    if (userAccount?.password?.length < LOGIN_CONST.PASS_MIN_LENGTH) {
      setVisibility({ ...visibility, informativeTextPassword: true });
    }
  };

  const onChangeConfirmPass = (event) => {
    setUserAccount({ ...userAccount, confirmPass: event.target.value });
    validateMatchPassword(userAccount?.password, event.target.value);
    if (error?.confirmPass?.value) {
      setError({ ...error, confirmPass: { value: false, message: "" } });
    }
  };

  const onChangeRememberMe = () => {
    setUserAccount({ ...userAccount, rememberMe: !userAccount?.rememberMe });
  };

  const onClickChangePassword = async () => {
    if (!isPasswordValid(userAccount?.password)) {
      if (
        (userAccount?.password?.length < LOGIN_CONST.PASS_MIN_LENGTH ||
          userAccount?.password?.length > LOGIN_CONST.PASS_MAX_LENGTH) &&
        !containSpecialCharacter(userAccount?.password)
      ) {
        if (userAccount?.password?.length > 16) {
          return setError({
            ...error,
            password: {
              value: true,
              message: LOGIN_CONST.ERROR_MSG.pass_exceeds_maxLength_condition,
            },
          });
        } else {
          return setError({
            ...error,
            password: {
              value: true,
              message: LOGIN_CONST.ERROR_MSG.pass_longer_minLength_condition,
            },
          });
        }
      } else if (userAccount?.password?.length < LOGIN_CONST.PASS_MIN_LENGTH) {
        return setError({
          ...error,
          password: {
            value: true,
            message: LOGIN_CONST.ERROR_MSG.pass_longer_minLength,
          },
        });
      } else if (userAccount?.password?.length > LOGIN_CONST.PASS_MAX_LENGTH) {
        return setError({
          ...error,
          password: {
            value: true,
            message: LOGIN_CONST.ERROR_MSG.pass_exceeds_maxLength,
          },
        });
      } else {
        return setError({
          ...error,
          password: {
            value: true,
            message: LOGIN_CONST.ERROR_MSG.pass_dont_satisfy_condition,
          },
        });
      }
    }
    if (userAccount?.password !== userAccount?.confirmPass) {
      return setError({
        ...error,
        confirmPass: {
          value: true,
          message: LOGIN_CONST.ERROR_MSG.passwords_dont_match,
        },
      });
    }
    if (Object.values(error).every((err) => !err.value)) {
      if (page === LOGIN_CONST.APP_PAGES.setPassword.page) {
        try {
          const dataSetPassword = await setPassword({
            userID: userAccount?.userId,
            password: userAccount?.password,
            repeat_password: userAccount?.confirmPass,
          });
          if (dataSetPassword?.admin) {
            setPage(LOGIN_CONST.APP_PAGES.setAdminPasswordOK.page);
          } else {
            setPage(LOGIN_CONST.APP_PAGES.setUserPasswordOK.page);
          }
        } catch (err) {
          if (err?.cause === "user does not exist") {
            return setError({
              ...error,
              email: {
                value: true,
                message: LOGIN_CONST.ERROR_MSG.user_does_not_exist,
              },
            });
          }
          if (err?.cause === "user has password") {
            return setError({
              ...error,
              email: {
                value: true,
                message: LOGIN_CONST.ERROR_MSG.user_already_has_password_setted,
              },
            });
          }
          setIsError({
            active: true,
            error: `Failed to setPassword: ${err?.cause}`,
            status: err?.status,
            statusText: err?.statusText,
          });
        }
      } else {
        try {
          const dataSetPassword = await resetPassword({
            token,
            userAccount,
          });
          if (dataSetPassword?.admin) {
            setPage(LOGIN_CONST.APP_PAGES.setAdminPasswordOK.page);
          } else {
            setPage(LOGIN_CONST.APP_PAGES.setUserPasswordOK.page);
          }
        } catch (err) {
          if (err?.cause === "user does not exist") {
            return setError({
              ...error,
              email: {
                value: true,
                message: LOGIN_CONST.ERROR_MSG.user_does_not_exist,
              },
            });
          }
          if (err?.cause === "invalid") {
            return setError({
              ...error,
              email: {
                value: true,
                message: LOGIN_CONST.ERROR_MSG.invalid_password_reset_link,
              },
            });
          }
          setIsError({
            active: true,
            error: `Failed to setPassword: ${err?.cause}`,
            status: err?.status,
            statusText: err?.statusText,
          });
        }
      }
    }
  };

  const onClickSendResetPassword = async () => {
    if (!isEmailValid(userAccount?.email)) return;
    try {
      await triggerResetPassword(userAccount?.email);
      setPage(LOGIN_CONST.APP_PAGES.resetPasswordOK.page);
    } catch (err) {
      if (err?.cause === "user does not exist" || "User not found") {
        return setError({
          ...error,
          email: {
            value: true,
            message: LOGIN_CONST.ERROR_MSG.user_does_not_exist,
          },
        });
      }
      setIsError({
        active: true,
        error: `Failed to triggerResetPassword: ${err?.cause}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
  };

  const onClickContinueToLogIn = () => {
    if (page === LOGIN_CONST.APP_PAGES.setAdminPasswordOK.page) {
      navigate(`/admin/login`, { replace: true });
    } else {
      navigate(`/login`, { replace: true });
    }
  };

  async function onClickLogIn() {
    if (!isEmailValid(userAccount?.email)) {
      setError({
        ...error,
        email: {
          value: true,
          message: LOGIN_CONST.ERROR_MSG.invalid_email,
        },
      });
    }
    if (
      isEmailValid(userAccount?.email) &&
      Object.values(error).every((err) => !err.value)
    ) {
      try {
        const { data } = await login({
          email: userAccount?.email,
          password: userAccount?.password,
          page,
          rememberMe: userAccount?.rememberMe,
        });
        if (page === LOGIN_CONST.APP_PAGES.loginAdmin.page) {
          return navigate(`/admin/dashboard`);
        }
        const onBoardingList = await getFlowListForAnUser();
        const hasOneFlow = onBoardingList?.flows?.length < 2;
        if (hasOneFlow) {
          const firstOnboardingFlowID = onBoardingList?.flows?.[0]?._id;
          const firstOnboardingFlowStatus = onBoardingList?.flows?.[0]?.status;
          if (firstOnboardingFlowID) {
            if (
              firstOnboardingFlowStatus === STATUS_CONST.STATUS_NEW_OPPORTUNITY
            ) {
              return navigate(`/flow/${firstOnboardingFlowID}`);
            } else {
              return navigate(`/flows/${data?.userId}`, {
                state: { onBoardingList },
              });
            }
          } else {
            throw new Error("There is not Flow for this user");
          }
        } else {
          navigate(`/flows/${data?.userId}`, { state: { onBoardingList } });
        }
      } catch (err) {
        if (
          err?.cause &&
          err?.cause?.toLowerCase().includes("incorrect password")
        ) {
          return setError({
            ...error,
            password: {
              value: true,
              message: LOGIN_CONST.ERROR_MSG.user_password_incorrect,
            },
          });
        }
        if (
          err?.cause &&
          err?.cause?.toLowerCase().includes("user not found")
        ) {
          return setError({
            ...error,
            email: {
              value: true,
              message: LOGIN_CONST.ERROR_MSG.user_email_incorrect,
            },
          });
        }
        if (err?.cause && err?.cause?.toLowerCase().includes("invalid token")) {
          return setError({
            ...error,
            email: {
              value: true,
              message:
                LOGIN_CONST.ERROR_MSG.user_dont_have_any_onboarding_available,
            },
          });
        }
        setIsError({
          active: true,
          error: `Failed to fetch login/getFlowListForAnUser: ${err.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    }
  }

  useEffect(() => {
    const userLocalStorage = window.localStorage.getItem("user");
    if (typeof userLocalStorage !== "undefined" && userLocalStorage) {
      if (
        JSON.parse(userLocalStorage)?.token &&
        JSON.parse(userLocalStorage)?.rememberMe === true
      ) {
        if (
          page === LOGIN_CONST.APP_PAGES.loginUser.page &&
          user?.flows?.length > 0
        ) {
          if (!user?.admin) {
            if (user?.flows?.length < 2) {
              navigate(`/flow/${user?.onboardingFlowID}`);
            } else {
              navigate(`/flows/${user?.userId}`);
            }
          } else {
            alert("This user don't have permissions!");
          }
        } else {
          if (page === LOGIN_CONST.APP_PAGES.loginAdmin.page && user?.userId) {
            navigate(`/admin/dashboard`);
          } else if (
            page === LOGIN_CONST.APP_PAGES.loginUser.page ||
            page === LOGIN_CONST.APP_PAGES.loginAdmin.page
          ) {
            logout();
          }
        }
      } else {
        if (
          page === LOGIN_CONST.APP_PAGES.loginUser.page ||
          page === LOGIN_CONST.APP_PAGES.loginAdmin.page
        ) {
          logout();
        }
      }
    }
  }, []);

  useEffect(() => {
    setIsError({ active: false, error: "" });
    setUserAccount({
      userId: userID,
      email,
      password: "",
      confirmPass: "",
      rememberMe: false,
    });
    setVisibility({
      informativeTextPassword: false,
      buttonConfirm: false,
    });
    setError({
      email: {
        value: email?.length > 0 && !isEmailValid(userAccount?.email),
        message: !isEmailValid(userAccount?.email)
          ? LOGIN_CONST.ERROR_MSG.invalid_email
          : "",
      },
      password: { value: false, message: "" },
      confirmPass: { value: false, message: "" },
    });
    setPage(initialPage);
  }, [initialPage]);

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  return (
    <div className="accountPage__wrapper">
      <div className="account__heading">
        <span>{title}</span>
      </div>
      <div className="account__dividerSection"></div>
      {(page === LOGIN_CONST.APP_PAGES.setPassword.page ||
        page === LOGIN_CONST.APP_PAGES.resetPassword.page) && (
        <>
          <Modal>
            <form className="accountForm">
              <div className="formSection">
                <div>
                  <Input
                    error={{
                      value: error?.email?.value,
                      message: error?.email?.message,
                    }}
                    disabled={true}
                    label={{ name: "Email", required: true }}
                    placeholder={"Write here"}
                    style={{ marginBottom: "18px" }}
                    type="email"
                    value={userAccount?.email}
                  />
                  <Input
                    disabled={error?.email?.value}
                    error={{
                      value: error?.password?.value,
                      message: error?.password?.message,
                    }}
                    informativeText={{
                      visible: visibility?.informativeTextPassword,
                      message:
                        LOGIN_CONST.ERROR_MSG.pass_longer_minLength_condition,
                    }}
                    label={{ name: "New password", required: true }}
                    onChange={onChangePassword}
                    onFocus={onFocusPassword}
                    placeholder={"Write here"}
                    style={{ marginBottom: "18px" }}
                    type="password"
                    value={userAccount?.password}
                  />
                  <Input
                    checkedPassword={
                      !visibility.informativeTextPassword &&
                      Object.values(error).every((err) => !err.value) &&
                      userAccount?.password?.length > 0 &&
                      userAccount?.password === userAccount?.confirmPass
                    }
                    disabled={error?.email?.value}
                    error={{
                      value: error?.confirmPass?.value,
                      message: error?.confirmPass?.message,
                    }}
                    label={{ name: "Confirm new password", required: true }}
                    onChange={onChangeConfirmPass}
                    onFocus={onFocusPassword}
                    placeholder={"Write here"}
                    type="password"
                    value={userAccount?.confirmPass}
                    onKeyPress={(event) => {
                      return event.key === "Enter" && onClickChangePassword();
                    }}
                  />
                </div>
              </div>
            </form>
          </Modal>
          <div>
            <Button
              title={buttonName}
              disabled={
                error?.email?.value || userAccount?.password?.length === 0
              }
              classes={
                error?.email?.value || userAccount?.password?.length === 0
                  ? "disabled"
                  : "active"
              }
              onClick={onClickChangePassword}
            />
          </div>
          <div className="accountPage__footer">
            <span>
              {LOGIN_CONST.DONT_HAVE_ACCOUNT}
              <a href="mailto:info@nflflag.com">
                {" "}
                {/* FGL */}
                {LOGIN_CONST.CONTACT_SUPPORT}
              </a>
            </span>
          </div>
        </>
      )}
      {(page === LOGIN_CONST.APP_PAGES.setUserPasswordOK.page ||
        page === LOGIN_CONST.APP_PAGES.setAdminPasswordOK.page) && (
        <Modal>
          <div className="modal__icon-img">
            <img src={ModalIconNumberOne} alt="Modal Icon" />
          </div>
          <div className="modal__wrapper-title">
            <span className="modal__title">
              {LOGIN_CONST.TITLE_PASSWORD_UPDATED}
            </span>
          </div>
          <span className="modal__description-text">
            {LOGIN_CONST.DESCRIPTION_PASSWORD_UPDATED}
          </span>
          <Button
            title={BUTTON_CONST.BUTTON_CONTINUE_TO_LOG_IN}
            classes={"active"}
            onClick={onClickContinueToLogIn}
          />
        </Modal>
      )}
      {(page === LOGIN_CONST.APP_PAGES.loginUser.page ||
        page === LOGIN_CONST.APP_PAGES.loginAdmin.page) && (
        <>
          <Modal>
            <div className="account__modalLogin-wrapperInput">
              <Input
                error={{
                  value: error?.email.value,
                  message: error?.email?.message,
                }}
                label={{ name: "Email" }}
                onChange={onChangeEmail}
                placeholder={"Write here"}
                type="email"
                value={userAccount?.email}
              />
              <Input
                error={{
                  value: error?.password?.value,
                  message: error?.password?.message,
                }}
                label={{ name: "Password" }}
                onChange={onChangePassword}
                onFocus={onFocusPassword}
                placeholder={"Write here"}
                style={{ marginBottom: "18px" }}
                type="password"
                value={userAccount?.password}
                onKeyPress={(event) => {
                  return (
                    event.key === "Enter" &&
                    userAccount?.password?.length &&
                    onClickLogIn()
                  );
                }}
              />
            </div>
          </Modal>
          <div className="login__extras-wrapper">
            <Checkbox
              checked={userAccount?.rememberMe}
              key={"login_rememberMe"}
              label={LOGIN_CONST.REMEMBER_ME}
              onChange={onChangeRememberMe}
              value={userAccount?.rememberMe}
              wrapperClassName={"checkbox__wrapper-normalGap"}
            />
            <Link className="account__forgotPassword" to="/reset_password">
              {LOGIN_CONST.FORGOT_PASSWORD}
            </Link>
          </div>
          <div>
            <Button
              title={BUTTON_CONST.BUTTON_LOG_IN}
              classes={"active"}
              onClick={onClickLogIn}
            />
          </div>
        </>
      )}
      {page === LOGIN_CONST.APP_PAGES.resetPasswordInitial.page && (
        <div className="resetPassword__initial-wrapper">
          <img src={ImageLock} alt={"Lock"} />
          <span className="resetPassword__initial-title">
            {LOGIN_CONST.RESET_PASSWORD_ENTER_EMAIL}
          </span>
          <span className="resetPassword__initial-description">
            {LOGIN_CONST.RESET_PASSWORD_DESCRIPTION}
          </span>
          <Input
            error={{
              value: error?.email?.value,
              message: error?.email?.message,
            }}
            label={{ name: "Email", required: true }}
            onChange={onChangeEmail}
            placeholder={"Write here"}
            style={{ marginBottom: "18px" }}
            type="email"
            value={userAccount?.email}
            onKeyPress={(event) => {
              return event.key === "Enter" && onClickSendResetPassword();
            }}
          />
          <Button
            title={buttonName}
            disabled={
              userAccount?.email?.trim()?.length === 0 || error?.email?.value
            }
            classes={
              userAccount?.email?.trim()?.length === 0 || error?.email?.value
                ? "disabled"
                : "active"
            }
            onClick={onClickSendResetPassword}
          />
        </div>
      )}
      {page === LOGIN_CONST.APP_PAGES.resetPasswordOK.page && (
        <div className="resetPassword__initial-wrapper">
          <img src={ImageEmailSent} alt={"Email Sent"} />
          <span className="resetPassword__initial-title">
            {LOGIN_CONST.RESET_PASSWORD_OK}
          </span>
          <span className="resetPassword__initial-description">
            {`We sent the reset password email to`}
            <br />
            {`${userAccount?.email}`}
          </span>
          {/* FGL */
          /* <Button
            title={"Go Back To Log In"}
            disabled={error?.email?.value}
            classes={error?.email?.value ? "disabled" : "active"}
            onClick={onClickSendResetPassword}
          /> */}
        </div>
      )}
    </div>
  );
};
