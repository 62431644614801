import React from "react";
import PropTypes from "prop-types";
import "./Checked.css";

const Checked = (props) => {
  const { classes, color, height, width } = props;

  return (
    <div className={classes}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Add, remove, cancel/ok">
          <path
            id="Path 3"
            d="M4 13L9 18L20 7"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </div>
  );
};

Checked.propTypes = {
  classes: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Checked.defaultProps = {
  color: "#17181C",
  height: 24,
  width: 24,
};

export default Checked;
