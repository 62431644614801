import React from "react";
// Components
import Avatar from "../Avatar/Avatar";
// Constants
import * as STATUS_CONST from "../../constants/status";
// Utils
import { getClassNameForStatus } from "../../utils/getClassNameForStatus";

const TopHeaderUserProfile = ({ onboardingUser }) => {
  return (
    <div className="topbar_user">
      <div className="topbar_user_info">
        <span className="topbar_username">Hi, {onboardingUser?.name}!</span>
        <div>
          {onboardingUser?.role !== STATUS_CONST.STATUS_NEW_OPPORTUNITY &&
            onboardingUser?.leadType && (
              <span
                className={`${getClassNameForStatus(onboardingUser?.role)}`}
              >
                {onboardingUser?.role}
              </span>
            )}
        </div>
      </div>
      <Avatar typeUser={"user"} />
    </div>
  );
};

export default TopHeaderUserProfile;
