import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
// Images
import IconExit from "../../assets/icon_exit.png";
// Constants
import * as USER_CONST from "../../constants/userPage";
// Utils
import { useAuth } from "../../utils/authenticate";
// Styles
import "./Avatar.css";

const Avatar = ({ typeUser }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);

  const handleClick = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = useCallback(() => {
    logout();
    if (typeUser === "user") {
      navigate(`/login`, { replace: true });
    }
    if (typeUser === "admin") {
      navigate(`/admin/login`, { replace: true });
    }
  }, [logout, navigate, typeUser]);

  return (
    <div className="topbar_avatar-container" onClick={handleClick}>
      <div className="topbar_avatar-content">
        <span className="topbar_circle">{user?.initials}</span>
        {showMenu && (
          <div className="avatar__menu" onClick={handleLogout}>
            <img src={IconExit} alt={"Exit"} />
            <span className="avatar__menu-text">
              {USER_CONST.AVATAR_LOGOUT}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Avatar;
