import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Components
import Modal from "../../Modal/Modal";
import Button from "../../Button/Button";
import StatusDropDown from "../StatusDropDown/StatusDropDown";
import Loader from "../../Loader/Loader";
import Avatar from "../../Avatar/Avatar";
// Reducers
import { actionTypes } from "../../../reducers/AdminDashboard/reducer";
// Context
import AsideContext from "../../../context/AsideMenu/AsideContext";
import AdminDashboardContext from "../../../context/AdminDashboard/AdminDashboardContext";
// Icons
import AdminNavTab from "../../Icons/AdminNavTab/AdminNavTab";
// Images
import ModalIconExtras from "../../../assets/icon_extras.png";
import ImgAlert from "../../../assets/image_alert.png";
import ImgQuestion from "../../../assets/image_question3.png";
import CheckCircle from "../../../assets/image_check_circle.png";
import EditPencil from "../../../assets/edit_pencil.png";
import ChatBubble from "../../../assets/icon_chat_bubble.png";
import Flag from "../../../assets/icon_flag.png";
import Divider from "../../../assets/arrow_right_divider.png";
import History from "../../../assets/icon_history.png";
import Resume from "../../../assets/icon_resume.png";
import AlertIcon from "../../../assets/ALERT.svg";
// Constants
import * as ADMIN_CONST from "../../../constants/adminPage";
import * as BUTTON_CONST from "../../../constants/buttons";
import * as STATUS_CONST from "../../../constants/status";
// Styles
import "./AdminDashboardDetail.css";
// Utils
import { useAuth } from "../../../utils/authenticate";
import {
  formattedDate,
  formatDateToStringYYYYMMDDHHMMSS,
} from "../../../utils/formatDate";
import {
  getMessagesForStepper,
  addMessage,
  markAsRead,
} from "../../../utils/messaging";
import { renderComponentBaseOnType } from "../../../utils/componentForm";

const getInitialsForAvatar = (initials, initialsUserLogged) => {
  return (
    <div className="chat__avatar-content">
      <span
        className={`chat__avatar-circle ${
          initials === initialsUserLogged
            ? "chat__avatar_eqFrom"
            : "chat__avatar_neqFrom"
        }`}
      >
        {initials}
      </span>
    </div>
  );
};

const getStatsOrganizationCard = (
  statsData,
  title,
  icon,
  value,
  componentJSX
) => {
  return (
    <>
      {statsData ? (
        <div className="admin__wrapper-cards">
          <div className="admin__header-panel">
            <div className="admin__header-card">
              <div className="admin__header-wrapperC">
                <div className="admin__header-cardTop">
                  <div className="admin__header-cardTop">
                    <div className="admin__hCardTop-text">{title}</div>
                    {icon && (
                      <img
                        className="admin__hCardTop-icon"
                        src={icon}
                        alt={title}
                      />
                    )}
                  </div>
                </div>
                {title === ADMIN_CONST.ORG_CURRENT_STATUS ? (
                  <div className="admin__header_cardBottomC">
                    {componentJSX}
                  </div>
                ) : (
                  <div className={`admin__header-cardBottom`}>
                    {value?.includes("@") ? (
                      <a className="admin__contact" href={`mailto:${value}`}>
                        {value}
                      </a>
                    ) : (
                      value
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ alignSelf: "center" }}>
          <Loader />
        </div>
      )}
    </>
  );
};

const AdminDashboardDetail = ({ flowId }) => {
  const {
    user,
    getOnboardingListFromAdmin,
    changeOnboardingStatus,
    changeOnboardingSectionItems,
  } = useAuth();

  const navigate = useNavigate();

  const [isError, setIsError] = useState({ active: false, error: "" });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const refTopBar = useRef(null);
  const refModalContainer = useRef(null);
  const refBubble = useRef([]);
  const messageListRef = useRef(null);

  const asideContext = useContext(AsideContext);
  const { adminDashState, dispatchAdminDashboardAction } = useContext(
    AdminDashboardContext
  );

  const changeTabSelected = async (index, stepName) => {
    try {
      dispatchAdminDashboardAction({
        type: actionTypes.SET_TAB_SELECTED,
        payload: {
          index,
          stepName,
        },
      });
    } catch (err) {
      setIsError({
        active: true,
        error: `Failed to get Messages for Stepper: ${err?.cause}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
  };

  const onChangeOrganizationStatus = (value) => {
    dispatchAdminDashboardAction({
      type: actionTypes.SHOW_MODAL_UPD_SECTION,
      payload: { active: true, value },
    });
  };

  const onChangeSectionStatus = (value, indexSection) => {
    dispatchAdminDashboardAction({
      type: actionTypes.SHOW_MODAL_UPD_STATUS_SECTION,
      payload: { active: true, value, indexSection },
    });
  };

  const handleUpdateStatusOrganization = async () => {
    setIsButtonDisabled(true);
    try {
      dispatchAdminDashboardAction({
        type: actionTypes.CANCEL_EDIT_SECTION,
      });
      await changeOnboardingStatus(flowId, {
        status: adminDashState?.showModalUpdateStatusOrg?.value,
      });
      dispatchAdminDashboardAction({
        type: actionTypes.UPD_STATUS_ORG,
        payload: { status: adminDashState?.showModalUpdateStatusOrg?.value },
      });
    } catch (err) {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 500);
      dispatchAdminDashboardAction({
        type: actionTypes.SHOW_MODAL_UPD_SECTION,
        payload: { active: false, value: null },
      });
      setIsError({
        active: true,
        error: `Failed to handleUpdateStatusOrganization: ${err?.cause}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 500);
  };

  const handleUpdateStepperStatus = async () => {
    setIsButtonDisabled(true);
    try {
      const result = await changeOnboardingStatus(flowId, {
        status: STATUS_CONST.STATUS_APPROVED,
        stepperIndex: adminDashState?.tabSelected?.index,
      });
      const data = await result.json();
      dispatchAdminDashboardAction({
        type: actionTypes.UPD_ONBOARDING_STEP,
        payload: {
          stepperStatus: data?.stepper_status,
          flowStatus: data?.flow_status,
        },
      });
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 500);
      setTimeout(() => {
        document
          .getElementsByClassName("admin__navTab-sectionNameActive")[0]
          ?.scrollIntoView({ behavior: "auto", block: "center" });
      }, 10);
    } catch (err) {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 500);
      dispatchAdminDashboardAction({
        type: actionTypes.SHOW_MODAL_UPD_STEP,
        payload: { active: false, value: null },
      });
      setIsError({
        active: true,
        error: `Failed to handleUpdateStepperStatus: ${err?.cause}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
  };

  const handleUpdateStatusSection = async () => {
    setIsButtonDisabled(true);
    try {
      dispatchAdminDashboardAction({
        type: actionTypes.CANCEL_EDIT_SECTION,
      });
      const result = await changeOnboardingStatus(flowId, {
        status: adminDashState?.showModalUpdateStatusSection?.value,
        stepperIndex: adminDashState?.tabSelected?.index,
        sectionIndex:
          adminDashState?.showModalUpdateStatusSection?.indexSection,
      });
      const data = await result.json();
      dispatchAdminDashboardAction({
        type: actionTypes.UPD_ONBOARDING_SECTION_STATUS,
        payload: {
          stepperStatus: data?.stepper_status,
          flowStatus: data?.flow_status,
        },
      });
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 500);
      setTimeout(() => {
        document
          .getElementById(
            `section-${adminDashState?.showModalUpdateStatusSection?.indexSection}`
          )
          ?.scrollIntoView({ behavior: "auto", block: "center" });
      }, 10);
    } catch (err) {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 500);
      dispatchAdminDashboardAction({
        type: actionTypes.SHOW_MODAL_UPD_STATUS_SECTION,
        payload: { active: false, value: null, indexSection: null },
      });
      setIsError({
        active: true,
        error: `Failed to handleUpdateStatusSection: ${err?.cause}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
  };

  const handleUpdateFieldsItems = async () => {
    setIsButtonDisabled(true);
    const result = await changeOnboardingSectionItems(flowId, {
      sectionItems:
        adminDashState?.onboardingIdData?.onboardingFlow?.stepper[
          adminDashState?.tabSelected?.index
        ].stepSections[adminDashState?.sectionEditSelected].sectionItems,
      stepperIndex: adminDashState?.tabSelected?.index,
      sectionIndex: adminDashState?.sectionEditSelected,
    });
    dispatchAdminDashboardAction({
      type: actionTypes.SHOW_MODAL_UPD_FIELD,
      payload: { active: false, value: null },
    });
    if (result.ok) {
      dispatchAdminDashboardAction({
        type: actionTypes.UPD_ONBOARDING_FLOW_FIELD,
        payload: { email: user?.email },
      });
    }
    dispatchAdminDashboardAction({
      type: actionTypes.SET_SECTION_EDIT_SELECTED,
      payload: { sectionEditSelected: null },
    });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 500);
  };

  const updateStringNestedProps = async (
    keySection,
    keyItem,
    new_value,
    validation,
    updateSectionWithoutValidation = false
  ) => {
    dispatchAdminDashboardAction({
      type: actionTypes.UPD_ONBOARDING_FLOW_FIELD_VIEW,
      payload: { keySection, keyItem, new_value, validation },
    });
    if (updateSectionWithoutValidation) {
      try {
        adminDashState.onboardingIdData.onboardingFlow.stepper[
          adminDashState?.tabSelected?.index
        ].stepSections[keySection].sectionItems[keyItem].value = new_value;
        await changeOnboardingSectionItems(flowId, {
          sectionItems:
            adminDashState?.onboardingIdData?.onboardingFlow?.stepper[
              adminDashState?.tabSelected?.index
            ].stepSections[keySection].sectionItems,
          stepperIndex: adminDashState?.tabSelected?.index,
          sectionIndex: keySection,
        });
      } catch (error) {
        console.log("Error updating DocuSign status: ", error);
      }
    }
  };

  const onClickOnChatModal = async (stepperIndex, indexSection) => {
    dispatchAdminDashboardAction({
      type: actionTypes.SHOW_MODAL_CHAT,
      payload: {
        stepperChat: adminDashState?.tabSelected.index,
        sectionChat: indexSection,
        email: user?.email,
      },
    });
    try {
      if (
        adminDashState?.msgList?.stepSections?.length > 0 &&
        adminDashState?.msgList?.stepSections[indexSection]?.messages?.some(
          (msg) => !msg.readBy?.includes(user?.email)
        ) &&
        adminDashState?.showModalChat?.active === false
      ) {
        await markAsRead(user?.token, flowId, stepperIndex, indexSection);
      }
    } catch (err) {
      setIsError({
        active: true,
        error: `Failed to mark msg as read: ${err?.cause}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
  };

  const addNewCommentOnChat = async (stepperIndex, sectionIndex, message) => {
    if (message?.trim()?.length === 0) return;
    try {
      setIsButtonDisabled(true);
      const resultAddMsg = await addMessage(
        user?.token,
        flowId,
        stepperIndex,
        sectionIndex,
        message
      );
      dispatchAdminDashboardAction({
        type: actionTypes.CHAT_COMMET,
        payload: {
          newMsg: resultAddMsg.task,
        },
      });
      setIsButtonDisabled(false);
      setTimeout(() => {
        const lengthMsgs =
          resultAddMsg?.task?.stepSections[sectionIndex]?.messages?.length - 1;
        document
          .getElementById(`chat-msg-${lengthMsgs}`)
          ?.scrollIntoView({ behavior: "auto", block: "center" });
      }, 100);
    } catch (err) {
      setIsError({
        active: true,
        error: `Failed to add new Message: ${err?.cause}`,
        status: err?.status,
        statusText: err?.statusText,
      });
    }
  };

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        refModalContainer?.current &&
        refBubble?.current[adminDashState?.showModalChat?.sectionChat] &&
        !refModalContainer.current.contains(event.target) &&
        !refBubble.current[
          adminDashState?.showModalChat?.sectionChat
        ]?.current?.contains(event.target)
      ) {
        dispatchAdminDashboardAction({
          type: actionTypes.SHOW_MODAL_CHAT,
          payload: {
            active: false,
            email: user?.email,
          },
        });
      }
    },
    [
      refModalContainer,
      dispatchAdminDashboardAction,
      user?.email,
      adminDashState?.showModalChat?.sectionChat,
    ]
  );

  const handleCancelSaveYourChanges = async () => {
    setIsButtonDisabled(true);
    dispatchAdminDashboardAction({
      type: actionTypes.CANCEL_EXIT_WITHOUT_SAVING,
    });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 500);
  };

  const handleDontSaveYourChangesAndContinue = async () => {
    return dispatchAdminDashboardAction({
      type: actionTypes.SET_TAB_SELECTED,
      payload: {
        index: adminDashState?.showModalExitWithoutSaving?.value,
        viewMode: true,
      },
    });
  };

  const handleSaveYourChangesAndContinue = async () => {
    setIsButtonDisabled(true);
    await handleUpdateFieldsItems();
    await changeTabSelected(adminDashState?.showModalExitWithoutSaving?.value);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 500);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    if (
      adminDashState?.showModalChat?.active &&
      messageListRef &&
      messageListRef?.current
    ) {
      const messageList = messageListRef?.current;
      messageList.scrollTop = messageList?.scrollHeight;
    }
  }, [adminDashState?.showModalChat?.active]);

  useEffect(() => {
    const onClickViewActivity = async (flowId) => {
      let msgerror = "getOnboardingListFromAdmin";
      try {
        const onboardingData = await getOnboardingListFromAdmin(flowId);
        dispatchAdminDashboardAction({
          type: actionTypes.VIEW_ORG_ACTIVITY,
          payload: {
            onboardingData,
            orgName:
              onboardingData?.onboardingFlow?.stepper[0]?.stepSections[0]
                ?.sectionItems[0]?.value || ADMIN_CONST.UNNAMED_ORGANIZATION,
            orgEmail: onboardingData?.email,
          },
        });
        if (
          adminDashState?.onboardingIdDataLoading === false &&
          onboardingData.status !== STATUS_CONST.STATUS_NEW_OPPORTUNITY
        ) {
          msgerror = "getMessagesForStepper";
          const msgList = await getMessagesForStepper(user?.token, flowId, 0);
          dispatchAdminDashboardAction({
            type: actionTypes.SET_CURRENT_MSG_LIST,
            payload: {
              msgList: msgList?.task,
            },
          });
        }
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to ${msgerror}: ${err?.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    };
    onClickViewActivity(flowId);
    if (refTopBar) {
      refTopBar.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    const retrieveMsgsForStepper = async () => {
      try {
        const msgList = await getMessagesForStepper(
          user?.token,
          flowId,
          adminDashState?.tabSelected?.index
        );
        dispatchAdminDashboardAction({
          type: actionTypes.SET_CURRENT_MSG_LIST,
          payload: {
            msgList: msgList?.task,
          },
        });
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to get Messages for Stepper: ${err?.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    };
    if (
      adminDashState?.onboardingIdDataLoading === false &&
      adminDashState?.onboardingIdData?.status !==
        STATUS_CONST.STATUS_NEW_OPPORTUNITY &&
      adminDashState?.tabSelected?.index > -1
    ) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "auto",
        });
      }, 0);
      retrieveMsgsForStepper();
      dispatchAdminDashboardAction({
        type: actionTypes.CANCEL_EDIT_SECTION,
      });
    }
  }, [
    adminDashState?.onboardingIdData?.status,
    adminDashState?.onboardingIdDataLoading,
    adminDashState?.tabSelected?.index,
    dispatchAdminDashboardAction,
    flowId,
    user?.token,
  ]);

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  return (
    <div
      className={`${
        asideContext.asideOpen ? "admin__content-expanded" : "admin__content"
      }`}
      ref={refTopBar}
    >
      <div className="admin__topBar">
        <div className="admin__topBar-title">
          <span
            className="admin__topBar-title"
            onClick={() =>
              navigate(
                `/admin/dashboard${
                  adminDashState?.inputSearchNav?.trim()?.length > 0
                    ? `/?search=${encodeURIComponent(
                        adminDashState?.inputSearchNav
                      )}`
                    : ""
                }`
              )
            }
          >
            {ADMIN_CONST.TOPBAR_DASHBOARD_TITLE}
          </span>
          <>
            <img src={Divider} alt={"divider"} />
            <span className="admin__selected-onboardingFlow">
              {adminDashState?.onboardingIdDataLoading === false &&
                adminDashState?.organizationContact?.orgName}
            </span>
          </>
        </div>
        <div className="admin__topBar-right">
          <Avatar typeUser={"admin"} />
        </div>
      </div>
      <div className="admin__cards-org">
        {getStatsOrganizationCard(
          adminDashState?.onboardingIdDataLoading === false &&
            adminDashState?.onboardingIdData?.onboardingFlow &&
            Object.keys(adminDashState?.onboardingIdData?.onboardingFlow)
              .length !== 0,
          ADMIN_CONST.ORG_ONBOARDING_SENT,
          Flag,
          formattedDate(adminDashState?.onboardingIdData?.createdAt)
        )}
        {getStatsOrganizationCard(
          adminDashState?.onboardingIdDataLoading === false &&
            adminDashState?.onboardingIdData?.onboardingFlow &&
            Object.keys(adminDashState?.onboardingIdData?.onboardingFlow)
              .length !== 0,
          ADMIN_CONST.ORG_ONBOARDING_LAST_UPD,
          History,
          formattedDate(
            adminDashState?.onboardingIdData?.onboardingFlow?.lastUpdate
          )
        )}
        {getStatsOrganizationCard(
          adminDashState?.onboardingIdDataLoading === false &&
            adminDashState?.onboardingIdData?.onboardingFlow &&
            Object.keys(adminDashState?.onboardingIdData?.onboardingFlow)
              .length !== 0,
          ADMIN_CONST.ORG_LEAGUE_CONTACT,
          Resume,
          adminDashState?.onboardingIdData?.user?.email
        )}
        {getStatsOrganizationCard(
          adminDashState?.onboardingIdDataLoading === false &&
            adminDashState?.onboardingIdData?.onboardingFlow &&
            Object.keys(adminDashState?.onboardingIdData?.onboardingFlow)
              .length !== 0,
          ADMIN_CONST.ORG_CURRENT_STATUS,
          "",
          "",
          adminDashState?.onboardingIdData?.status ===
            STATUS_CONST.STATUS_NEW_OPPORTUNITY ? (
            <span className="statusOnboarding statusOther">
              {STATUS_CONST.STATUS_NEW_OPPORTUNITY.toLocaleUpperCase()}
            </span>
          ) : (
            <StatusDropDown
              onChange={(newValue) => onChangeOrganizationStatus(newValue)}
              options={STATUS_CONST.STATUS_ORGANIZATION_SECTIONS}
              value={adminDashState?.onboardingIdData?.status}
            />
          )
        )}
      </div>
      {adminDashState?.onboardingIdDataLoading === false &&
        adminDashState?.onboardingIdData?.status ===
          STATUS_CONST.STATUS_NEW_OPPORTUNITY && (
          <div style={{ width: "100%" }}>
            <Modal>
              <div className="modal__icon-img">
                <img src={ModalIconExtras} alt="Modal Icon" />
              </div>
              <div className="modal__wrapper-title">
                <span className="modal__title">
                  {ADMIN_CONST.ORG_NO_INFORMATION}
                </span>
              </div>
              <span className="modal__description-text">
                {ADMIN_CONST.ORG_NO_INFORMATION_DESC}
              </span>
              <div>
                <span className="modal__description-text">
                  {ADMIN_CONST.CONTACT_ORGANIZATION}
                </span>
                <span className="modal__description-text">
                  <a
                    href={`mailto:${adminDashState?.organizationContact?.orgEmail}`}
                  >
                    {adminDashState?.onboardingIdData?.user?.email}
                  </a>
                </span>
              </div>
            </Modal>
          </div>
        )}
      {adminDashState?.onboardingIdDataLoading === false &&
        adminDashState?.onboardingIdData?.status !==
          STATUS_CONST.STATUS_NEW_OPPORTUNITY && (
          <>
            <div className="admin__wrapper-content">
              {adminDashState?.onboardingIdData?.length === 0 ? (
                <Loader />
              ) : (
                <>
                  <nav className="admin__navTab-wrapper">
                    {adminDashState?.onboardingIdData?.onboardingFlow?.stepper?.map(
                      (tab, index) => {
                        return adminDashState?.onboardingIdData?.onboardingFlow
                          ?.stepper.length -
                          1 >
                          index ? (
                          <div
                            key={`${tab.stepName}-${index}`}
                            onClick={() =>
                              changeTabSelected(index, tab.stepName)
                            }
                            className={
                              adminDashState?.tabSelected?.index === index
                                ? "admin__navTab-sectionActive"
                                : "admin__navTab-section"
                            }
                          >
                            <AdminNavTab
                              color={
                                adminDashState?.tabSelected?.index === index
                                  ? "#2468DB"
                                  : "#17181C"
                              }
                              type={tab.icon}
                            />
                            <div
                              className={
                                adminDashState?.tabSelected?.index === index
                                  ? "admin__navTab-sectionNameActive"
                                  : "admin__navTab-sectionName"
                              }
                            >
                              {tab.stepName}
                            </div>
                            {tab?.status === STATUS_CONST.STATUS_APPROVED && (
                              <div>
                                <img
                                  src={CheckCircle}
                                  alt="Check Circle Green"
                                />
                              </div>
                            )}
                          </div>
                        ) : null;
                      }
                    )}
                  </nav>
                  {adminDashState?.onboardingIdData?.onboardingFlow?.stepper[
                    adminDashState?.tabSelected?.index
                  ]?.stepSections?.map((section, indexSection) => {
                    refBubble.current[indexSection] =
                      refBubble.current[indexSection] || React.createRef();
                    return (
                      <div
                        id={`section-${indexSection}`}
                        key={`${section?.sectionTitle}-${indexSection}`}
                        className="admin__stepper-section"
                      >
                        <div className="admin__stepper-sectionContent">
                          <div className="admin__stepper-sectionHeader">
                            <span className="admin__stepper-sectionHeaderT">
                              {section?.sectionTitle}
                            </span>
                            <div className="admin__stepper-sectionHeaderE">
                              <span className="admin__stepper-lastUpdated">
                                {ADMIN_CONST.LAST_UPDATED_BY}
                              </span>
                              <span className="admin__violetText">
                                {section?.lastUpdatedBy}
                              </span>
                              <StatusDropDown
                                classes={"admin__StatusDropDownBox-withM"}
                                onChange={(newValue) =>
                                  onChangeSectionStatus(newValue, indexSection)
                                }
                                options={STATUS_CONST.STATUS_SECTIONS_INITIAL}
                                value={section?.status}
                              />
                              {adminDashState?.sectionEditSelected ===
                              indexSection ? (
                                <>
                                  <Button
                                    classes="active"
                                    className="button__edit-section"
                                    disabled={isButtonDisabled}
                                    onClick={() =>
                                      dispatchAdminDashboardAction({
                                        type: actionTypes.SHOW_MODAL_UPD_FIELD,
                                        payload: {
                                          active: true,
                                          value: null,
                                          indexSection,
                                        },
                                      })
                                    }
                                    title={BUTTON_CONST.BUTTON_SAVE}
                                  />
                                  <Button
                                    classes="secondary"
                                    className="button__edit-section"
                                    onClick={() =>
                                      dispatchAdminDashboardAction({
                                        type: actionTypes.CANCEL_EDIT_SECTION,
                                      })
                                    }
                                    title={BUTTON_CONST.BUTTON_CANCEL}
                                  />
                                </>
                              ) : (
                                <>
                                  <Button
                                    classes="active"
                                    className="button__edit-section"
                                    image={{
                                      src: EditPencil,
                                      alt: "Pencil Edit",
                                    }}
                                    onClick={() =>
                                      dispatchAdminDashboardAction({
                                        type: actionTypes.SET_SECTION_EDIT_SELECTED,
                                        payload: {
                                          sectionEditSelected: indexSection,
                                        },
                                      })
                                    }
                                  />
                                  <div
                                    id={`refBubble-${indexSection}`}
                                    ref={refBubble.current[indexSection]}
                                  >
                                    <div
                                      className={`chat__bubble-wrapper ${
                                        adminDashState?.showModalChat?.active &&
                                        adminDashState?.showModalChat
                                          ?.stepperChat ===
                                          adminDashState?.tabSelected?.index &&
                                        adminDashState?.showModalChat
                                          ?.sectionChat === indexSection
                                          ? "chat__bubble-indexUp"
                                          : "chat__bubble-neutral"
                                      }`}
                                    >
                                      <img
                                        onClick={() => {
                                          onClickOnChatModal(
                                            adminDashState?.tabSelected?.index,
                                            indexSection
                                          );
                                        }}
                                        src={ChatBubble}
                                        alt={`Chat Bubble ${
                                          adminDashState?.showModalChat
                                            ?.active &&
                                          adminDashState?.showModalChat
                                            ?.stepperChat ===
                                            adminDashState?.tabSelected
                                              ?.index &&
                                          adminDashState?.showModalChat
                                            ?.sectionChat === indexSection
                                            ? "chat__bubble-indexUp"
                                            : "chat__bubble-neutral"
                                        }`}
                                      />
                                      {adminDashState?.msgList?.stepSections
                                        ?.length > 0 &&
                                        adminDashState?.msgList?.stepSections[
                                          indexSection
                                        ]?.messages?.some(
                                          (msg) =>
                                            !msg?.readBy?.includes(user?.email)
                                        ) &&
                                        (adminDashState?.showModalChat
                                          ?.active === false ||
                                          (adminDashState?.showModalChat
                                            ?.active &&
                                            adminDashState?.showModalChat
                                              ?.sectionChat !==
                                              indexSection)) && (
                                          <div
                                            className={`chat__bubble_notificationW ${
                                              adminDashState?.showModalChat
                                                ?.active &&
                                              adminDashState?.showModalChat
                                                ?.stepperChat ===
                                                adminDashState?.tabSelected
                                                  ?.index &&
                                              adminDashState?.showModalChat
                                                ?.sectionChat === indexSection
                                                ? "chat__bubble-indexUp"
                                                : "chat__bubble-neutral"
                                            }`}
                                          >
                                            <div className="chat__bubble_notification"></div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  {adminDashState?.showModalChat?.active &&
                                    adminDashState?.showModalChat
                                      ?.stepperChat ===
                                      adminDashState?.tabSelected?.index &&
                                    adminDashState?.showModalChat
                                      ?.sectionChat === indexSection && (
                                      <div className="modal__chat-wrapper">
                                        <div
                                          className="modal__chat"
                                          ref={refModalContainer}
                                        >
                                          <Modal
                                            width={546}
                                            height={333}
                                            classes={"admin__chat-modal"}
                                          >
                                            <div className="modal__header">
                                              <h1 className="modal__header-main">
                                                {ADMIN_CONST.TITLE_CHAT}
                                              </h1>
                                            </div>
                                            <main
                                              className={`admin__chat-main ${
                                                isButtonDisabled
                                                  ? "chat__overflowY-hidden"
                                                  : ""
                                              }`}
                                              ref={messageListRef}
                                            >
                                              {isButtonDisabled ? (
                                                <Loader />
                                              ) : (
                                                adminDashState?.msgList
                                                  ?.stepSections?.length > 0 &&
                                                adminDashState?.msgList?.stepSections[
                                                  indexSection
                                                ]?.messages?.map(
                                                  (message, indexMsg) => {
                                                    return (
                                                      <div
                                                        key={indexMsg}
                                                        id={`chat-msg-${indexMsg}`}
                                                        className="admin__chat-msgs"
                                                      >
                                                        <div className="modal__chat-avatar">
                                                          {getInitialsForAvatar(
                                                            `${message?.from?.firstName[0]?.toLocaleUpperCase()}${message?.from?.lastName[0].toLocaleUpperCase()}`,
                                                            user?.initials
                                                          )}
                                                        </div>
                                                        <div className="chat__content">
                                                          <span className="chat__content-msg">
                                                            {message?.message}
                                                          </span>
                                                          <span className="chat__content-date">
                                                            {`${formatDateToStringYYYYMMDDHHMMSS(
                                                              message?.datetime
                                                            )} - ${
                                                              user?.initials ===
                                                              message?.from?.firstName[0]?.toLocaleUpperCase() +
                                                                message?.from?.lastName[0]?.toLocaleUpperCase()
                                                                ? "You"
                                                                : message?.from
                                                                    .firstName
                                                            } sent a message`}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )
                                              )}
                                            </main>
                                            <footer className="modal__chat-footer">
                                              <div className="modal__chat-inputW">
                                                <div className="modal__chat-avatar">
                                                  {getInitialsForAvatar(
                                                    user?.initials,
                                                    user?.initials
                                                  )}
                                                </div>
                                                <textarea
                                                  disabled={isButtonDisabled}
                                                  type="text"
                                                  className={`modal__chat-textarea`}
                                                  value={
                                                    adminDashState?.msgTemp
                                                  }
                                                  onChange={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    dispatchAdminDashboardAction(
                                                      {
                                                        type: actionTypes.SET_CURRENT_COMMENT,
                                                        payload: {
                                                          msgTemp:
                                                            e.target.value,
                                                        },
                                                      }
                                                    );
                                                  }}
                                                ></textarea>
                                              </div>
                                              <div className="chat__button-commentW">
                                                <Button
                                                  style={{
                                                    visibility: `${
                                                      isButtonDisabled
                                                        ? "hidden"
                                                        : "visible"
                                                    }`,
                                                  }}
                                                  disabled={isButtonDisabled}
                                                  className={
                                                    "chat__button-comment"
                                                  }
                                                  title={
                                                    BUTTON_CONST.BUTTON_COMMENT
                                                  }
                                                  classes="secondary"
                                                  onClick={() =>
                                                    addNewCommentOnChat(
                                                      adminDashState
                                                        ?.tabSelected?.index,
                                                      indexSection,
                                                      adminDashState?.msgTemp
                                                    )
                                                  }
                                                />
                                              </div>
                                            </footer>
                                          </Modal>
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              adminDashState?.onboardingIdData?.onboardingFlow
                                ?.stepper[adminDashState?.tabSelected?.index]
                                .stepName === "Registration Method"
                                ? "stepSection-wrapper-middle"
                                : "admin__stepSection-wrapper"
                            }
                          >
                            {section?.sectionItems?.map(
                              (sectionItem, indexItem) =>
                                renderComponentBaseOnType(
                                  sectionItem,
                                  indexItem,
                                  indexSection,
                                  adminDashState?.sectionEditSelected !==
                                    indexSection,
                                  updateStringNestedProps,
                                  adminDashState?.onboardingIdData
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="admin__dividerSection"></div>
                  <div className="buttonSection">
                    <Button
                      classes={"secondary"}
                      onClick={() =>
                        adminDashState?.tabSelected?.index === 0
                          ? 0
                          : changeTabSelected(
                              adminDashState?.tabSelected?.index - 1,
                              adminDashState?.onboardingIdData?.onboardingFlow
                                ?.stepper[
                                adminDashState?.tabSelected?.index - 1
                              ].stepName
                            )
                      }
                      style={{
                        visibility: `${
                          adminDashState?.tabSelected?.index === 0
                            ? "hidden"
                            : "visible"
                        }`,
                      }}
                      title={BUTTON_CONST.BUTTON_BACK}
                    />
                    {adminDashState?.onboardingIdData?.onboardingFlow?.stepper
                      .length -
                      1 >
                      adminDashState?.tabSelected?.index && (
                      <>
                        <Button
                          classes={
                            adminDashState?.onboardingIdData?.onboardingFlow
                              ?.stepper[adminDashState?.tabSelected?.index]
                              .status === STATUS_CONST.STATUS_APPROVED
                              ? "disabled"
                              : "secondary"
                          }
                          disabled={
                            adminDashState?.onboardingIdData?.onboardingFlow
                              ?.stepper[adminDashState?.tabSelected?.index]
                              .status === STATUS_CONST.STATUS_APPROVED
                          }
                          onClick={() => {
                            dispatchAdminDashboardAction({
                              type: actionTypes.CANCEL_EDIT_SECTION,
                            });
                            dispatchAdminDashboardAction({
                              type: actionTypes.SHOW_MODAL_UPD_STEP,
                              payload: { active: true, value: null },
                            });
                          }}
                          title={`Mark ${adminDashState?.tabSelected?.stepName} As Approved`}
                        />
                        <div className="rightSection">
                          <Button
                            classes={"active"}
                            onClick={() =>
                              changeTabSelected(
                                adminDashState?.tabSelected?.index + 1,
                                adminDashState?.onboardingIdData?.onboardingFlow
                                  .stepper[
                                  adminDashState?.tabSelected?.index + 1
                                ].stepName
                              )
                            }
                            style={{
                              visibility: `${
                                adminDashState?.onboardingIdData?.onboardingFlow
                                  ?.stepper.length -
                                  1 <=
                                adminDashState?.tabSelected?.index + 1
                                  ? "hidden"
                                  : "visible"
                              }`,
                            }}
                            title={BUTTON_CONST.BUTTON_CONTINUE}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {adminDashState?.showModalUpdateStatusSection?.active && (
                <div className="admin__modal-overaly">
                  <Modal classes={"modal_wrapper_confirmation"}>
                    <div>
                      <img src={ImgQuestion} alt="Informative Question" />
                    </div>
                    <div className="modal__wrapper-title">
                      <span className="modal__title">
                        {ADMIN_CONST.ORG_ASK_APPROVE_SECTION}
                      </span>
                    </div>
                    <span className="modal__description-text">
                      {ADMIN_CONST.ORG_ASK_APPROVE_SECTION_DESC}
                    </span>
                    <div className="modal__twoButtons">
                      <Button
                        classes={"secondary"}
                        onClick={() => {
                          dispatchAdminDashboardAction({
                            type: actionTypes.SHOW_MODAL_UPD_STATUS_SECTION,
                            payload: {
                              active: false,
                              value: null,
                              indexSection: null,
                            },
                          });
                        }}
                        title={BUTTON_CONST.BUTTON_CANCEL}
                      />
                      <Button
                        classes={"active"}
                        disabled={isButtonDisabled}
                        onClick={handleUpdateStatusSection}
                        title={BUTTON_CONST.BUTTON_UPDATE}
                      />
                    </div>
                  </Modal>
                </div>
              )}
              {adminDashState?.showModalUpdateField?.active && (
                <div className="admin__modal-overaly">
                  <Modal classes={"modal_wrapper_confirmation"}>
                    <div>
                      <img src={ImgQuestion} alt="Ask question" />
                    </div>
                    <div className="modal__wrapper-title">
                      <span className="modal__title">
                        {ADMIN_CONST.ORG_ASK_APPROVE_FIELD}
                      </span>
                    </div>
                    <span className="modal__description-text">
                      {ADMIN_CONST.ORG_ASK_APPROVE_FIELD_DESC}
                    </span>
                    <div className="modal__twoButtons">
                      <Button
                        classes={"secondary"}
                        onClick={() => {
                          dispatchAdminDashboardAction({
                            type: actionTypes.CANCEL_UPD_FIELD,
                          });
                        }}
                        title={BUTTON_CONST.BUTTON_CANCEL}
                      />
                      <Button
                        classes={"active"}
                        disabled={isButtonDisabled}
                        onClick={handleUpdateFieldsItems}
                        title={BUTTON_CONST.BUTTON_UPDATE}
                      />
                    </div>
                  </Modal>
                </div>
              )}
              {adminDashState?.showModalUpdateStep.active && (
                <div className="admin__modal-overaly">
                  <Modal classes={"modal_wrapper_confirmation"}>
                    <div>
                      <img src={ImgQuestion} alt="Ask Question" />
                    </div>
                    <div className="modal__wrapper-title">
                      <span className="modal__title">
                        {ADMIN_CONST.ORG_ASK_APPROVE_ALL}
                      </span>
                    </div>
                    <span className="modal__description-text">
                      {ADMIN_CONST.ORG_ASK_APPROVE_ALL_DESC}
                    </span>
                    <div className="modal__twoButtons">
                      <Button
                        classes={"secondary"}
                        onClick={() => {
                          dispatchAdminDashboardAction({
                            type: actionTypes.SHOW_MODAL_UPD_STEP,
                            payload: { active: false, value: null },
                          });
                        }}
                        title={BUTTON_CONST.BUTTON_CANCEL}
                      />
                      <Button
                        classes={"active"}
                        disabled={isButtonDisabled}
                        onClick={handleUpdateStepperStatus}
                        title={BUTTON_CONST.BUTTON_UPDATE}
                      />
                    </div>
                  </Modal>
                </div>
              )}
            </div>
          </>
        )}
      {adminDashState?.onboardingIdDataLoading === false &&
        adminDashState?.showModalUpdateStatusOrg?.active && (
          <div className="admin__modal-overaly">
            <Modal classes={"modal_wrapper_confirmation"}>
              <div>
                <img src={ImgAlert} alt="Alert warning" />
              </div>
              <div className="modal__wrapper-title">
                <span className="modal__title">
                  {ADMIN_CONST.ORG_ASK_CHANGE_STATUS}
                </span>
              </div>
              <span className="modal__description-text">
                {ADMIN_CONST.ORG_ASK_CHANGE_STATUS_DESC}
              </span>
              <div className="modal__twoButtons">
                <Button
                  classes={"secondary"}
                  onClick={() => {
                    dispatchAdminDashboardAction({
                      type: actionTypes.SHOW_MODAL_UPD_SECTION,
                      payload: { active: false, value: null },
                    });
                  }}
                  title={BUTTON_CONST.BUTTON_CANCEL}
                />
                <Button
                  classes={"active"}
                  disabled={isButtonDisabled}
                  onClick={handleUpdateStatusOrganization}
                  title={BUTTON_CONST.BUTTON_UPDATE}
                />
              </div>
            </Modal>
          </div>
        )}
      {adminDashState?.onboardingIdDataLoading === false &&
        adminDashState?.showModalExitWithoutSaving?.active && (
          <div className="admin__modal-overaly">
            <Modal classes={"modal_wrapper_confirmation"}>
              <div className="modal__xMark">
                <svg
                  onClick={handleCancelSaveYourChanges}
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z"
                    fill="#0F172A"
                  />
                </svg>
              </div>
              <div>
                <img src={AlertIcon} alt="Alert Question" />
              </div>
              <div className="modal__wrapper-title">
                <span className="modal__title">
                  {ADMIN_CONST.LEAVE_WITHOUT_SAVING}
                </span>
              </div>
              <span className="modal__description-text">
                {ADMIN_CONST.LEAVE_WITHOUT_SAVING_DESC}
              </span>
              <div className="modal__twoButtons">
                <Button
                  classes={"secondary"}
                  disabled={isButtonDisabled}
                  onClick={handleDontSaveYourChangesAndContinue}
                  title={BUTTON_CONST.BUTTON_DONT_SAVE}
                />
                <Button
                  classes={"active"}
                  disabled={isButtonDisabled}
                  onClick={handleSaveYourChangesAndContinue}
                  title={BUTTON_CONST.BUTTON_SAVE_CONTINUE}
                />
              </div>
            </Modal>
          </div>
        )}
    </div>
  );
};

export default AdminDashboardDetail;
