import React from "react";
import PropTypes from "prop-types";
// Constants
import * as FORM_CONST from "../../../constants/formComponents";
// Styles
import "./InputText.css";
import classNames from "classnames";

const InputText = ({
  className = "",
  disabled = false,
  label,
  onChange,
  placeholder = FORM_CONST.PLACEHOLDER_DEFAULT,
  required = false,
  style,
  value,
  wrapperClassName,
  ...additionalProps
}) => {
  const labelClasses = classNames("inputText__label", {
    "admin__disabled-color": disabled,
  });

  const inputTextClasses = classNames("inputText__box", {
    "admin__disabled-color admin__disabled-background user__disabled-background":
      disabled,
  });

  const wrapperTextClasses = classNames(
    "inputText__wrapper-general",
    wrapperClassName
  );

  return (
    <div className={wrapperTextClasses}>
      {label && (
        <label className={labelClasses}>
          {label}
          {required ? "*" : null}
        </label>
      )}
      <input
        className={inputTextClasses}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        style={style}
        type="text"
        value={value ?? ""}
        {...additionalProps}
      />
    </div>
  );
};

InputText.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default InputText;
