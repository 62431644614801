import React from "react";
// Components
import Button from "../Button/Button";
// Constants
import * as STATUS_CONST from "../../constants/status";
// Images
import imageFindError from "../../assets/image_error_find.png";
import arrowRight from "../../assets/arrow_right.png";
// Utils
import { useAuth } from "../../utils/authenticate";
import { getErrorDescription, getErrorTitle } from "../../utils/errorFunctions";
// Constants
import * as BUTTON_CONST from "../../constants/buttons";
// Css
import "./ErrorFallback.css";

const ErrorFallback = ({ error }) => {
  const { user } = useAuth();
  const currentURL = window.location.href;
  const isAdminPage = currentURL.includes("/admin");

  const parseJson = (jsonString) => {
    let parsedJson;
    try {
      parsedJson = JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing Json String. Is not a valid format ", error);
      parsedJson = jsonString;
    }
    return parsedJson;
  };

  const { message, status } = error?.message
    ? parseJson(error?.message)
    : { message: "Page Not Found", status: 404, statusText: "Page Not Found" };

  const onClickGoToHomePage = () => {
    if (user?.token) {
      if (user?.admin) {
        if (user?.userId) {
          window.location.href = `/admin/dashboard`;
        } else {
          window.location.href = "/admin/login";
        }
      } else {
        const hasOneFlow = user?.flows?.length < 2;
        if (hasOneFlow) {
          if (user?.onboardingFlowID) {
            if (user?.status === STATUS_CONST.STATUS_NEW_OPPORTUNITY) {
              window.location.href = `/flow/${user?.onboardingFlowID}`;
            } else {
              window.location.href = `/flows/${user?.userId}`;
            }
          } else {
            if (isAdminPage) {
              window.location.href = "/admin/login";
            } else {
              window.location.href = "/login";
            }
          }
        } else {
          window.location.href = `/flows/${user?.userId}`;
        }
      }
    } else {
      if (user?.admin) {
        window.location.href = "/admin/login";
      } else {
        if (isAdminPage) {
          window.location.href = "/admin/login";
        } else {
          window.location.href = "/login";
        }
      }
    }
  };

  return (
    <div className="error__container">
      <div className="error__left-wrapper">
        <div className="error__left-error">
          <span className="error__left-statusCode">{status ?? 400}</span>
          <span className="error__left-title">{getErrorTitle(status)}</span>
          <span className="error__left-description">
            {getErrorDescription(status)}
          </span>
          <span className="error__left-description error__last">{message}</span>
          <Button
            classes={"secondary"}
            image={{
              src: arrowRight,
              alt: "right arrow",
              className: "button__flex",
            }}
            onClick={() => onClickGoToHomePage()}
            title={BUTTON_CONST.BUTTON_GO_HOMEPAGE}
          />
        </div>
      </div>
      <div className="error_rigth-wrapper">
        <img src={imageFindError} alt="Error" />
      </div>
    </div>
  );
};

export default ErrorFallback;
