import { getBaseURL } from "./getBaseURL";

const SERVER_URL = getBaseURL();

export const getDocuSignFile = async ({ token, flowId, docuSignId }) => {
  try {
    const res = await fetch(
      `${SERVER_URL}/onboarding/${flowId}/integration/${docuSignId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }
    );
    if (!res.ok) {
      const errorMsg = await res.json();
      throw Object.assign(
        new Error(
          `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
        ),
        {
          status: res.status,
          statusText: res.statusText,
          cause: errorMsg.error,
        }
      );
    }
    return await res.json();
  } catch (error) {
    return Promise.reject(error);
  }
};
