import { getBaseURL } from "./getBaseURL";

const SERVER_URL = getBaseURL();

export const getUploadedFile = async (
  onboardingFlowID,
  token,
  keyFileUpload
) => {
  if (onboardingFlowID && keyFileUpload) {
    try {
      const res = await fetch(
        `${SERVER_URL}/onboarding/${onboardingFlowID}/upload/${keyFileUpload}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            "auth-token": token,
          },
        }
      );
      if (!res.ok) {
        const errorMsg = await res.json();
        throw Object.assign(
          new Error(
            `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
          ),
          {
            status: res.status,
            statusText: res.statusText,
            cause: errorMsg.error,
          }
        );
      }
      return await res.json();
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    console.error(" *** getUploadedFile INCOMPLETE INPUT DATA");
  }
};

export const postUploadedFile = async (
  flowId,
  token,
  fileInput,
  keyFileUpload
) => {
  const SERVER_URL = getBaseURL();
  try {
    const formData = new FormData();
    formData.append("rcxupload", fileInput, fileInput.name);
    formData.append("key", keyFileUpload);
    const res = await fetch(`${SERVER_URL}/onboarding/${flowId}/upload`, {
      method: "POST",
      headers: {
        "auth-token": token,
      },
      body: formData,
    });
    if (!res.ok) {
      const errorMsg = await res.json();
      throw Object.assign(
        new Error(
          `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
        ),
        {
          status: res.status,
          statusText: res.statusText,
          cause: errorMsg.error,
        }
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postUploadedConfigFile = async (
  leadType,
  token,
  fileInput,
  image,
  index
) => {
  const SERVER_URL = getBaseURL();
  try {
    const formData = new FormData();
    formData.append("rcxupload", fileInput, fileInput.name);
    formData.append("image", image);
    if (index >= 0) {
      formData.append("index", index);
    }
    const res = await fetch(`${SERVER_URL}/admin/config/${leadType}/image`, {
      method: "POST",
      headers: {
        "auth-token": token,
      },
      body: formData,
    });
    if (!res.ok) {
      const errorMsg = await res.json();
      throw Object.assign(
        new Error(
          `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
        ),
        {
          status: res.status,
          statusText: res.statusText,
          cause: errorMsg.error,
        }
      );
    }
    const data = await res.json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
