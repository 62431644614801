import { useEffect, useState } from "react";
// Utils
import { getBaseURL } from "../utils/getBaseURL";
import { useAuth } from "../utils/authenticate";

const SERVER_URL = getBaseURL();

const fetchTopBarConfiguration = async (leadType, userToken) => {
  try {
    const response = await fetch(
      `${SERVER_URL}/onboarding/config/${leadType}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "auth-token": userToken,
        },
      }
    );
    if (!response.ok) {
      const errorMsg = await response.json();
      throw Object.assign(
        new Error(
          `${response?.statusText} : ${errorMsg?.error}. Status: ${response?.status}`
        ),
        {
          status: response?.status,
          statusText: response?.statusText,
          cause: errorMsg?.error,
        }
      );
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("ERROR Loading TopBar Configuration : ", err);
    return null;
  }
};

export const useGetTopBarConfiguration = (leadType) => {
  const { user } = useAuth();
  const [configuration, setConfiguration] = useState(null);

  useEffect(() => {
    if (!leadType) return;
    fetchTopBarConfiguration(leadType, user?.token).then((data) => {
      setConfiguration(data);
    });
  }, [leadType, user?.token]);

  return configuration;
};
