import "./style.css";

export const Stepper = ({ steps, indexStepper, setIndexStepper }) => {
  return (
    <div key={indexStepper} className="wrapper-stepper">
      <div className="c-stepper">
        {steps?.map((item, key) => {
          return (
            <li
              className={`c-stepper__item   ${
                key === indexStepper
                  ? "c-stepper__active"
                  : key < indexStepper
                  ? "c-stepper__done"
                  : ""
              }`}
              key={key}
              onClick={() => setIndexStepper(key)}
            >
              <h3 className="c-stepper__title">{item}</h3>
            </li>
          );
        })}
      </div>
    </div>
  );
};
