import React, { useCallback } from "react";
import { Link } from "react-router-dom";
// Components
import NavigationItemLink from "./NavigationItemLink";
// Utils
import { useAuth } from "../../utils/authenticate";
import { getIconForLink } from "../../utils/getIconForTopBar";
import { useGetTopBarConfiguration } from "../../hooks/useGetTopBarConfiguration";

const TopHeaderLinks = ({ leadType, showMyFlows }) => {
  const { user } = useAuth();
  const configTopHeader = useGetTopBarConfiguration(leadType);

  const topBarConfiguration = useCallback(() => {
    if (Object.keys(configTopHeader).length === 0) {
      return;
    }
    const {
      conf: { top_bar },
    } = configTopHeader;

    return (
      <>
        {top_bar?.items?.map((item) => (
          <NavigationItemLink key={item?._id} item={item} />
        ))}
      </>
    );
  }, [configTopHeader]);

  const getTopBarSection = () => {
    if (!showMyFlows || user?.flows?.length <= 1) {
      return configTopHeader && topBarConfiguration();
    }

    return (
      <>
        <div key={"myFlows"} className="topbar_sections_item">
          <Link className="admin__topBar-title" to={`/flows/${user?.userId}`}>
            <div>{getIconForLink("MyFlows")}</div>
            <span className="topbar_text">{"My Flows"}</span>
          </Link>
        </div>
        {configTopHeader && topBarConfiguration()}
      </>
    );
  };

  return <div className="topbar_sections">{getTopBarSection()}</div>;
};

export default TopHeaderLinks;
