import React, { useEffect, useState, useRef } from "react";
// Utils
import {
  getUploadedFile,
  postUploadedFile,
  postUploadedConfigFile,
} from "../../../utils/uploadedFiles";
import { useAuth } from "../../../utils/authenticate";
// Images
import logoUploadFile from "../../../assets/logo_uploadFile.png";
// Components
import Loader from "../../Loader/Loader";
// Styles
import "./UploadFile.css";

const extensionRegex = /\.(\w+)(?=\?|$)/;
const imageExtensions = ["png", "jpeg", "svg", "jpg"];

const isImageFile = (file) => {
  const match = extensionRegex.exec(file?.url);
  const extension = match && match[1] ? match[1].toLowerCase() : "";
  return imageExtensions?.includes(extension?.toLowerCase());
};

const UploadFile = ({
  itemFile,
  isFileUploaded = false,
  paramConfig,
  onChange,
  disabled,
  id,
}) => {
  const { user } = useAuth();
  const [dragActive, setDragActive] = useState(false);
  const [inputFile, setInputFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  async function handleFile(file) {
    setIsLoading(true);
    try {
      const { name, size, type } = file[0];
      if (paramConfig?.active) {
        const data = await postUploadedConfigFile(
          paramConfig?.leadType,
          user?.token,
          file[0],
          paramConfig?.image,
          paramConfig?.index
        );
        setInputFile({ name, size, type, url: data.url });
      } else {
        const data = await postUploadedFile(
          user?.onboardingFlowID,
          user?.token,
          file[0],
          itemFile?.keyUpload
        );
        setInputFile({ name, size, type, url: data.url });
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error Upload File: ", error?.cause);
      setIsLoading(false);
    }
  }

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange();
      handleFile(e.dataTransfer.files);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files[0]) {
      onChange();
      handleFile(e.target.files);
    }
  };

  if (!isFileUploaded && inputFile?.name?.length > 0) {
    setInputFile(null);
  }

  useEffect(() => {
    const fetchUploadedFile = async () => {
      const fileData = await getUploadedFile(
        user?.onboardingFlowID,
        user?.token,
        itemFile?.keyUpload
      );
      return fileData;
    };
    if (isFileUploaded) {
      if (paramConfig?.active) {
        setInputFile({
          name: paramConfig?.originalname,
          size: paramConfig?.size,
          url: paramConfig?.url,
        });
      } else {
        fetchUploadedFile()
          .then((e) =>
            setInputFile({
              name: e?.originalfilename,
              size: e?.size,
              url: e?.url,
            })
          )
          .catch((e) => console.log("Error:", e));
      }
    }
  }, [
    isFileUploaded,
    itemFile?.keyUpload,
    paramConfig?.active,
    paramConfig?.originalfilename,
    paramConfig?.originalname,
    paramConfig?.size,
    paramConfig?.url,
    user?.onboardingFlowID,
    user?.token,
  ]);

  return (
    <>
      <input
        className="input-file-upload"
        disabled={disabled}
        ref={inputRef}
        accept={itemFile?.acceptFiles?.toString()}
        type="file"
        id={`input-file-upload-${id ?? 0}`}
        multiple={false}
        onChange={!disabled ? handleChange : undefined}
      />
      <div
        className={`${
          isFileUploaded
            ? disabled
              ? "uploadFile-card-active active-disabled"
              : "uploadFile-card-active"
            : disabled
            ? "uploadFile-card card-disabled"
            : "uploadFile-card"
        }`}
        onDragEnter={!disabled ? handleDrag : undefined}
      >
        <label
          disabled={disabled}
          id="label-file-upload"
          htmlFor={`input-file-upload-${id ?? 0}`}
          className={
            dragActive ? "drag-active" : disabled ? "input__file-disabled" : ""
          }
        >
          <div className="uploadFile-header">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <img
                  className="uploadFile-logo"
                  src={
                    isFileUploaded && inputFile && isImageFile(inputFile)
                      ? inputFile?.url
                      : logoUploadFile
                  }
                  alt="File Uploaded"
                />
              </>
            )}
          </div>
          {isFileUploaded && inputFile ? (
            <div className="uploadFile-text">
              <span className="upload__descriptionFile">{inputFile?.name}</span>
              <div className="uploadFile__container-anchor">
                {!disabled && (
                  <>
                    <span className="uploadFile-anchor">
                      <span disabled className={"uploadFile-underline_blue"}>
                        Click here
                      </span>
                    </span>
                    <span>{` to replace file`}</span>
                  </>
                )}
                {inputFile?.url && (
                  <>
                    <a
                      className="uploadFile-anchor"
                      href={inputFile?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={inputFile?.name}
                    >
                      {!disabled && <span>{` or `}</span>}
                      <span className="uploadFile-anchor">
                        <span disabled className="uploadFile-underline_blue">
                          {disabled ? "Click here" : "here"}
                        </span>
                      </span>
                    </a>
                    <span>{` to download file`}</span>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="uploadFile-text">
              <span className="titleCard">{itemFile?.titleCard}</span>
              <span className="descriptionCard">
                {itemFile?.descriptionCard}
              </span>
            </div>
          )}
        </label>
        {!disabled && dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
    </>
  );
};

export default UploadFile;
