import React from "react";
// Components
import Checkbox from "../components/FormComponents/Checkbox/Checkbox";
import CombineCard from "../components/FormComponents/CombineCard/CombineCard";
import DocuSign from "../components/FormComponents/DocuSign/DocuSign";
import Dropdown from "../components/FormComponents/Dropdown/Dropdown";
import InputDate from "../components/FormComponents/InputDate/InputDate";
import InputText from "../components/FormComponents/InputText/InputText";
import MultiCheckbox from "../components/FormComponents/MultiCheckbox/MultiCheckbox";
import Radio from "../components/FormComponents/Radio/Radio";
import TableDynamic from "../components/FormComponents/TableDynamic/TableDynamic";
import TextArea from "../components/FormComponents/TextArea/TextArea";
import UploadFile from "../components/FormComponents/UploadFile/UploadFile";
// Constans
import * as FORM_CONST from "../constants/formComponents";

export function renderComponentBaseOnType(
  sectionItem,
  indexItem,
  indexSection,
  conditionComponentDisabled,
  onChange,
  ...props
) {
  if (!sectionItem) return <></>;
  switch (sectionItem?.inputType) {
    case FORM_CONST.COMPONENT_TYPE_STRING:
      return (
        <InputText
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          label={sectionItem?.labelName}
          onChange={(event) =>
            onChange(
              indexSection,
              indexItem,
              event?.target?.value,
              sectionItem?.validation
            )
          }
          placeholder={sectionItem?.placeholder}
          required={sectionItem?.required}
          value={sectionItem?.value}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_TEXTAREA:
      return (
        <TextArea
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          label={sectionItem?.labelName}
          onChange={(event) =>
            onChange(
              indexSection,
              indexItem,
              event?.target?.value,
              sectionItem?.validation
            )
          }
          placeholder={sectionItem?.placeholder}
          required={sectionItem?.required}
          value={sectionItem?.value}
          wrapperClassName={"textarea__wrapper"}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_DROPDOWN:
      return (
        <Dropdown
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          label={sectionItem?.labelName}
          onChange={(event) =>
            onChange(
              indexSection,
              indexItem,
              event?.target?.value,
              sectionItem?.validation
            )
          }
          options={sectionItem?.options}
          required={sectionItem?.required}
          value={sectionItem?.value}
          wrapperClassName={"stringInput"}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_DATE:
      return (
        <InputDate
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          label={sectionItem?.labelName}
          onChange={(event) =>
            onChange(
              indexSection,
              indexItem,
              event?.target?.value,
              sectionItem?.validation
            )
          }
          placeholder={sectionItem?.placeholder}
          required={sectionItem?.required}
          value={sectionItem?.value}
          wrapperClassName={"stringInput"}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_CHECKBOX:
      return (
        <Checkbox
          checked={sectionItem?.value}
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          label={sectionItem?.labelName}
          onChange={(newValue) => {
            onChange(
              indexSection,
              indexItem,
              !newValue,
              sectionItem?.validation
            );
          }}
          required={sectionItem?.required}
          value={sectionItem?.value}
          wrapperClassName={"checkbox__wrapper"}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_RADIO:
      return (
        <Radio
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          label={sectionItem?.labelName}
          options={sectionItem?.options}
          onChange={(newValue) => {
            onChange(
              indexSection,
              indexItem,
              newValue,
              sectionItem?.validation
            );
          }}
          placeholder={sectionItem?.placeholder}
          required={sectionItem?.required}
          value={sectionItem?.value}
          wrapperClassName={"radio__wrapper"}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_LARGE_CARD:
      return (
        <CombineCard
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          label={sectionItem?.labelName}
          onChange={(type, value) =>
            onChange(
              indexSection,
              indexItem,
              type === "uploadFile" ? "File" : value,
              sectionItem?.validation
            )
          }
          options={sectionItem?.options}
          required={sectionItem?.required}
          value={sectionItem?.value}
          wrapperClassName={"combineCard__wrapper"}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_MULTI_CHECHBOX:
      return (
        <MultiCheckbox
          checked={sectionItem?.value?.selectedOptions}
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          label={sectionItem?.labelName}
          onChange={(newValue) => {
            onChange(
              indexSection,
              indexItem,
              !sectionItem?.value?.selectedOptions?.includes(newValue)
                ? sectionItem?.value?.selectedOptions.length > 0
                  ? {
                    selectedOptions: [
                      ...sectionItem?.value.selectedOptions,
                      newValue,
                    ],
                    inputAdded: [...sectionItem?.value.inputAdded],
                  }
                  : {
                    selectedOptions: [newValue],
                    inputAdded: [],
                  }
                : sectionItem?.value?.selectedOptions?.length > 0
                  ? {
                    selectedOptions: sectionItem?.value.selectedOptions.filter(
                      (item) => item !== newValue
                    ),
                    inputAdded:
                      newValue === "Other"
                        ? []
                        : sectionItem?.value?.inputAdded,
                  }
                  : {
                    selectedOptions: [],
                    inputAdded: [],
                  },
              sectionItem?.validation
            );
          }}
          onChangeInput={(inputData) => {
            onChange(
              indexSection,
              indexItem,
              sectionItem?.value?.inputAdded?.length > 0
                ? {
                  selectedOptions: [...sectionItem?.value?.selectedOptions],
                  inputAdded: [...sectionItem?.value?.inputAdded, inputData],
                }
                : {
                  selectedOptions: [...sectionItem?.value?.selectedOptions],
                  inputAdded: [inputData],
                },
              sectionItem?.validation
            );
          }}
          onRemovePill={(key) => {
            onChange(
              indexSection,
              indexItem,
              sectionItem?.value.inputAdded?.length > 1
                ? {
                  selectedOptions: sectionItem?.value?.selectedOptions,
                  inputAdded: sectionItem?.value?.inputAdded?.filter(
                    (el, key1) => key1 !== key
                  ),
                }
                : {
                  selectedOptions: sectionItem?.value?.selectedOptions,
                  inputAdded: [],
                },
              sectionItem?.validation
            );
          }}
          options={sectionItem?.options}
          inputAdded={sectionItem?.value?.inputAdded}
          required={sectionItem?.required}
          value={sectionItem?.value?.selectedOptions}
          wrapperClassName={"multipleCheckbox__wrapper"}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_UPLOAD_FILE:
      return (
        <UploadFile
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          itemFile={{
            acceptFiles: sectionItem?.acceptFiles,
            descriptionCard: sectionItem?.descriptionCard,
            keyUpload: sectionItem?.keyUpload,
            titleCard: sectionItem?.titleCard,
          }}
          isFileUploaded={sectionItem?.value === "File"}
          onChange={() =>
            onChange(indexSection, indexItem, "File", sectionItem?.validation)
          }
          required={sectionItem?.required}
          value={sectionItem?.value}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_DOCUSIGN:
      return (
        <DocuSign
          disabled={conditionComponentDisabled}
          docuSignId={sectionItem?.docusignId}
          key={`${indexSection}-item-${indexItem}`}
          label={sectionItem?.labelName}
          onChange={(value) =>
            onChange(
              indexSection,
              indexItem,
              value,
              sectionItem?.validation,
              true
            )
          }
          required={sectionItem?.required}
          value={sectionItem?.value}
          {...props}
        />
      );
    case FORM_CONST.COMPONENT_TYPE_TABLE:
      return (
        <TableDynamic
          columns={sectionItem?.columns}
          disabled={conditionComponentDisabled}
          key={`${indexSection}-item-${indexItem}`}
          onChange={(value) =>
            onChange(indexSection, indexItem, value, sectionItem?.validation)
          }
          value={sectionItem?.value}
          {...props}
        />
      );
    default:
      return <></>;
  }
}
