import { isEmailValid } from "../../utils/validationsFields";

// Define the initial state of the reducer
export const initialState = {
  isLoading: true,
  error: false,
  programFlows: {},
  showModalSendWorkflow: {
    active: false,
    value: {
      isEmailValided: false,
      emailArray: [],
      emailText: "",
    },
  },
  showModalErrorInvites: {
    activeUserNotExist: false,
    valueUserNotExist: [],
    activeUserAlreadyHas: false,
    valueUserAlreadyHas: [],
  },
  showModalOkInvites: {
    active: false,
    value: [],
  },
  tabNames: [],
  tabSelected: { index: 0 },
  title: "",
  stepperList: [],
};

// Define the action types
export const actionTypes = {
  RESPONSE_FROM_SEND_WORKFLOW: "RESPONSE_FROM_SEND_WORKFLOW",
  SET_CURRENT_FLOW: "SET_CURRENT_FLOW",
  SET_PROGRAMS_FLOWS: "SET_PROGRAMS_FLOWS",
  SET_TAB_SELECTED: "SET_TAB_SELECTED",
  SHOW_MODAL_ERROR_INVITE: "SHOW_MODAL_ERROR_INVITE",
  SHOW_MODAL_INVITE_OK: "SHOW_MODAL_INVITE_OK",
  SHOW_MODAL_SEND_WORKFLOW: "SHOW_MODAL_SEND_WORKFLOW",
};

// Define the reducer function
export function adminBuilderReducer(state, action) {
  if (action.type === actionTypes.RESPONSE_FROM_SEND_WORKFLOW) {
    const invites = action?.payload?.response;
    const errorInvitesNotExist = invites.filter(
      (userInvite) =>
        userInvite.status === "error" &&
        userInvite.message === "User does not exist"
    );
    const errorInvitesAlreadyHas = invites.filter(
      (userInvite) =>
        userInvite.status === "error" &&
        userInvite.message === "User already has workflow asigned"
    );
    const invitedInvites = invites.filter(
      (userInvite) => userInvite.status === "invited"
    );
    return {
      ...state,
      showModalSendWorkflow: {
        ...state.showModalSendWorkflow,
        active: false,
        value: {
          ...state.showModalSendWorkflow.value,
          isEmailValided: false,
        },
      },
      showModalErrorInvites: {
        activeUserNotExist: errorInvitesNotExist.length > 0,
        valueUserNotExist: errorInvitesNotExist,
        activeUserAlreadyHas: errorInvitesAlreadyHas.length > 0,
        valueUserAlreadyHas: errorInvitesAlreadyHas,
      },
      showModalOkInvites: {
        active: invitedInvites.length > 0,
        value: invitedInvites,
      },
    };
  }
  if (action.type === actionTypes.ONCHANGE_NEW_FLOW_FIELD) {
    const emailArray = action?.payload?.value
      .split(",")
      .map((email) => email.trim());
    const isEmailValided = emailArray.every((email) => isEmailValid(email));
    const emailText = emailArray.join(", ");
    return {
      ...state,
      showModalSendWorkflow: {
        ...state.showModalSendWorkflow,
        active: true,
        value: {
          isEmailValided,
          emailArray,
          emailText,
        },
      },
    };
  }
  if (action.type === actionTypes.SET_CURRENT_FLOW) {
    const { title = "", stepper: stepperList = [] } =
      action?.payload?.flowDefinition;
    return {
      ...state,
      isLoading: false,
      title,
      stepperList,
    };
  }
  if (action.type === actionTypes.SET_PROGRAMS_FLOWS) {
    return {
      ...state,
      isLoading: false,
      tabNames: action?.payload?.currentFlows,
      programFlows: action?.payload?.currentFlows,
    };
  }
  if (action.type === actionTypes.SET_TAB_SELECTED) {
    return {
      ...state,
      tabSelected: {
        index: action?.payload?.index,
        stepName: action?.payload?.stepName,
      },
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_ERROR_INVITE) {
    return {
      ...state,
      showModalErrorInvites: {
        activeUserNotExist: action?.payload?.activeUserNotExist
          ? false
          : state.showModalErrorInvites.activeUserNotExist,
        valueUserNotExist: action?.payload?.activeUserNotExist
          ? []
          : state.showModalErrorInvites?.valueUserNotExist,
        activeUserAlreadyHas: action?.payload?.activeUserAlreadyHas
          ? false
          : state.showModalErrorInvites.activeUserAlreadyHas,
        valueUserAlreadyHas: action?.payload?.activeUserAlreadyHas
          ? []
          : state.showModalErrorInvites?.valueUserAlreadyHas,
      },
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_INVITE_OK) {
    return {
      ...state,
      showModalOkInvites: {
        active: false,
      },
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_SEND_WORKFLOW) {
    return {
      ...state,
      showModalSendWorkflow: {
        ...state.showModalSendWorkflow,
        active: action?.payload?.active,
        program: action?.payload?.program,
        value: !state?.showModalSendWorkflow?.value
          ? state.showModalSendWorkflow.value
          : initialState.showModalSendWorkflow.value,
        workflowName: action?.payload?.workflowName,
        workflowId: action?.payload?.workflowId,
      },
    };
  }
}
