import { useLocation, useParams } from "react-router";
// Import components for BasicPage
import { TopHeader } from "../components/TopHeader/TopHeader";
import { FlowLayout } from "../components/FlowLayout/FlowLayout";
import Loader from "../components/Loader/Loader";
import Dashboard from "../components/Dashboard/Dashboard";
// Utils
import { useGetDataFromFlowID } from "../hooks/useGetDataFromFlowID";
// Constants
import * as STATUS_CONST from "../constants/status";
// Styles
import "./style.css";

export const OnboardingFlowIDPage = () => {
  const { onboardingFlowID } = useParams();
  const { state } = useLocation();
  const step = state?.step || 0;
  const accessFromLogin = state?.login || false;

  const { userData, isError, isLoading } =
    useGetDataFromFlowID(onboardingFlowID);

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  if (isLoading) {
    return (
      <div className="loader__wrapper-withHeader">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <TopHeader
        onboardingUser={{
          name: `${userData?.user?.firstName} ${userData?.user?.lastName}`,
          role: userData?.status,
          leadType: userData?.onboardingFlow?.leadType,
        }}
      />
      {accessFromLogin &&
      userData?.status !== STATUS_CONST.STATUS_NEW_OPPORTUNITY ? (
        <Dashboard userData={userData} />
      ) : (
        <FlowLayout newOnboardingData={userData} step={step} />
      )}
    </div>
  );
};
