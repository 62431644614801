import React from "react";
// Constants
import * as BUILDER_CONST from "../../../../constants/builderPage";
// Styles
import "./TypeViewNav.css";
import classNames from "classnames";

const TypeViewNav = ({ handleTypeViewChange, selectedTypeView = 0 }) => {
  return (
    <header className="typeViewNav__header">
      <nav className="typeViewNav__nav">
        {BUILDER_CONST.TYPE_VIEW_LIST.map((typeView, index) => {
          const typeViewNavClassNames = classNames("typeViewNav__item", {
            "admin__navTab-sectionActive": selectedTypeView === index,
            "admin__navTab-section": selectedTypeView !== index,
          });
          return (
            <div
              key={typeView.id}
              onClick={handleTypeViewChange}
              className={typeViewNavClassNames}
            >
              {typeView.name}
            </div>
          );
        })}
      </nav>
    </header>
  );
};

export default TypeViewNav;
