import _ from "lodash";
// Define the initial state of the reducer
export const initialState = {
  currentFlow: [],
  currentFlowBKP: [],
  error: false,
  isLoading: true,
  showModalUpdateField: {
    active: false,
    value: null,
  },
  sectionEditSelected: null,
  tabNames: [],
  tabSelected: { index: 0 },
};

// Define the action types
export const actionTypes = {
  CANCEL_EDIT_SECTION: "CANCEL_EDIT_SECTION",
  CANCEL_UPD_FIELD: "CANCEL_UPD_FIELD",
  ON_CHANGE_CONFIG: "ON_CHANGE_CONFIG",
  SET_CURRENT_CONFIGURATION_FLOW: "SET_CURRENT_CONFIGURATION_FLOW",
  SET_PROGRAMS_FLOWS: "SET_PROGRAMS_FLOWS",
  SET_SECTION_EDIT_SELECTED: "SET_SECTION_EDIT_SELECTED",
  SET_TAB_SELECTED: "SET_TAB_SELECTED",
  SHOW_MODAL_UPD_FIELD: "SHOW_MODAL_UPD_FIELD",
};

// Define the reducer function
export function adminLinksReducer(state, action) {
  if (action.type === actionTypes.CANCEL_EDIT_SECTION) {
    return {
      ...state,
      sectionEditSelected: null,
      currentFlow: _.cloneDeep(state?.currentFlowBKP),
    };
  }
  if (action.type === actionTypes.CANCEL_UPD_FIELD) {
    return {
      ...state,
      showModalUpdateField: { active: false, value: null },
      sectionEditSelected: null,
      currentFlow: _.cloneDeep(state?.currentFlowBKP),
    };
  }
  if (action.type === actionTypes.ON_CHANGE_CONFIG) {
    const currentFlowAux = _.cloneDeep(state?.currentFlow);
    // id, value, type
    if (action?.payload?.subtype?.length > 0) {
      if (action?.payload?.id !== null) {
        currentFlowAux[action.payload.type][action.payload.subtype][
          action.payload.id
        ][action.payload.field] = action?.payload?.value;
      } else {
        currentFlowAux[action.payload.type][action.payload.subtype][
          action.payload.field
        ] = action?.payload?.value;
      }
    } else {
      if (action?.payload?.id !== null) {
        currentFlowAux[action.payload.type][action.payload.id][
          action.payload.field
        ] = action?.payload?.value;
      } else {
        currentFlowAux[action.payload.type][action.payload.field] =
          action?.payload?.value;
      }
    }
    return {
      ...state,
      currentFlow: currentFlowAux,
    };
  }
  if (action.type === actionTypes.SET_PROGRAMS_FLOWS) {
    return {
      ...state,
      isLoading: false,
      tabNames: action?.payload?.currentFlows,
    };
  }
  if (action.type === actionTypes.SET_TAB_SELECTED) {
    return {
      ...state,
      sectionEditSelected: null,
      isLoading: false,
      tabSelected: {
        index: action?.payload?.index,
      },
    };
  }
  if (action.type === actionTypes.SET_SECTION_EDIT_SELECTED) {
    return {
      ...state,
      sectionEditSelected: action?.payload?.sectionEditSelected,
      currentFlow: _.cloneDeep(state?.currentFlowBKP),
    };
  }
  if (action.type === actionTypes.SET_CURRENT_CONFIGURATION_FLOW) {
    return {
      ...state,
      isLoading: false,
      currentFlow: action?.payload?.programConfig?.conf,
      currentFlowBKP: action?.payload?.programConfig?.conf,
    };
  }
  if (action.type === actionTypes.SHOW_MODAL_UPD_FIELD) {
    return {
      ...state,
      showModalUpdateField: {
        active: action?.payload?.active,
        indexSection: action?.payload?.indexSection,
        value: action?.payload?.value,
      },
    };
  }
}
