import React from "react";
import imageFindError from "../../../../assets/image_error_find.png";
import {
  getErrorDescription,
  getErrorTitle,
} from "../../../../utils/errorFunctions";
// Styles
import "./ErrorTemplate.css";

const ErrorTemplate = ({ error }) => {
  return (
    <div className="builderError__container">
      <div>
        <div>
          <span className="builderError__left-statusCode">
            {error?.status ?? 400}
          </span>
          <span className="builderError__left-title">
            {getErrorTitle(error?.status)}
          </span>
          <span className="builderError__left-description">
            {getErrorDescription(error?.status)}
          </span>
          <span className="error__left-description error__last">
            {error?.message}
          </span>
        </div>
      </div>
      <div className="error_rigth-wrapper">
        <img src={imageFindError} alt="Error" />
      </div>
    </div>
  );
};

export default ErrorTemplate;
