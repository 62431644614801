import React, { useContext } from "react";
// Components
import Button from "../../../Button/Button";
import ErrorTemplate from "../ErrorTemplate/ErrorTemplate";
import InputText from "../../../FormComponents/InputText/InputText";
import Loader from "../../../Loader/Loader";
import Modal from "../../../Modal/Modal";
// Images
import ImgSend from "../../../../assets/image_new_flow.png";
// Constants
import * as BUTTON_CONST from "../../../../constants/buttons";
import * as ADMIN_CONST from "../../../../constants/adminPage";
// Reducers
import { actionTypes } from "../../../../reducers/AdminBuilder/reducer";
// Context
import AdminBuilderContext from "../../../../context/AdminBuilder/AdminBuilderContext";
// Utils/Hooks
import { useAuth } from "../../../../utils/authenticate";
import { sendWorkflowForUser } from "../../../../utils/sendWorkflowForUser";
import { useGetWorkflowsByProgram } from "../../../../hooks/useGetWorkflowsByProgram";
// Styles
import "./WorkflowList.css";
import classNames from "classnames";

const WorkflowList = ({ program }) => {
  const { user } = useAuth();

  const { adminBuildState, dispatchAdminBuilderAction } =
    useContext(AdminBuilderContext);
  const { workFlowList, error, isLoading } = useGetWorkflowsByProgram({
    program,
  });

  if (error) return <ErrorTemplate error={error} />;

  if (isLoading)
    return (
      <div className="workFlowList__loader">
        <Loader />
      </div>
    );

  const renderFlow = (flowList, level) => {
    const flowClasses = classNames("workFlowList__flow", {
      workFlowList__child0: level === 0,
      workFlowList__child1: level === 1,
      workFlowList__child2: level === 2,
      workFlowList__child3: level >= 3,
    });

    const flowNameClasses = classNames({
      "workFlowList__FlowType-level0": level === 0,
      "workFlowList__FlowType-levelgt0": level !== 0,
    });

    return (
      <div
        className={"workFlowList__parent-content"}
        style={{ marginLeft: level * 16 }}
      >
        <div className={flowClasses}>
          <div className={flowNameClasses}>{flowList?.name}</div>
          <div className="workFlowList__triggeredBy">
            {flowList?.triggeredBy?.length > 0 &&
              `TRIGGERED BY: ${flowList?.triggeredBy}`}
          </div>
        </div>
        {flowList?.triggers &&
          flowList?.triggers?.map((trigger) => (
            <div key={trigger?.name}>{renderFlow(trigger, level + 1)}</div>
          ))}
      </div>
    );
  };

  const handleOnClickSendWorkflow = async () => {
    try {
      const data = await sendWorkflowForUser({
        workflowId: adminBuildState?.showModalSendWorkflow?.workflowId,
        emails: adminBuildState?.showModalSendWorkflow?.value?.emailArray,
        token: user?.token,
      });
      dispatchAdminBuilderAction({
        type: actionTypes.RESPONSE_FROM_SEND_WORKFLOW,
        payload: {
          response: data?.invites,
        },
      });
    } catch (error) {
      console.log("FGL Error: ", error);
    }
  };

  return (
    <div className="workFlowList__wrapper">
      {workFlowList?.map((workflow) => (
        <div key={workflow?.id} className="workFlowList__item-wrapper">
          <div className="workFlowList__item-main">
            <div className="workFlowList__item-mainTitle">{workflow?.name}</div>
            {workflow.can_invite && (
              <div className="admin__sendNewFlow">
                <Button
                  classes="secondary"
                  className="button__dashboard-filters"
                  disabled={adminBuildState?.showModalSendWorkflow?.active}
                  iconPlace="left"
                  image={{
                    className: "button__filter-flex",
                    src: ImgSend,
                    alt: "Send Workflow",
                  }}
                  onClick={() =>
                    dispatchAdminBuilderAction({
                      type: actionTypes.SHOW_MODAL_SEND_WORKFLOW,
                      payload: {
                        active: true,
                        program: program,
                        workflowName: workflow?.name,
                        workflowId: workflow?.id,
                      },
                    })
                  }
                  title={BUTTON_CONST.BUTTON_SEND_WORKFLOW}
                  style={{ border: "none", backgroundColor: "inherit" }}
                />
              </div>
            )}
          </div>
          {workflow?.flowtype_tree?.map((flowList) => (
            <div className={"workFlowList__parent"} key={flowList?.name}>
              {renderFlow(flowList, 0)}
            </div>
          ))}
          {adminBuildState?.showModalSendWorkflow?.active && (
            <div className="modal__backdrop">
              <Modal width={808} classes={"admin__filter-modal"}>
                <div className="modal__header-sendWorkflow-div">
                  <span className="modal__header-sendWorkflow">
                    {`Send ${adminBuildState?.showModalSendWorkflow?.workflowName} to Organization`}
                  </span>
                </div>
                <form
                  className="modal__sendNewFlow-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    adminBuildState?.showModalSendWorkflow?.value
                      ?.isEmailValided && handleOnClickSendWorkflow();
                  }}
                >
                  <div className="style__width-100">
                    <InputText
                      disabled={false}
                      label={ADMIN_CONST.LABEL_EMAIL_SEND_NEW_FLOW}
                      onChange={(event) =>
                        dispatchAdminBuilderAction({
                          type: actionTypes.ONCHANGE_NEW_FLOW_FIELD,
                          payload: {
                            value: event.target.value,
                          },
                        })
                      }
                      placeholder={ADMIN_CONST.PLACEHOLDER_EMAIL_WORKFLOW}
                      required={true}
                      value={
                        adminBuildState?.showModalSendWorkflow?.value?.emailText
                      }
                    />
                  </div>
                  <div className="modal__sendNewFlow-content">
                    <div className="modal__sendNewFlow-section">
                      <div className="modal__upper-header">
                        <div className="modal__title">
                          {ADMIN_CONST.MODAL_TITLE_PROGRAM}
                        </div>
                        <span className="modal__title-description">
                          {adminBuildState?.showModalSendWorkflow?.program}
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="modal__divider"></div>
                <div className="modal__footer">
                  <Button
                    classes="secondary"
                    onClick={() =>
                      dispatchAdminBuilderAction({
                        type: actionTypes.SHOW_MODAL_SEND_WORKFLOW,
                        payload: {
                          active: false,
                        },
                      })
                    }
                    title={BUTTON_CONST.BUTTON_CANCEL}
                  />
                  <Button
                    classes={
                      adminBuildState?.showModalSendWorkflow?.value
                        ?.isEmailValided
                        ? "active"
                        : "disabled"
                    }
                    disabled={
                      !adminBuildState?.showModalSendWorkflow?.value
                        ?.isEmailValided
                    }
                    onClick={handleOnClickSendWorkflow}
                    title={BUTTON_CONST.BUTTON_SEND}
                  />
                </div>
              </Modal>
            </div>
          )}
          {adminBuildState?.showModalOkInvites?.active && (
            <div className="modal__backdrop">
              <Modal height={305} width={486} classes={"admin__filter-modal"}>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="36"
                    viewBox="0 0 37 36"
                    fill="none"
                  >
                    <path
                      d="M20.2004 34.9159C29.4419 34.9159 36.9337 27.4241 36.9337 18.1826C36.9337 8.94099 29.4419 1.44922 20.2004 1.44922C10.9588 1.44922 3.46704 8.94099 3.46704 18.1826C3.46704 27.4241 10.9588 34.9159 20.2004 34.9159Z"
                      fill="white"
                    />
                    <path
                      d="M4.11678 27.5494C1.82318 24.1638 0.841818 20.0585 1.35633 16.0016C1.87085 11.9447 3.84598 8.21436 6.91214 5.50852C9.97829 2.80267 13.9253 1.3068 18.0147 1.3008C22.1041 1.2948 26.0555 2.7791 29.1296 5.47594C32.2036 8.17278 34.1897 11.8974 34.7161 15.9527C35.2425 20.008 34.2732 24.1162 31.9895 27.5085C29.7059 30.9009 26.2644 33.3448 22.309 34.3831C18.3536 35.4213 14.1555 34.9828 10.5001 33.1494"
                      stroke="#00BD63"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0012 18.6007L15.7846 22.484L25.0012 14.3174"
                      stroke="#00BD63"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="modal__wrapper-title">
                  <span className="modal__title">
                    {`${
                      adminBuildState?.showModalSendWorkflow?.workflowName
                    } sent to ${adminBuildState?.showModalSendWorkflow?.value?.emailArray?.join(
                      ", "
                    )}`}
                  </span>
                </div>
                <span className="modal__description-text">
                  {`Stay tuned for the completion process of ${adminBuildState?.showModalSendWorkflow?.value?.emailArray?.join(
                    ", "
                  )} in the "organizations" section`}
                </span>
                <div
                  className="modal__footer"
                  style={{ justifyContent: "center" }}
                >
                  <Button
                    classes="active"
                    onClick={() =>
                      dispatchAdminBuilderAction({
                        type: actionTypes.SHOW_MODAL_INVITE_OK,
                      })
                    }
                    title={BUTTON_CONST.BUTTON_GOT_IT}
                  />
                </div>
              </Modal>
            </div>
          )}
          {adminBuildState?.showModalErrorInvites?.activeUserNotExist && (
            <div className="modal__backdrop">
              <Modal height={305} width={486} classes={"admin__filter-modal"}>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="36"
                    viewBox="0 0 37 36"
                    fill="none"
                  >
                    <path
                      d="M20.2006 34.9149C29.4422 34.9149 36.934 27.4231 36.934 18.1816C36.934 8.94001 29.4422 1.44824 20.2006 1.44824C10.9591 1.44824 3.46729 8.94001 3.46729 18.1816C3.46729 27.4231 10.9591 34.9149 20.2006 34.9149Z"
                      fill="#E0EAFA"
                    />
                    <path
                      d="M4.11678 27.5494C1.82318 24.1638 0.841818 20.0585 1.35633 16.0016C1.87085 11.9447 3.84598 8.21436 6.91214 5.50852C9.97829 2.80267 13.9253 1.3068 18.0147 1.3008C22.1041 1.2948 26.0555 2.7791 29.1296 5.47594C32.2036 8.17278 34.1897 11.8973 34.7161 15.9527C35.2425 20.008 34.2732 24.1162 31.9895 27.5085C29.7059 30.9009 26.2644 33.3448 22.309 34.3831C18.3536 35.4213 14.1555 34.9828 10.5001 33.1494"
                      stroke="#0E2A58"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.8566 22.7839C17.8566 19.2127 20.5583 18.615 21.4886 17.6484C22.4219 16.6786 22.9994 15.3375 22.9994 13.8559C22.9994 10.8974 20.6969 8.49902 17.8566 8.49902C16.3207 8.49902 14.9421 9.20028 13.9998 10.3122C13.5641 10.8263 13.2216 11.4282 13 12.0892"
                      stroke="#0E2A58"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.9999 27.0691C18.3944 27.0691 18.7141 26.7493 18.7141 26.3549C18.7141 25.9604 18.3944 25.6406 17.9999 25.6406C17.6054 25.6406 17.2856 25.9604 17.2856 26.3549C17.2856 26.7493 17.6054 27.0691 17.9999 27.0691Z"
                      stroke="#0E2A58"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
                <div className="modal__wrapper-title">
                  <span className="modal__title">
                    {`We don't recognize  "${adminBuildState?.showModalErrorInvites?.valueUserNotExist
                      ?.map((user) => user.email)
                      .join(", ")}" as valid user/s`}
                  </span>
                </div>
                <span className="modal__description-text">
                  {`Please ensure the email address is correct or they have an active account.`}
                </span>
                <div
                  className="modal__footer"
                  style={{ justifyContent: "center" }}
                >
                  <Button
                    classes="active"
                    onClick={() =>
                      dispatchAdminBuilderAction({
                        type: actionTypes.SHOW_MODAL_ERROR_INVITE,
                        payload: {
                          activeUserNotExist: true,
                        },
                      })
                    }
                    title={BUTTON_CONST.BUTTON_OK}
                  />
                </div>
              </Modal>
            </div>
          )}
          {adminBuildState?.showModalErrorInvites?.activeUserAlreadyHas && (
            <div className="modal__backdrop">
              <Modal height={305} width={486} classes={"admin__filter-modal"}>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="36"
                    viewBox="0 0 37 36"
                    fill="none"
                  >
                    <path
                      d="M20.2006 34.9149C29.4422 34.9149 36.934 27.4231 36.934 18.1816C36.934 8.94001 29.4422 1.44824 20.2006 1.44824C10.9591 1.44824 3.46729 8.94001 3.46729 18.1816C3.46729 27.4231 10.9591 34.9149 20.2006 34.9149Z"
                      fill="#E0EAFA"
                    />
                    <path
                      d="M4.11678 27.5494C1.82318 24.1638 0.841818 20.0585 1.35633 16.0016C1.87085 11.9447 3.84598 8.21436 6.91214 5.50852C9.97829 2.80267 13.9253 1.3068 18.0147 1.3008C22.1041 1.2948 26.0555 2.7791 29.1296 5.47594C32.2036 8.17278 34.1897 11.8973 34.7161 15.9527C35.2425 20.008 34.2732 24.1162 31.9895 27.5085C29.7059 30.9009 26.2644 33.3448 22.309 34.3831C18.3536 35.4213 14.1555 34.9828 10.5001 33.1494"
                      stroke="#0E2A58"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.8566 22.7839C17.8566 19.2127 20.5583 18.615 21.4886 17.6484C22.4219 16.6786 22.9994 15.3375 22.9994 13.8559C22.9994 10.8974 20.6969 8.49902 17.8566 8.49902C16.3207 8.49902 14.9421 9.20028 13.9998 10.3122C13.5641 10.8263 13.2216 11.4282 13 12.0892"
                      stroke="#0E2A58"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.9999 27.0691C18.3944 27.0691 18.7141 26.7493 18.7141 26.3549C18.7141 25.9604 18.3944 25.6406 17.9999 25.6406C17.6054 25.6406 17.2856 25.9604 17.2856 26.3549C17.2856 26.7493 17.6054 27.0691 17.9999 27.0691Z"
                      stroke="#0E2A58"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
                <div className="modal__wrapper-title">
                  <span className="modal__title">
                    {`User  "${adminBuildState?.showModalErrorInvites?.valueUserAlreadyHas
                      ?.map((user) => user.email)
                      .join(", ")}" already has workflow assigned`}
                  </span>
                </div>
                <span className="modal__description-text">
                  {`Contact them to ask for the necessary information to start the onboarding process.`}
                </span>
                <div
                  className="modal__footer"
                  style={{ justifyContent: "center" }}
                >
                  <Button
                    classes="active"
                    onClick={() =>
                      dispatchAdminBuilderAction({
                        type: actionTypes.SHOW_MODAL_ERROR_INVITE,
                        payload: {
                          activeUserAlreadyHas: true,
                        },
                      })
                    }
                    title={BUTTON_CONST.BUTTON_OK}
                  />
                </div>
              </Modal>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default WorkflowList;
