import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
// Components
import Modal from "../../Modal/Modal";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import Input from "../../Input/Input";
import Avatar from "../../Avatar/Avatar";
import InputDate from "../../FormComponents/InputDate/InputDate";
import TableContent from "../TableContent/TableContent";
// Reducers
import { actionTypes } from "../../../reducers/AdminDashboard/reducer";
// Context
import AsideContext from "../../../context/AsideMenu/AsideContext";
import AdminDashboardContext from "../../../context/AdminDashboard/AdminDashboardContext";
// Images
import DownArrow from "../../../assets/down_red_arrow.png";
import UpArrow from "../../../assets/up_green_arrow.png";
import Download from "../../../assets/image_download.png";
import Refresh from "../../../assets/image_refresh.png";
import ImgSearch from "../../../assets/image_search.png";
import ImgClose from "../../../assets/image_close.png";
import ImgFilter from "../../../assets/image_filter.png";
import ImgSend from "../../../assets/image_new_flow.png";
import ImgChecked from "../../../assets/image_checked_done.png";
// Constants
import * as ADMIN_CONST from "../../../constants/adminPage";
import * as BUTTON_CONST from "../../../constants/buttons";
import * as STATUS_CONST from "../../../constants/status";
// Styles
import "./AdminDashboard.css";
import classNames from "classnames";
// Utils
import { useAuth } from "../../../utils/authenticate";
import { getOrganizationsFlowsList } from "../../../utils/getOrganizationsFlowsList";
import { downloadFile } from "../../../utils/downloadFile";

const getStatsDashboardCard = (statsData, title, quantity, time, total) => {
  return (
    <div className="admin__card">
      {statsData ? (
        <div className="admin__card-header">
          <div className="admin__card-headerTop">
            <div className="admin__card-headerText">{title}</div>
            <div className="admin__card-headerTrend">
              <div className="admin__card-headerUpDown">
                <div className="admin__card-UpDownText">
                  {quantity >= 0
                    ? `+ ${quantity.toString()}%`
                    : `- ${Math.abs(quantity).toString()}%`}
                </div>
                <div className="admin__card-UpDownIcon">
                  {quantity >= 0 ? (
                    <img src={UpArrow} alt={"Up green arrow"} />
                  ) : (
                    <img src={DownArrow} alt={"Down red arrow"} />
                  )}
                </div>
              </div>
              <div className="admin__card-headerDescription">{time}</div>
            </div>
          </div>
          <div className="admin__card-headerBottom">{total}</div>
        </div>
      ) : (
        <div style={{ alignSelf: "center" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

const AdminDashboard = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [isError, setIsError] = useState({ active: false, error: "" });
  const location = useLocation();

  const refTopBar = useRef(null);
  const refModalContainer = useRef(null);
  const refBubble = useRef([]);

  const asideContext = useContext(AsideContext);
  const { adminDashState, dispatchAdminDashboardAction } = useContext(
    AdminDashboardContext
  );

  const handleOnClickRowTable = async (index, email) => {
    const onboardingFlowId = adminDashState?.filterSearchNav?.active
      ? adminDashState?.organizationFlowsSearchedList[email][index]?.id
      : adminDashState?.listFlowsGroupedByOrganization[email][index]?.id;
    dispatchAdminDashboardAction({
      type: actionTypes.SET_ROW_SELECTED,
      payload: {
        row: {
          active: true,
          row: index,
          onboardingFlowId,
          username: email,
        },
      },
    });
    return navigate(`/admin/dashboard/${onboardingFlowId}`);
  };

  const handleOnClickDownloadFile = () => {
    const convertedData = Object.values(
      adminDashState?.listFlowsGroupedByOrganization
    ).flat();
    downloadFile(convertedData);
  };

  const handleOnChangeInputSearchNav = (event) => {
    dispatchAdminDashboardAction({
      type: actionTypes.SET_INPUT_SEARCH_NAV,
      payload: {
        inputSearchNav: event?.target?.value,
      },
    });
  };

  const handleOnClickSearchNav = () => {
    dispatchAdminDashboardAction({
      type: actionTypes.FILTER_ROWS_BY_SEARCH_NAV,
    });
    if (adminDashState?.inputSearchNav?.length > 0) {
      const encodedSearchNav = encodeURIComponent(
        adminDashState?.inputSearchNav
      );
      return navigate(`/admin/dashboard/?search=${encodedSearchNav}`);
    } else {
      return navigate(`/admin/dashboard`);
    }
  };

  const handleOnRemoveFilteredSearch = (reload) => {
    dispatchAdminDashboardAction({
      type: actionTypes.SET_INPUT_SEARCH_NAV,
      payload: {
        inputSearchNav: "",
      },
    });
    dispatchAdminDashboardAction({
      type: actionTypes.FILTER_ROWS_BY_SEARCH_NAV,
    });
    if (reload) {
      return navigate(`/admin/dashboard`);
    }
  };

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        refModalContainer?.current &&
        refBubble?.current[adminDashState?.showModalChat?.sectionChat] &&
        !refModalContainer.current.contains(event.target) &&
        !refBubble.current[
          adminDashState?.showModalChat?.sectionChat
        ]?.current?.contains(event.target)
      ) {
        dispatchAdminDashboardAction({
          type: actionTypes.SHOW_MODAL_CHAT,
          payload: {
            active: false,
            email: user?.email,
          },
        });
      }
    },
    [
      refModalContainer,
      dispatchAdminDashboardAction,
      user?.email,
      adminDashState?.showModalChat?.sectionChat,
    ]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    const organizationsFlowsList = async ({ token }) => {
      try {
        const data = await getOrganizationsFlowsList({ token });
        dispatchAdminDashboardAction({
          type: actionTypes.SET_ORG_ONBOARDING_FLOWS_LIST,
          payload: {
            organizationFlowsList: data?.flows,
            organizationFlowsStats: data?.stats,
          },
        });
        if (adminDashState?.refreshPage) {
          dispatchAdminDashboardAction({
            type: actionTypes.SET_REFRESH_PAGE,
            payload: {
              refreshPage: false,
            },
          });
        }
        const query = new URLSearchParams(window.location.search).get("search");
        if (query?.trim()?.length > 0) {
          dispatchAdminDashboardAction({
            type: actionTypes.SET_INPUT_SEARCH_NAV,
            payload: {
              inputSearchNav: query,
            },
          });
          dispatchAdminDashboardAction({
            type: actionTypes.FILTER_ROWS_BY_SEARCH_NAV,
          });
        } else {
          handleOnRemoveFilteredSearch(false);
        }
      } catch (err) {
        setIsError({
          active: true,
          error: `Failed to fetch organizationsFlowsList: ${err?.cause}`,
          status: err?.status,
          statusText: err?.statusText,
        });
      }
    };
    if (
      (Object.keys(adminDashState?.listFlowsGroupedByOrganization).length ===
        0 &&
        adminDashState?.filters?.count === 0) ||
      adminDashState?.refreshPage
    ) {
      if (user?.token) {
        organizationsFlowsList({ token: user?.token });
      } else {
        setIsError({
          active: true,
          error: "Page Not Found. First Login to App.",
          status: 404,
          statusText: "Page Not Found",
        });
      }
    } else {
      const query = new URLSearchParams(window.location.search).get("search");
      if (query?.trim()?.length === 0 || !query) {
        handleOnRemoveFilteredSearch();
      } else {
        dispatchAdminDashboardAction({
          type: actionTypes.SET_INPUT_SEARCH_NAV,
          payload: {
            inputSearchNav: query,
          },
        });
        dispatchAdminDashboardAction({
          type: actionTypes.FILTER_ROWS_BY_SEARCH_NAV,
        });
      }
      dispatchAdminDashboardAction({
        type: actionTypes.SET_ONBOARDING_ID_DATA_LOADING,
        payload: {
          onboardingIdDataLoading: true,
        },
      });
    }
    if (refTopBar) {
      refTopBar.current.scrollIntoView();
    }
  }, [
    window.location.href,
    adminDashState?.filters?.count,
    Object.keys(adminDashState?.listFlowsGroupedByOrganization).length,
    adminDashState?.refreshPage,
    getOrganizationsFlowsList,
    location?.state?.fromAdminDashboard,
    user?.token,
  ]);

  const wrapperClasses = classNames(
    "checkbox__label",
    {
      "admin__content-expanded": asideContext.asideOpen,
    },
    {
      admin__content: !asideContext.asideOpen,
    },
    {
      admin__hideScrollY:
        adminDashState?.showModalFilters?.active && !asideContext.asideOpen,
    },
    {
      "admin__hideScrollY-aside":
        adminDashState?.showModalFilters?.active && asideContext.asideOpen,
    }
  );

  if (isError?.active) {
    throw new Error(
      JSON.stringify({
        message: isError?.error,
        status: isError?.status,
        statusText: isError?.statusText,
      })
    );
  }

  return (
    <div className={wrapperClasses} ref={refTopBar}>
      <div className="admin__topBar">
        <div className="admin__topBar-title">
          <span>
            {adminDashState?.filterSearchNav?.active
              ? ADMIN_CONST.TOPBAR_SEARCH_RESULTS
              : ADMIN_CONST.TOPBAR_DASHBOARD_TITLE}
          </span>
        </div>
        <div className="admin__topBar-right">
          <div className="admin__searchNav">
            <div className="admin__searchNav-content">
              <div className="admin__searchNav-left">
                <img src={ImgSearch} alt="Search Nav" />
                <Input
                  className="admin__searchNav-input"
                  onChange={(e) => handleOnChangeInputSearchNav(e)}
                  onKeyPress={(e) => {
                    e.key === "Enter" && handleOnClickSearchNav();
                  }}
                  placeholder={ADMIN_CONST.PLACEHOLDER_SEARCH}
                  type="input"
                  style={{ display: "flex" }}
                  value={adminDashState?.inputSearchNav}
                />
              </div>
              {!adminDashState?.filterSearchNav?.active && (
                <div className="admin__searchNav-right">
                  <Button
                    classes="secondary"
                    className="button__searchNav"
                    title={BUTTON_CONST.BUTTON_SEARCH}
                    onClick={handleOnClickSearchNav}
                  />
                </div>
              )}
              {adminDashState?.filterSearchNav?.active && (
                <div className="admin__searchNav-right">
                  <Button
                    classes="secondary"
                    className="button__searchNav"
                    image={{
                      src: ImgClose,
                      alt: "Close Icon",
                      className: "button__flex",
                    }}
                    onClick={handleOnRemoveFilteredSearch}
                  />
                </div>
              )}
            </div>
          </div>
          <Avatar typeUser={"admin"} />
        </div>
      </div>
      <>
        {!adminDashState?.filterSearchNav?.active && (
          <div className="admin__cards">
            {getStatsDashboardCard(
              Object.keys(adminDashState?.organizationFlowsStats).length !== 0,
              ADMIN_CONST.CARD_ALL_ORG,
              adminDashState?.organizationFlowsStats
                ?.organizations_count_change,
              ADMIN_CONST.LAST_MONTH,
              adminDashState?.organizationFlowsStats?.organizations_count
            )}
            {getStatsDashboardCard(
              Object.keys(adminDashState?.organizationFlowsStats).length !== 0,
              ADMIN_CONST.CARD_ACTIVE_ORG,
              adminDashState?.organizationFlowsStats
                ?.organizations_active_change,
              ADMIN_CONST.LAST_MONTH,
              adminDashState?.organizationFlowsStats?.organizations_active_count
            )}
            {getStatsDashboardCard(
              Object.keys(adminDashState?.organizationFlowsStats).length !== 0,
              ADMIN_CONST.CARD_PENDING_TASKS,
              adminDashState?.organizationFlowsStats?.pending_change,
              ADMIN_CONST.LAST_WEEK,
              adminDashState?.organizationFlowsStats?.pending_taks
            )}
          </div>
        )}
        <div
          className={`${
            adminDashState?.filterSearchNav?.active
              ? "admin__title"
              : "admin__title-row"
          }`}
        >
          <div className="admin__title-wrapper">
            <div className="admin__titleText">
              {adminDashState?.filterSearchNav?.active
                ? `Search for results: "${adminDashState?.filterSearchNav?.lastSearch}"`
                : ADMIN_CONST.DASHBOARD_TITLE}
            </div>
            {!adminDashState?.filterSearchNav?.active && (
              <div className="admin__filters">
                <Button
                  classes="secondary"
                  className="button__dashboard-filters"
                  iconPlace="left"
                  image={{
                    className: "button__filter-flex",
                    src: ImgFilter,
                    alt: "Filter Icon",
                  }}
                  onClick={() =>
                    dispatchAdminDashboardAction({
                      type: actionTypes.SHOW_MODAL_FILTERS,
                      payload: {
                        active: true,
                      },
                    })
                  }
                  title={`${BUTTON_CONST.BUTTON_FILTERS} ${
                    adminDashState?.filters?.count > 0
                      ? `(+${adminDashState?.filters.count})`
                      : ""
                  }`}
                />
              </div>
            )}
          </div>
          {adminDashState?.filterSearchNav?.active && (
            <div className="admin__titleDescription">
              {`${Object.values(
                adminDashState?.organizationFlowsSearchedList
              ).reduce((count, emailData) => count + emailData.length, 0)} ${
                ADMIN_CONST.SEARCH_RESULT_FOUND
              }`}
            </div>
          )}
          {!adminDashState?.filterSearchNav?.active && (
            <div className="admin__tcommands">
              <div className="admin__tbuttons">
                <Button
                  classes="secondary"
                  className="button__dashboard-auxiliar"
                  image={{
                    src: Refresh,
                    alt: "Refresh Button",
                    className: "button__flex",
                  }}
                  onClick={() =>
                    dispatchAdminDashboardAction({
                      type: actionTypes.SET_REFRESH_PAGE,
                      payload: {
                        refreshPage: true,
                      },
                    })
                  }
                />
              </div>
              <div className="admin__tbuttons">
                <Button
                  classes="secondary"
                  className="button__dashboard-auxiliar"
                  image={{
                    src: Download,
                    alt: "Download Button",
                    className: "button__flex",
                  }}
                  onClick={() => handleOnClickDownloadFile()}
                />
              </div>
            </div>
          )}
        </div>
        <TableContent
          isLoading={
            (Object.keys(adminDashState?.listFlowsGroupedByOrganization)
              .length === 0 &&
              adminDashState?.filters?.count === 0) ||
            adminDashState?.refreshPage
          }
          objectList={
            adminDashState?.filterSearchNav?.active
              ? adminDashState?.organizationFlowsSearchedList
              : adminDashState?.listFlowsGroupedByOrganization
          }
          onClick={handleOnClickRowTable}
        />
      </>
      {adminDashState?.showModalFilters?.active && (
        <div className="modal__backdrop">
          <Modal width={819} classes={"admin__filter-modal"}>
            <div className="modal__header">
              <h1 className="modal__header-main">
                {ADMIN_CONST.TITLE_FILTERS}
              </h1>
              <Button
                className={"admin__filter-btnErase"}
                title={BUTTON_CONST.BUTTON_ERASE_FILTER}
                classes="secondary"
                onClick={() =>
                  dispatchAdminDashboardAction({
                    type: actionTypes.ERASE_FILTERS,
                  })
                }
              />
            </div>
            <div className="admin__filters-content">
              <div className="admin__filters_grouping">
                <div className="admin__filters-gList">
                  <span className="admin__filters-typeT">
                    {ADMIN_CONST.FILTER_TITLE_STATUS}
                  </span>
                  <div className="admin__filters-options">
                    {STATUS_CONST.STATUS_ORG_FILTER_DASH.map((item, key) => {
                      return (
                        <div
                          key={`status-${item?.name}-${key}`}
                          className="admin__filter-wrapperOpt"
                        >
                          <input
                            disabled={false}
                            className="admin__filters-optionSelect"
                            type="checkbox"
                            value={item?.value}
                            checked={adminDashState?.filters?.[
                              "status"
                            ]?.includes(item.value)}
                            onChange={(event) =>
                              dispatchAdminDashboardAction({
                                type: actionTypes.SET_FILTERS,
                                payload: {
                                  value: event?.target?.value,
                                  checked: event?.target?.checked,
                                  field: "status",
                                },
                              })
                            }
                          ></input>
                          <span className="admin__filter-textOpt">
                            {item?.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="modal__divider"></div>
                  <span className="admin__filters-typeT">
                    {ADMIN_CONST.FILTER_TITLE_PROGRAM}
                  </span>
                  <div className="admin__filters-options">
                    {ADMIN_CONST.FILTER_PROGRAM.map((item, key) => {
                      return (
                        <div
                          key={`program-${item?.name}-${key}`}
                          className="admin__filter-wrapperOpt"
                        >
                          <input
                            disabled={false}
                            className="admin__filters-optionSelect"
                            type="checkbox"
                            value={item?.value}
                            checked={adminDashState?.filters?.[
                              "program"
                            ]?.includes(item.value)}
                            onChange={(event) =>
                              dispatchAdminDashboardAction({
                                type: actionTypes.SET_FILTERS,
                                payload: {
                                  value: event?.target?.value,
                                  checked: event?.target?.checked,
                                  field: "program",
                                },
                              })
                            }
                          ></input>
                          <span className="admin__filter-textOpt">
                            {item?.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="admin__filters_calendar">
                <span className="admin__filters-typeT">
                  {ADMIN_CONST.FILTER_TITLE_DATE}
                </span>
                <div className="admin__filters-options">
                  {ADMIN_CONST.FILTER_DATES.map((item, key) => {
                    return (
                      <div
                        key={`program-${item?.name}-${key}`}
                        className="admin__filter-wrapperOpt"
                      >
                        <input
                          disabled={false}
                          className="admin__filters-optionSelect"
                          type="radio"
                          value={item?.value}
                          checked={
                            adminDashState?.filters?.datePicker?.value ===
                            item.value
                          }
                          onChange={(event) =>
                            dispatchAdminDashboardAction({
                              type: actionTypes.SET_FILTERS,
                              payload: {
                                value: event?.target?.value,
                                checked: event?.target?.checked,
                                field: "datePicker",
                              },
                            })
                          }
                        ></input>
                        <span className="admin__filter-textOpt">
                          {item?.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="admin__filters-datePicker">
                  <InputDate
                    disabled={
                      adminDashState?.filters?.datePicker?.value !==
                      "datePicker"
                    }
                    label={ADMIN_CONST.FILTER_TITLE_FROM}
                    onChange={(event) =>
                      dispatchAdminDashboardAction({
                        type: actionTypes.SET_FILTERS,
                        payload: {
                          value: event?.target?.value,
                          field: "dateFrom",
                        },
                      })
                    }
                    value={adminDashState?.filters?.datePicker?.dateFrom}
                  />
                  <InputDate
                    disabled={
                      adminDashState?.filters?.datePicker?.value !==
                      "datePicker"
                    }
                    label={ADMIN_CONST.FILTER_TITLE_TO}
                    onChange={(event) =>
                      dispatchAdminDashboardAction({
                        type: actionTypes.SET_FILTERS,
                        payload: {
                          value: event?.target?.value,
                          field: "dateTo",
                        },
                      })
                    }
                    value={adminDashState?.filters?.datePicker?.dateTo}
                  />
                </div>
              </div>
            </div>
            <div className="modal__divider"></div>
            <div className="modal__footer">
              <Button
                classes="secondary"
                onClick={() =>
                  dispatchAdminDashboardAction({
                    type: actionTypes.CANCEL_FILTERS,
                  })
                }
                title={BUTTON_CONST.BUTTON_CANCEL}
              />
              <Button
                classes="active"
                onClick={() =>
                  dispatchAdminDashboardAction({
                    type: actionTypes.APPLY_FILTERS,
                  })
                }
                title={BUTTON_CONST.BUTTON_APPLY}
              />
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
