import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ isChecked, onToggleChange }) => {
  return (
    <div
      className="switch-container"
      onClick={() => onToggleChange(!isChecked)}
    >
      <input
        type="checkbox"
        className="toggle-input"
        checked={isChecked}
        readOnly
      />
      <div className={`toggle-label ${isChecked ? "active" : ""}`}>
        <div className={`toggle-circle ${isChecked ? "active" : ""}`}></div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
