import React from "react";
import ProgramNav from "../ProgramNav/ProgramNav";
import TypeViewNav from "../TypeViewNav/TypeViewNav";
// Styles
import "./AdminBuilderNav.css";

const AdminBuilderNav = ({
  handleProgramChange,
  handleTypeViewChange,
  programList,
  selectedProgram,
  selectedTypeView,
}) => {
  return (
    <div className="admin__builder-cardsWrapper">
      <ProgramNav
        handleProgramChange={handleProgramChange}
        programList={programList}
        selectedProgram={selectedProgram}
      />
      <TypeViewNav
        handleTypeViewChange={handleTypeViewChange}
        selectedTypeView={selectedTypeView}
      />
    </div>
  );
};

export default AdminBuilderNav;
