import React from "react";
import AdminNavTab from "../../../Icons/AdminNavTab/AdminNavTab";

const ProgramNav = ({ handleProgramChange, programList, selectedProgram }) => {
  return (
    <nav className="admin__navTab-wrapper">
      {programList?.length > 0 &&
        programList?.map((programName, index) => {
          return (
            <div
              key={`${programName}-${index}`}
              onClick={() => handleProgramChange(index, programName)}
              className={
                selectedProgram?.index === index
                  ? "admin__navTab-sectionActive"
                  : "admin__navTab-section"
              }
            >
              <AdminNavTab
                color={selectedProgram?.index === index ? "#2468DB" : "#17181C"}
              />
              <div
                className={
                  selectedProgram?.index === index
                    ? "admin__navTab-sectionNameActive"
                    : "admin__navTab-sectionName"
                }
              >
                {programName}
              </div>
            </div>
          );
        })}
    </nav>
  );
};

export default ProgramNav;
