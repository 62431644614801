export const TITLE_FOR_LINK_PAGES = "Get started as a League Operator";
export const TITLE_MY_TASKS = "My tasks";
export const SECTION_REVIEW = "Review";
export const SECTION_RESOLVE = "Resolve";
export const MSG_SECTION_NEW_OPPORTUNITY =
  "You have pending information to complete in this step. Please fill out all required fields.";
export const MSG_SECTION_APPROVED =
  "Congratulations, you have successfully completed this step of the form";
export const MSG_SECTION_PENDING =
  "Your request has been received and is currently being reviewed by an administrator";
export const MSG_SECTION_NEEDS_ATTENTION =
  "Sorry, there is one or more sections that require attention. Please, make the necessary corrections";
export const MSG_SECTION_INCOMPLETED =
  "Finish uploading your information. Have any doubts? Get in touch!";
export const NOTIFICATION_UNREAD_MSGS = "You have a new message";
