import React from "react";
import PropTypes from "prop-types";
// Styles
import "./Checkbox.css";
import classNames from "classnames";

const Checkbox = ({
  className = "",
  checked,
  disabled = false,
  label,
  onChange,
  required = false,
  style,
  value,
  wrapperClassName,
  ...additionalProps
}) => {
  const labelClasses = classNames(
    "checkbox__label",
    {
      "admin__disabled-color": disabled,
    },
    {
      "checkbox__cursor-pointer": !disabled,
    }
  );

  const inputDateClasses = classNames(
    "checkbox__input-checked",
    className,
    {
      "admin__disabled-color admin__disabled-background user__disabled-background":
        disabled,
    },
    {
      "checkbox__cursor-pointer": !disabled,
    }
  );

  const wrapperClasses = classNames(wrapperClassName, {
    "checkbox__cursor-pointer": !disabled,
  });

  const handleOnClick = () => {
    return !disabled && onChange(value);
  };

  return (
    <div className={wrapperClasses} onClick={handleOnClick}>
      <input
        checked={checked}
        className={inputDateClasses}
        disabled={disabled}
        onChange={handleOnClick}
        required={required}
        style={style}
        type="checkbox"
        value={value}
        {...additionalProps}
      />
      {label && (
        <label className={labelClasses}>
          {label}
          {required ? "*" : null}
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  wrapperClassName: PropTypes.string,
};

export default Checkbox;
