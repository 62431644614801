import React from "react";
import PropTypes from "prop-types";

const Info = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="error">
        <path
          id="Path 5"
          d="M12 8V13"
          stroke={props.color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Oval"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
          fill={props.color}
        />
        <path
          id="Oval_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke={props.color}
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

Info.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Info.defaultProps = {
  height: 24,
  width: 24,
};

export default Info;
