export const STATUS_APPROVED = "APPROVED";
export const STATUS_DENIED = "DENIED";
export const STATUS_NEEDS_ATTENTION = "NEEDS ATTENTION";
export const STATUS_NEW_OPPORTUNITY = "NEW OPPORTUNITY";
export const STATUS_PENDING = "PENDING";
export const STATUS_PUBLISHED = "PUBLISHED";
export const STATUS_SENT_TO_RCX = "SENT TO RCX";
export const STATUS_TO_APPROVED = "TO_APPROVE";
export const STATUS_TODO = "TODO";
// Array Status
export const STATUS_ORGANIZATION_SECTIONS = [
  { name: "APPROVED", value: "APPROVED" },
  { name: "DENIED", value: "DENIED" },
  { name: "PENDING", value: "PENDING" },
];
export const STATUS_SECTIONS = [
  { name: "APPROVED", value: "APPROVED" },
  { name: "NEEDS ATTENTION", value: "NEEDS ATTENTION" },
];
export const STATUS_SECTIONS_INITIAL = [
  { name: "APPROVED", value: "APPROVED" },
  { name: "NEEDS ATTENTION", value: "NEEDS ATTENTION" },
  { name: "PENDING", value: "PENDING" },
];
export const STATUS_ORG_FILTER_DASH = [
  { name: "Approved", value: "APPROVED" },
  { name: "New opportunity", value: "NEW OPPORTUNITY" },
  { name: "Pending", value: "PENDING" },
  { name: "Inactive", value: "INACTIVE" },
  { name: "Denied", value: "DENIED" },
];
export const statusInformationText = [
  {
    index: 1,
    status: "TO DO",
    description: "You haven't submitted anything yet for RCX to check",
  },
  {
    index: 2,
    status: "SENT TO RCX",
    description:
      "You have already submitted your task, now you just have to wait for RCX to respond or approve it.",
  },
  {
    index: 3,
    status: "NEEDS ATTENTION",
    description:
      "Check out what RCX responded. You may need to fix some of your uploaded information",
  },
  {
    index: 4,
    status: "APPROVED",
    description: "Great! This step is already checked and approved",
  },
  {
    index: 5,
    status: "DENIED",
    description: "Contact RCX if you think this step should not be declined",
  },
];
export const statusRisks = {
  "NEEDS ATTENTION": 1,
  TODO: 1,
  "TO DO": 1,
  PENDING: 1,
  "SENT TO RCX": 2,
  APPROVED: 3,
  DENIED: 4,
};
