import React, { useState } from "react";
import AsideContext from "./AsideContext";

const AsideProvider = ({ children }) => {
  const [asideOpen, setAsideOpen] = useState(false);

  const toggleAside = () => setAsideOpen(!asideOpen);

  return (
    <AsideContext.Provider value={{ asideOpen, toggleAside }}>
      {children}
    </AsideContext.Provider>
  );
};

export default AsideProvider;
