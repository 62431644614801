// Constants
import * as ADMIN_CONST from "../constants/adminPage";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const options = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};

export function formatDateToStringMMMDDYYYYHHMM(dateString) {
  /* Feb 14 2022, 11:49 AM */
  if (!dateString) return "Not Available";
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}

export function formatDateToStringMMMDDYYYY(dateString) {
  /* Feb 14 2022 */
  if (!dateString) return "Not Available";
  const date = new Date(dateString);
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}

export function formattedDate(isoDate) {
  if (!isoDate) return ADMIN_CONST.NOT_AVAILABLE;

  const dateObj = new Date(isoDate);

  return `${
    monthNames[dateObj.getMonth()]
  } ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
}

export function isCurrentDate(inputDate) {
  const currentDate = new Date();
  currentDate.setUTCHours(0, 0, 0, 0);

  inputDate = new Date(inputDate);
  inputDate.setUTCHours(0, 0, 0, 0);

  return currentDate.getTime() === inputDate.getTime();
}

export function isBetweenLastWeek(inputDate) {
  const currentDate = new Date();
  currentDate.setUTCHours(23, 59, 59, 999);

  inputDate = new Date(inputDate);
  inputDate.setUTCHours(inputDate.getUTCHours());

  const dateLastWeek = new Date();
  dateLastWeek.setUTCHours(0, 0, 0, 0);
  dateLastWeek.setDate(currentDate.getDate() - 7);

  return inputDate >= dateLastWeek && inputDate <= currentDate;
}

export function isBetweenLastMonth(inputDate) {
  const currentDate = new Date();
  currentDate.setUTCHours(0, 0, 0, 0);

  inputDate = new Date(inputDate);
  inputDate.setUTCHours(0, 0, 0, 0);

  const dateLastMonth = new Date();
  dateLastMonth.setMonth(currentDate.getMonth() - 1);
  dateLastMonth.setUTCHours(0, 0, 0, 0);

  return inputDate >= dateLastMonth && inputDate <= currentDate;
}

export function fromDate(inputDate, fromDate) {
  fromDate = new Date(fromDate);
  fromDate.setUTCHours(0, 0, 0, 0);

  inputDate = new Date(inputDate);
  inputDate.setUTCHours(0, 0, 0, 0);

  return inputDate.getTime() >= fromDate.getTime();
}

export function toDate(inputDate, toDate) {
  toDate = new Date(toDate);
  toDate.setUTCHours(0, 0, 0, 0);

  inputDate = new Date(inputDate);
  inputDate.setUTCHours(0, 0, 0, 0);

  return inputDate.getTime() <= toDate.getTime();
}

export function formatDateToStringYYYYMMDDHHMMSS(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  let hours = ("0" + date.getHours()).slice(-2);
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const ampm = hours >= 12 ? "PM" : "AM";

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

  return formattedDate;
}

export function formatDateDifference(inputDate) {
  if (!inputDate) return ADMIN_CONST.NOT_AVAILABLE;

  const currentDate = new Date();
  const inputDateTime = new Date(inputDate);
  const timeDifference = currentDate.getTime() - inputDateTime.getTime();

  const minuteInMillis = 60 * 1000;
  const hourInMillis = 60 * minuteInMillis;
  const dayInMillis = 24 * hourInMillis;
  const monthInMillis = 30 * dayInMillis;
  const yearInMillis = 365 * dayInMillis;

  if (timeDifference < minuteInMillis) {
    const seconds = Math.floor(timeDifference / 1000);
    return seconds + "s ago";
  } else if (timeDifference < hourInMillis) {
    const minutes = Math.floor(timeDifference / minuteInMillis);
    return minutes + "min ago";
  } else if (timeDifference < dayInMillis) {
    const hours = Math.floor(timeDifference / hourInMillis);
    return hours + "h ago";
  } else if (timeDifference < monthInMillis) {
    const days = Math.floor(timeDifference / dayInMillis);
    return days + "d ago";
  } else if (timeDifference < yearInMillis) {
    const months = Math.floor(timeDifference / monthInMillis);
    return months + "mo ago";
  } else {
    const years = Math.floor(timeDifference / yearInMillis);
    return years + "y ago";
  }
}
