import { getBaseURL } from "./getBaseURL";

export async function getCurrentFlows(user_token) {
  const SERVER_URL = getBaseURL();

  try {
    const res = await fetch(`${SERVER_URL}/admin/builder/programs`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user_token,
      },
    });
    if (!res.ok) {
      const errorMsg = await res.json();
      throw Object.assign(
        new Error(
          `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
        ),
        {
          status: res.status,
          statusText: res.statusText,
          cause: errorMsg.error,
        }
      );
    }
    const data = await res.json();
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFlowDefinition(user_token, flowId) {
  const SERVER_URL = getBaseURL();

  try {
    const res = await fetch(`${SERVER_URL}/admin/builder/${flowId}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user_token,
      },
    });
    if (!res.ok) {
      const errorMsg = await res.json();
      throw Object.assign(
        new Error(
          `${res.statusText} : ${errorMsg.error}. Status: ${res.status}`
        ),
        {
          status: res.status,
          statusText: res.statusText,
          cause: errorMsg.error,
        }
      );
    }
    const data = await res.json();
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}
