import React from "react";
import PropTypes from "prop-types";
// Styles
import "./Button.css";

const Button = (props) => {
  const classes = `button-class buttonBase ${props?.classes} ${props?.className}`;

  const handleOnClick = (event) => {
    event.preventDefault();
    props?.onClick(event);
  };

  return (
    <button
      className={classes}
      disabled={props?.disabled}
      onClick={handleOnClick}
      style={props?.style}
      type="submit"
    >
      {props?.iconPlace === "left" && props?.image?.src && (
        <div className={props?.image?.className ?? "button__margin-auto"}>
          <img src={props?.image?.src} alt={props?.image?.alt} />
        </div>
      )}
      {props?.title}
      {props?.iconPlace === "right" && props?.image?.src && (
        <div className={props?.image?.className ?? "button__margin-auto"}>
          <img src={props?.image?.src} alt={props?.image?.alt} />
        </div>
      )}
      {props.children}
    </button>
  );
};

Button.propTypes = {
  classes: PropTypes.oneOf(["active", "secondary", "disabled", "transparent", "pill"])
    .isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  disabled: false,
  iconPlace: "right",
};

export default Button;
