export const DONT_HAVE_ACCOUNT = "Don't have an account? ";
export const CONTACT_SUPPORT = "Contact Support";
export const REMEMBER_ME = "Remember me";
export const TITLE_PASSWORD_UPDATED = "Great job, you're ready to go!";
export const DESCRIPTION_PASSWORD_UPDATED =
  "Please check your email for verification and a link to the platformor log in right now.";
export const FORGOT_PASSWORD = "I forgot my password";
export const RESET_PASSWORD_ENTER_EMAIL = "Enter your email";
export const RESET_PASSWORD_OK = "Email sent";
export const RESET_PASSWORD_DESCRIPTION =
  "We will send an email to your account so you can start the reseting password process.";
// Pages Definition
export const APP_PAGES = Object.freeze({
  setPassword: {
    buttonName: "Change Password",
    title: "Set your Password",
    page: 0,
  },
  setPasswordOK: {
    buttonName: "Change Password",
    page: 1,
    title: "Set your Password",
  },
  loginUser: {
    buttonName: "Log In",
    page: 2,
    title: "Log into your account",
  },
  loginAdmin: {
    buttonName: "Log In",
    page: 3,
    title: "Log into your account",
  },
  setUserPasswordOK: {
    title: "Log In",
    page: 4,
    buttonName: "Change Password",
  },
  setAdminPasswordOK: {
    title: "Log In",
    page: 5,
    buttonName: "Change Password",
  },
  resetPasswordInitial: {
    buttonName: "Send",
    page: 6,
    title: "Reset your Password",
  },
  resetPassword: {
    buttonName: "Reset Password",
    page: 7,
    title: "Reset your Password",
  },
  resetPasswordOK: {
    buttonName: "Go Back To Log In",
    page: 8,
    title: "Reset your Password",
  },
});
// Validations
export const PASS_MIN_LENGTH = 9;
export const PASS_MAX_LENGTH = 16;
export const ERROR_MSG = {
  invalid_email: "Invalid email address",
  invalid_password_reset_link:
    "Invalid or expired password reset link. Please request a new one.",
  passwords_dont_match: "Passwords don't match",
  pass_dont_satisfy_condition: "Must contain at least one special character",
  pass_exceeds_maxLength: `Must be shorter than ${PASS_MAX_LENGTH + 1
    } characters`,
  pass_exceeds_maxLength_condition: `Must be shorter than ${PASS_MAX_LENGTH + 1
    } characters and contain at least one special character`,
  pass_longer_minLength: `Must be longer than ${PASS_MIN_LENGTH - 1
    } characters`,
  pass_longer_minLength_condition: `Must be longer than ${PASS_MIN_LENGTH - 1
    } characters and contain at least one special character`,
  user_already_has_password_setted: "This user already has a password set",
  user_does_not_exist: "This user does not exist",
  user_password_incorrect: "Your password is incorrect",
  user_email_incorrect: "Your email is incorrect",
  user_dont_have_any_onboarding_available:
    "This user don't have any onboarding available",
};
