export const DROPDOWN_DEFAULT_VALUE = "Select an option";
export const PLACEHOLDER_INPUT_DATE = "dd/mm/yyyy";
export const PLACEHOLDER_DEFAULT = "Write here";
export const PLACEHOLDER_INPUT_ADD = "Add a new one";
export const DOCUSIGN_FILE_SIGNED = "completed";
// Component Types
export const COMPONENT_TYPE_CHECKBOX = "Checkbox";
export const COMPONENT_TYPE_DATE = "Date";
export const COMPONENT_TYPE_DROPDOWN = "Dropdown";
export const COMPONENT_TYPE_INPUT_ADD = "InputAdd";
export const COMPONENT_TYPE_LARGE_CARD = "LargeCard";
export const COMPONENT_TYPE_MULTI_CHECHBOX = "MultipleCheckbox";
export const COMPONENT_TYPE_RADIO = "Radio";
export const COMPONENT_TYPE_STRING = "String";
export const COMPONENT_TYPE_TEXTAREA = "TextArea";
export const COMPONENT_TYPE_UPLOAD_FILE = "UploadFile";
export const COMPONENT_TYPE_DOCUSIGN = "DocuSign";
export const COMPONENT_TYPE_TABLE = "Table";
//
// DocuSign Descriptions based on Status
export const DOCUSIGN_STATUS = {
    completed: {
        name: "completed",
        title: "Document signed",
        text: "We have already received the signed document",
    },
    delivered: {
        name: "delivered",
        title: "Sign your League Operator agreement",
        text: "We have sent the document to ",
    },
    error: {
        name: "error",
        title: "Invalid DocuSign ID",
        text: "Please check the information and try again. If not, please contact our team.",
    },
    sent: {
        name: "sent",
        title: "Sign your League Operator agreement",
        text: "We have sent the document to ",
    },
    waiting: {
        name: "waiting",
        title: "Docusign contract will be sent via Email",
        text: "Once you submit this form, your agreement will be created and sent for completion via email",
    },
};
export const DEFAULT_DOCUSIGN_TEXT =
    "Please submit the form to receive the League Operator Agreement for signing via DocuSign.";
